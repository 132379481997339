import {
    YTD,
    YEARS1,
    YEARS3,
    YEARS5,
    SINCE_INCEPTION,
    MONTH_TO_MONTH,
    ONE_YEAR_BEFORE,
    TWO_YEARS_BEFORE,
    THREE_YEARS_BEFORE,
} from 'filters/investementPerformanceFilters';
import {
    compareDates,
    dateFormat,
    formatDate,
    getFirstDayOfYear,
    getLastDayOfMonth,
    getSecondDayOfYear,
    getYearDates,
    isSameDay,
    subtractUnits,
} from 'utils/datetime';

export const isNeedToAddFlatLine = (filter, chartStartDate, date) => {
    return (
        (filter === YTD ||
            filter === YEARS1 ||
            filter === YEARS3 ||
            filter === YEARS5 ||
            filter === SINCE_INCEPTION ||
            filter === MONTH_TO_MONTH) &&
        compareDates(date, chartStartDate)
    );
};

export const adaptStartDate = (chartStartDate, date) => {
    // if data exist in date exists
    if (isSameDay(chartStartDate, date)) return chartStartDate;
    // if no data and it's XXXX-01-01, return XXXX-01-02 because of Jan 1st is day-off
    if (isSameDay(chartStartDate, getFirstDayOfYear())) return getSecondDayOfYear();

    return chartStartDate;
};

export const getPeriodByFilter = (filter, format = 'YYYY-MM-DD') => {
    let filterPeriod = {
        startDate: '',
        endDate: formatDate(new Date(), format),
    };

    switch (filter) {
        case YTD:
            filterPeriod.startDate = `${getFirstDayOfYear(new Date(), format)}`;
            break;
        case YEARS1:
            filterPeriod.startDate = subtractUnits(new Date(), 1, 'years', format);
            break;
        case YEARS3:
            filterPeriod.startDate = subtractUnits(new Date(), 3, 'years', format);
            break;
        case YEARS5:
            filterPeriod.startDate = subtractUnits(new Date(), 5, 'years', format);
            break;
        case SINCE_INCEPTION:
            delete filterPeriod.startDate;
            delete filterPeriod.endDate;
            break;
        case ONE_YEAR_BEFORE:
            filterPeriod = getYearDates(1);
            break;
        case TWO_YEARS_BEFORE:
            filterPeriod = getYearDates(2);
            break;
        case THREE_YEARS_BEFORE:
            filterPeriod = getYearDates(3);
            break;
        default:
            delete filterPeriod.startDate;
            delete filterPeriod.endDate;
    }

    return filterPeriod;
};

export const getPerformancePeriodByFilterOrDates = (filter, filterDates) =>
    filter === MONTH_TO_MONTH
        ? {
              startDate: getLastDayOfMonth(filterDates.startDate, dateFormat),
              endDate: getLastDayOfMonth(filterDates.endDate, dateFormat),
          }
        : getPeriodByFilter(filter, dateFormat);
