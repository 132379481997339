import DetectorSystemTheme from 'services/core/DetectorSystemTheme';
import { RESET_THEME, SET_THEME } from './themeActions';

const defaultState = {
    theme: DetectorSystemTheme.getSystemTheme(),
};

const initState = defaultState;

export default function reducer(state = initState, action = {}) {
    switch (action.type) {
        case RESET_THEME:
            return {
                ...initState,
            };
        case SET_THEME:
            return {
                theme: action.payload,
            };
        default:
            return state;
    }
}
