import moment from 'moment';
import 'moment/locale/en-gb';

require('moment-timezone');

export const timeFormat = 'HH:mm';
export const timeFormat12 = 'hh:mm A';
export const dateFormat = 'YYYY-MM-DD';
export const dateYearFormat = 'YYYY';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm';
export const dateTimeSecondsFormat = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_BACK = moment.HTML5_FMT.DATETIME_LOCAL_SECONDS;

export const importLocale = (lang) => {
    try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        require(`moment/locale/${lang}`);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
    }
};

export const detectTZ = () => moment.tz.guess();

export const formatDate = (date, outputFormat = dateFormat, inputFormat) =>
    moment.utc(date, inputFormat).local().format(outputFormat);

export const formatTime = (date, outputFormat = timeFormat) =>
    moment.utc(date).local().format(outputFormat);

export const getTimestamp = (date, initFormat = moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) =>
    +moment(date, initFormat);

export const subtractUnits = (date, quantity, unit, outputFormat = dateFormat) =>
    moment(date).subtract(quantity, unit).format(outputFormat);

export const compareDates = (date1, date2) => moment(date1) > moment(date2);

export const isSameDay = (date1, date2) => moment(date1).isSame(date2);

export const getDaysOfYear = (date) => moment(date).dayOfYear();

export const getDatesDifference = (date, dateDiff, units) => moment(date).diff(dateDiff, units);

export const getFirstDayOfYear = (date, outputFormat = dateFormat) =>
    moment(date).startOf('year').format(outputFormat);

export const getLastDayOfYear = (date, outputFormat = dateFormat) =>
    moment(date).endOf('year').format(outputFormat);

export const getFirstDayOfMonth = (date, outputFormat = dateFormat) =>
    moment(date).startOf('month').format(outputFormat);

export const getLastDayOfMonth = (date, outputFormat = dateFormat) =>
    moment(date).endOf('month').format(outputFormat);

export const getSecondDayOfYear = (outputFormat = dateFormat) =>
    moment().dayOfYear(2).format(outputFormat);

export const setYearToDate = (date, year, outputFormat = dateFormat) =>
    moment(date).set('year', year).format(outputFormat);

export const addMinutesToDate = (date, minuteUnits, outputFormat = dateFormat) =>
    moment(date).add(minuteUnits, 'minutes').format(outputFormat);

export const addDaysToDate = (date, daysUnits, outputFormat = dateFormat) =>
    moment(date).add(daysUnits, 'days').format(outputFormat);

export const adaptDate = (Date) => getTimestamp(Date);

export const getYearDates = (goBackForQuantityYears = 0) => {
    const currentDate = moment();
    const year = subtractUnits(currentDate, goBackForQuantityYears, 'year', dateYearFormat);
    const startDate = getLastDayOfMonth(getFirstDayOfYear(year));
    const endDate = getLastDayOfYear(year);

    return { startDate, endDate };
};
export const localResources = (func) => func(moment);
