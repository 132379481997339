import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'ui-library';
import WidgetError from './WidgetError';

export const Preloader = ({ isLoading, error, children }) => {
    if (isLoading) return <Loader />;

    if (error !== null) {
        return <WidgetError accent error message={error.message} />;
    }

    return children;
};

Preloader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    error: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
};

Preloader.defaultProps = {
    error: null,
};
