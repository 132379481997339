import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContentBox, Title } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import { Preloader } from 'components/molecules/Preloader';
import { Button } from 'components/Button';
import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';

function RelationshipManager({ data, isLoading, error, title }) {
    const { t } = useTranslation();
    const [showPhone, setPhone] = useState(false);

    return (
        <PageSection>
            <ContentBox underline={false} className="relationship-manager">
                {title ? (
                    <Title type={2}>{t(title)}</Title>
                ) : (
                    <Title type={2}>{t('dashboard.relationshipManager.title')}</Title>
                )}
                <Preloader isLoading={isLoading} error={error}>
                    <div className="media">
                        {data.Img && <img src={data.Img} alt=" " width="72" height="72" />}
                        <div className="media-body">
                            <Title type={3}>{data.Name}</Title>
                            <div className="media-links">
                                {data.Contacts && (
                                    <a
                                        onClick={() => {
                                            sendGAEvent(
                                                GA_ACTIONS.viewRM,
                                                GA_CATEGORIES.contactRM,
                                                'send_email'
                                            );
                                        }}
                                        href={data.Contacts[0].Icon.href}
                                        className="paragraph_secondary"
                                    >
                                        {t('messages.sendMessage')}
                                    </a>
                                )}
                                {data.PhoneNumber && !showPhone && (
                                    <Button
                                        type="button"
                                        className="call-now"
                                        onClick={() => setPhone(true)}
                                    >
                                        <span>{t('dashboard.relationshipManager.callNow')}</span>
                                    </Button>
                                )}
                                {data.PhoneNumber && showPhone && (
                                    <a
                                        onClick={() => {
                                            sendGAEvent(
                                                GA_ACTIONS.viewRM,
                                                GA_CATEGORIES.contactRM,
                                                'click_and_call'
                                            );
                                        }}
                                        href={`tel:${data.PhoneNumber}`}
                                        className="call-now"
                                    >
                                        {data.PhoneNumber}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

RelationshipManager.propTypes = {
    data: PropTypes.shape({
        Contacts: PropTypes.arrayOf(
            PropTypes.shape({
                Information: PropTypes.shape({
                    title: PropTypes.string,
                    subtitle: PropTypes.string,
                }),
                Icon: PropTypes.shape({
                    type: PropTypes.string,
                    href: PropTypes.string,
                    size: PropTypes.number,
                }),
            })
        ),
        Img: PropTypes.string,
        Name: PropTypes.string,
        PhoneNumber: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    title: PropTypes.string,
};

RelationshipManager.defaultProps = {
    title: null,
    error: null,
};

export default RelationshipManager;
