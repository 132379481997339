import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatWithDate } from 'utils/formatting';
import { SINCE_INCEPTION } from 'filters/investementPerformanceFilters';
import { LINE } from 'constants/constants';
import { useFormatting } from 'locale';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';
import { getPerformancePeriodByFilterOrDates } from 'utils/charts';
import {
    adaptPerformancePlots,
    filterHistoricalPerformanceByDates,
} from '../adapters/adaptPerformanceAndRiskCompare';

export const usePerformanceAndRiskCompare = (portfolio, isLoading, defaultFilter, filterDates) => {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const [performanceFilter, setPerformanceFilter] = useState(defaultFilter || SINCE_INCEPTION);
    const [isLoadingPerformanceAndRisk, setLoading] = useState(true);
    const [errorPerformanceAndRisk, setError] = useState(null);
    const [performance, setPerformance] = useState({
        title: t('confirmation.performance'),
        data: [],
    });

    const { getFormattedNumber, getFormattedDate } = useFormatting();

    const yAxisFormat = ({ value }) => `${getFormattedNumber(value)}%`;
    const tooltipFormat = (number, date) =>
        formatWithDate(`${getFormattedNumber(number)}%`, date, getFormattedDate);

    useEffect(() => {
        if (!isLoading && portfolio.HistoricalPerformance) {
            setError(null);
            setLoading(true);
            const dates = getPerformancePeriodByFilterOrDates(performanceFilter, filterDates);
            const filteredPerformance = filterHistoricalPerformanceByDates(
                portfolio.HistoricalPerformance[0],
                dates
            );
            const performanceData = filteredPerformance.length
                ? [
                      {
                          name: t('confirmation.current'),
                          data: adaptPerformancePlots(filteredPerformance, isMobileSize),
                          tooltipFormat,
                      },
                  ]
                : [];

            setPerformance({
                title: t('confirmation.performance'),
                data: performanceData,
                optional: {
                    tooltipFormat,
                    yAxisFormat,
                },
            });
            setLoading(false);
        }
    }, [isLoading, portfolio, performanceFilter, filterDates]);

    const onPerformanceChange = useCallback((param) => {
        sendGAEvent(GA_ACTIONS.changePerformanceFilter, GA_CATEGORIES.portfolio, param);
        setLoading(true);
        setPerformanceFilter(param);
    }, []);

    return {
        performanceAndRisk: {
            [LINE]: {
                ...performance,
                error: errorPerformanceAndRisk,
                isLoading: isLoadingPerformanceAndRisk,
            },
        },
        errorPerformanceAndRisk,
        isLoadingPerformanceAndRisk,
        performanceFilter,
        onPerformanceChange,
    };
};
