import React from 'react';
import DOMPurify from 'dompurify';

const tagsSettings = {
    iframe: (node) => {
        const src = node.getAttribute('src') || '';

        if (!src.startsWith('https://www.youtube.com/embed/')) {
            node.parentNode.removeChild(node);
        }
    },
};

DOMPurify.addHook('uponSanitizeElement', (node, data) => tagsSettings[data.tagName]?.(node));

export const innerHTMLRender = (text = '') => (
    <span
        dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(text, { ADD_TAGS: ['iframe'] }),
        }}
    />
);

export const sanitizeString = (str = '') => {
    if (typeof str === 'string') {
        return str
            .replace(/(\r\n|\n|\r)/gm, '')
            .replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode));
    }

    return '';
};
