export const validate = (values) => {
    const errors = {};

    if (values.password !== undefined && !values.password) {
        errors.password = 'resetPassword.providePassword';
    }

    if (
        values.password &&
        values.confirmPassword !== undefined &&
        values.confirmPassword !== values.password
    ) {
        errors.confirmPassword = 'resetPassword.passwordDontMatch';
    }

    return errors;
};
