export const validateUsername = (username) =>
    /@/.test(username) ? username : `${username}@${global.AppConfig.USERNAME_EMAIL_DOMAIN}`;

export const validateLoginForm = (values) => {
    const errors = {};

    if (!values.username) {
        errors.username = 'validation.requireUsername';
    }

    if (!values.password) {
        errors.password = 'validation.requirePassword';
    }

    return errors;
};
