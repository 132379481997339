/* eslint-disable */

window.reactAdditiv = {
    settings: {
        services: {
            withJWT: true,
            GATEWAY_API_URL: process.env.REACT_APP_GATEWAY_API_URL,
            SERVICES_URL_SUFFIX: global.AppConfig.SERVICES_URL_SUFFIX,
            DMS_URL_SUFFIX: global.AppConfig.DMS_URL_SUFFIX,
            CUSTOM_URL_SUFFIX: global.AppConfig.CUSTOM_URL_SUFFIX,
        },
    },
};
