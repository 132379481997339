import React from 'react';
import { ContentBox, Table, Title } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { portfoliosColumns, portfoliosMobileColumns } from 'constants/constants';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import PageSection from '../PageSection';
import { Preloader } from '../molecules/Preloader';
import { EmptyContent } from '../molecules/EmptyContent';

function Portfolios({ data, isLoading, error, withLink }) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedNumber } = useFormatting();

    return (
        <PageSection>
            <ContentBox className="portfolios">
                {withLink ? (
                    <Title type={2} link="/portfolios">
                        {t('portfolios.title')}
                    </Title>
                ) : (
                    <Title type={2}>{t('portfolios.title')}</Title>
                )}
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t('portfolios.noPortfoliosFound')}>
                        <Table
                            data={data}
                            columns={
                                isMobileSize
                                    ? portfoliosMobileColumns(t, getFormattedNumber)
                                    : portfoliosColumns(t, getFormattedNumber)
                            }
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                                sortByLabel: t('headerControls.sortBy'),
                            }}
                        />
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Portfolios.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            Name: { label: PropTypes.string, link: PropTypes.string },
            Product: PropTypes.string,
            Profile: PropTypes.string,
            Value: PropTypes.string,
            Performance: PropTypes.string,
            Icon: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    withLink: PropTypes.bool,
};

Portfolios.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    withLink: false,
};

export default Portfolios;
