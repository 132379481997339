import { getfromDataObject, NA } from 'utils/formatting';
import { adaptAllocationGroups } from '../../common/adapters/adaptAllocationGroups';
import { getAllocations } from '../../common/adapters/adaptAllocations';
import { groupPortfolioPositions } from './groupPortfolioPositions';

export const adaptPortfolioDetails = (
    data,
    t,
    getFormattedDate,
    getFormattedNumber,
    getFormattedCurrency
) => {
    let currency;
    let currencyId;
    const productID = getfromDataObject(data, 'Product.Id');
    const baseUrl = `/portfolios/${data.Id}/position/${productID}`;

    if (data && data.Currency) {
        currency = data.Currency.CurrencyCode;
        currencyId = data.Currency.Id;
    } else if (data && data.BaseCurrency) {
        currency = data.BaseCurrency.CurrencyCode;
        currencyId = data.BaseCurrency.Id;
    }

    const customAttr = data.CustomAttributes ? JSON.parse(data.CustomAttributes) : {};

    return {
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        currency,
        currencyId,
        updateDate: customAttr.UpdateDate ? getFormattedDate(customAttr.UpdateDate) : NA,
        overview: {
            portfolioValue: data.CurrentValue || 0,
            currency,
            strategy: getfromDataObject(data, 'RiskCategory.Name'),
            product: getfromDataObject(data, 'Product.Name'),
            performance: Math.round(customAttr.performanceSI * 10000) / 100,
            performanceYTD: Math.round(customAttr.performanceYTD * 10000) / 100,
        },
        constraints: data.OptimisationConstraint || {},
        investmentAllocations: getAllocations(data.Positions),
        investmentAllocation: {
            chart: adaptAllocationGroups(
                {
                    positions: data.Positions || data.ConsolidatedPositions,
                    currency,
                    t,
                },
                getFormattedCurrency
            ),
            currency,
        },
        productId: productID,
        positionsRaw: data.Positions,
        positions: groupPortfolioPositions(
            data.Positions,
            currency,
            baseUrl,
            t,
            getFormattedNumber,
            getFormattedCurrency,
            getFormattedDate
        ),
        riskReturn: [+(data.Risk * 100), +(data.Return * 100)],
        HistoricalPerformance: data.HistoricalPerformance,
    };
};
