import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Column, TabPane, Tabs, Row, Label as LabelUI, Select, TabsExtraItem } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import { formatBigNumber } from 'utils/formatting';
import { MONTH_TO_MONTH, getPerformanceSelectOptions } from 'filters/investementPerformanceFilters';
import { LINE } from 'constants/constants';
import ChartType from 'components/organisms/ChartType';
import MonthRangePicker from 'components/MonthRangePicker';

function Analysis({ data, performanceFilter, onFilterChange, setPerformanceDates }) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (startDate && endDate) {
            setPerformanceDates({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (performanceFilter !== MONTH_TO_MONTH) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [performanceFilter]);

    const monthFrom = {
        startDate,
        setStartDate,
        placeholder: 'dashboard.assetDevelopment.filters.from',
    };
    const monthTo = {
        endDate,
        setEndDate,
        placeholder: 'dashboard.assetDevelopment.filters.to',
    };

    const labelFormat = (label, values = {}) => {
        const valuesFormatted =
            values.new !== undefined
                ? `${formatBigNumber(values.new)}% (${t('confirmation.toDate')} ${formatBigNumber(
                      values.curr
                  )}%)`
                : `${formatBigNumber(values.curr)}%`;

        return [<LabelUI label={label} labelInfobox />, valuesFormatted];
    };

    return (
        <Tabs
            className="analysis tabs-with-filter"
            tabBarExtraContent={(
                <Fragment>
                    {performanceFilter === MONTH_TO_MONTH && (
                        <TabsExtraItem>
                            <MonthRangePicker monthFrom={monthFrom} monthTo={monthTo} />
                        </TabsExtraItem>
                    )}
                    <TabsExtraItem>
                        <Select
                            onChange={onFilterChange}
                            options={getPerformanceSelectOptions(t)}
                            width={130}
                            value={performanceFilter}
                        />
                    </TabsExtraItem>
                </Fragment>
              )}
        >
            {Object.keys(data).map((key) => (
                <TabPane tab={data[key].title} key={key}>
                    <Row className="mb-0">
                        <Column size={['sm-12']}>
                            <Preloader isLoading={data[key].isLoading} error={data[key].error}>
                                <EmptyContent data={data[key].data} text={t('dashboard.noData')}>
                                    <ChartType
                                        data={data[key].data}
                                        chartView={key}
                                        labelFormat={labelFormat}
                                        {...data[key].optional}
                                        legendEnabled
                                        t={t}
                                    />
                                </EmptyContent>
                            </Preloader>
                        </Column>
                    </Row>
                </TabPane>
            ))}
        </Tabs>
    );
}

Analysis.propTypes = {
    data: PropTypes.shape({
        [LINE]: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
            })
        ),
    }),
    performanceFilter: PropTypes.string,
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    onFilterChange: PropTypes.func.isRequired,
    setPerformanceDates: PropTypes.func.isRequired,
};

Analysis.defaultProps = {
    data: { chart: [] },
    performanceFilter: undefined,
    isLoading: true,
    error: null,
};

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);
const AnalysisWithMemo = React.memo(Analysis, areEqual);

export default AnalysisWithMemo;
