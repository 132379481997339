import React from 'react';
import { ContentBox } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PlayStoreQrCode from '../images/play_store_qr.png';
import AppStoreQrCode from '../images/app_store_qr.png';
import { NsConnect, NsTopDomain } from '../const';
// eslint-disable-next-line react/prop-types
import '../Activation.css';

function ActivationPageES() {
    return (
        <PageSection>
            <ContentBox>
                <p>
                    NS Partners le ofrece un acceso directo a su(s) cartera(s) a través de un sitio
                    web dedicado y una aplicación móvil: NS Connect.
                </p>
                <p>
                    Este servicio ha sido posible gracias a la integración informática de los flujos
                    de transacciones con nuestras principales contrapartes bancarias. Los precios de
                    los activos también están actualizados regularmente. El sitio web y la
                    aplicación móvil no permiten realizar transacciones. Además, le recordamos que
                    solo se utilizarán documentos bancarios para todos sus requisitos de declaración
                    fiscal y reglamentaria.
                </p>
                <p>
                    Consulte abajo el procedimiento que deberá seguir para beneficiarse de este
                    servicio.
                </p>
                <p>
                    Para una mayor seguridad, hemos elegido un método de doble autenticación tanto
                    para el sitio web como para la aplicación.
                </p>
                <p>
                    Cuando haya configurado su contraseña a través del enlace proporcionado en el
                    correo electrónico que acaba de recibir de <u>no-reply@{NsTopDomain}</u>
                    , proceda según se explica abajo.
                </p>

                <p>1. Para acceder a través de la aplicación</p>
                <p>
                    Descargue la aplicación NS Connect en Play Store o App Store en su smartphone o
                    tableta. También puede escanear el código QR que figura abajo.
                </p>
                <div className="qr-container">
                    <figure>
                        <img className="qr-image" src={PlayStoreQrCode} alt="Play Store" />
                        <figcaption className="centered-qr">Play Store</figcaption>
                    </figure>
                    <figure>
                        <img className="qr-image" src={AppStoreQrCode} alt="App Store" />
                        <figcaption className="centered-qr">App Store</figcaption>
                    </figure>
                </div>
                <p>
                    Introduzca su nombre de usuario y contraseña. Su smartphone o tableta utilizarán
                    la opción de reconocimiento facial (&quot;Face ID&quot;) para autenticarle.
                </p>

                <p>2. Para acceder a través del sitio web</p>
                <p>
                    Vaya a la página siguiente: &nbsp;
                    <a href={NsConnect} rel="noreferrer noopener">
                        nsconnect.{NsTopDomain}
                    </a>
                    &nbsp;(no utilice el navegador Internet Explorer).
                </p>
                <p>Introduzca su nombre de usuario y contraseña.</p>
                <p>
                    Se le pedirá que descargue la aplicación Futurae (Futurae Technologies AG) en la
                    App Store o GooglePlay de su smartphone o tableta. Esto le permitirá
                    autenticarse. Siga los pasos que se indican según avanza en NS Connect, los
                    cuales se resumen a continuación.
                </p>
                <p>
                    La primera vez que lo use, se le pedirá que registre su smartphone o tableta en
                    la aplicación Futurae. Para ello, vuelva a introducir su nombre de usuario y
                    contraseña en su ordenador. A continuación, abra la aplicación Futurae en su
                    dispositivo y escanee el código QR que aparecerá en su ordenador.
                </p>
                <p>Su dispositivo está registrado y usted ya está listo para iniciar sesión.</p>

                <p>Introduzca su nombre de usuario y contraseña.</p>
                <p>
                    A continuación, introduzca el código de seis dígitos que le facilita la
                    aplicación Futurae cuando se abre en su smartphone o tableta. Esa etapa será
                    necesaria a cada conexión, para identificarle.
                </p>
                <p>
                    No dude en ponerse en contacto con su gestor de cartera o con cualquier de
                    nuestros empleados, especialmente durante la primera conexión y las primeras
                    veces que use el producto.
                </p>
            </ContentBox>
        </PageSection>
    );
}

ActivationPageES.defaultProps = {};

export default ActivationPageES;
