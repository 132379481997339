import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { FieldRenderer } from 'components/Field';
import { PasswordFieldRenderer } from 'components/Field/PasswordRenderer';
import { connect } from 'react-redux';
import { FORM_NAME } from '../components/LoginForm/LoginFormNew';
import { validateLoginForm } from '../utils/validation';

const LoginForm = ({ isSubmitting, submitLabel, onSubmit, handleSubmit, bottomLinks }) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Field
                name="username"
                type="text"
                id="username"
                autoComplete="off"
                label={t('common.USERNAME')}
                component={FieldRenderer}
            />
            <br />
            <Field
                name="password"
                id="password"
                autoComplete="off"
                label={t('common.PASSWORD')}
                component={PasswordFieldRenderer}
            />
            <br />
            <div className="buttons buttons-inline button-group button-group--horiz">
                <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                    {t(submitLabel)}
                </Button>
                {bottomLinks}
            </div>
        </form>
    );
};

LoginForm.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    bottomLinks: PropTypes.instanceOf(Object),
};

LoginForm.defaultProps = {
    bottomLinks: null,
};

LoginForm.displayName = 'LoginForm';

const WrappedIntoReduxForm = reduxForm({
    form: FORM_NAME,
    validate: validateLoginForm,
})(LoginForm);

export default connect()(WrappedIntoReduxForm);
