import { getParent, roundAllocations, sum, sortAssetsByOrderPropOrAllocation } from 'utils';
import {
    formatBigNumber,
    getfromDataObject,
    NA,
    percentIsZero,
    validateData,
    validateNumber,
} from 'utils/formatting';
import { isLiquidity, redirectToReadOnlyPositionDetails } from '../../common/utils';
import { getAssetClassOrder } from '../constants';

const groupBySubStrategy = (data) => {
    if (data && data.length) {
        data.forEach((position) => {
            let childCurrentType = '';
            const groupingIndexes = [];

            position.children.forEach((child, index) => {
                if (childCurrentType !== child.subAssetClass) {
                    childCurrentType = child.subAssetClass;
                    groupingIndexes.push({ index, type: childCurrentType });
                }
            });

            groupingIndexes.forEach((groupIndexObj, index) => {
                if (
                    (groupIndexObj.index === 0 &&
                        position.children[groupIndexObj.index + index].grouping === undefined) ||
                    (groupIndexObj.index !== 0 &&
                        position.children[groupIndexObj.index + index - 1].grouping === undefined &&
                        position.children[groupIndexObj.index + index].grouping === undefined)
                )
                    position.children.splice(groupIndexObj.index + index, 0, {
                        name: { label: groupIndexObj.type, link: '', maxLength: 75 },
                        grouping: '',
                        actions: {},
                    });
            });
        });
    }

    return data;
};

export const groupPortfolioPositions = (
    data,
    portfolioCurrency,
    baseUrl,
    t,
    getFormattedNumber,
    getFormattedCurrency,
    getFormattedDate
) => {
    const roundedAllocation = data.length && roundAllocations(data);
    const groupToValues = data.reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAlloc = roundedAllocation.find((n) => n.id === securityData.Id).value;

        const accParent = getParent(item.Security.AssetClass);
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = validateData(securityData.Name);
        const customAttr = item.CustomAttributes ? JSON.parse(item.CustomAttributes) : {};
        const isLiquidityPosition = isLiquidity(securityData);

        accumulator[accParent.Name] = obj[accParent.Name] || [];
        accumulator[accParent.Name].push({
            sortingOrder: getAssetClassOrder(getfromDataObject(item.Security.AssetClass, 'Id')), // sub-strategies order property
            parentId: accParent.Id,
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            name: {
                label: name,
                link: isLiquidityPosition ? undefined : `${baseUrl}/${item.Id}/2/overview`,
                maxLength: 75,
            },
            allocationData: roundedAlloc,
            allocation: `${percentIsZero(roundedAlloc)}%`,
            currency,
            units: getFormattedNumber(validateNumber(item.Quantity), {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            performance:
                isLiquidityPosition || !customAttr.performanceSI
                    ? null
                    : Math.round(customAttr.performanceSI * 10000) / 100,
            performanceYTD:
                isLiquidityPosition || !customAttr.performanceYTD
                    ? null
                    : Math.round(customAttr.performanceYTD * 10000) / 100,
            latestPrice: getFormattedNumber(validateNumber(item.ValuationPrice), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            latestPriceDate: customAttr.LastPriceDate
                ? getFormattedDate(customAttr.LastPriceDate)
                : NA,
            valuation: getFormattedCurrency(validateNumber(item.InvestmentValuePortfolioCurrency), {
                currency: portfolioCurrency,
            }),
            subAssetClass: getfromDataObject(item.Security.AssetClass, 'Name'),
            type: getfromDataObject(item.Security.Type, 'Name'),
            // API gives customAttr.UnrealizedGainLoss as a string,
            // so we transform it to the number format to apply formatting
            unrealizedGainLoss:
                isLiquidityPosition || !customAttr.UnrealizedGainLoss
                    ? null
                    : getFormattedNumber(customAttr.UnrealizedGainLoss, {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                      }),
            costPrice: getFormattedNumber(item.BuyPrice, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            valuationInSecurityCurrency: getFormattedCurrency(
                validateNumber(item.InvestmentValueSecurityCurrency),
                { currency: item.Security.Currency.CurrencyCode }
            ),
            valuationData: validateNumber(item.InvestmentValuePortfolioCurrency),
            performanceCCY: validateNumber(item.MonetaryPerformance),
            actions: isLiquidity(securityData)
                ? {}
                : {
                      value: 'action',
                      actions: [
                          {
                              text: t('portfolios.showMoreDetails'),
                              onClick: () => redirectToReadOnlyPositionDetails(baseUrl, item.Id, 1),
                          },
                          {
                              text: t('portfolios.showPerformance'),
                              onClick: () => redirectToReadOnlyPositionDetails(baseUrl, item.Id, 2),
                          },
                      ],
                  },
        });

        return accumulator;
    }, {});

    let groups = Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];

        const allocation = getFormattedNumber(
            percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData')))
        );

        return {
            id: groupMembersData[0].parentId,
            sortingOrder: getAssetClassOrder(groupMembersData[0].parentId), // strategies order property
            valuation: getFormattedCurrency(sum(groupMembersData, 'valuationData'), {
                currency: portfolioCurrency,
            }),
            allocation: `${allocation}%`,
            allocationData: sum(groupMembersData, 'allocationData'),
            name: key,
            children: groupMembersData.sort(sortAssetsByOrderPropOrAllocation), // sub-strategies level sorting
            icon: 'action',
            costPrice: groupMembersData.costPrice,
            actions: {},
            lastPriceDate: groupMembersData.latestPriceDate,
        };
    });

    groups = groups
        .map((groupItem) => {
            const resultGroupItem = { ...groupItem };

            resultGroupItem.valuation = groupItem.valuation;

            resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
                const resultGroupMemberItem = groupMemberItem;

                resultGroupMemberItem.valuation = groupMemberItem.valuation;
                resultGroupMemberItem.icon = 'action';

                return resultGroupMemberItem;
            });

            return resultGroupItem;
        })
        .sort(sortAssetsByOrderPropOrAllocation); // strategies level sorting

    return groupBySubStrategy(groups);
};
