import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import commonContent from 'additiv-services/dist/services/core/commonContent';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptNews } from '../adapters/adaptNews';

const getAdaptedNewsData = async (i18n) => {
    const dfsNews = await commonContent.getNews(2, i18n?.language);

    const dfsNewsAdapted = adaptNews(dfsNews);

    return dfsNewsAdapted.posts;
};

export const useNewsPost = (newsItem, id, params) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (newsItem === undefined) {
            setLoading(true);
            setError(null);
            getAdaptedNewsData(i18n, params)
                .then((data) => {
                    try {
                        setNewsList(data);
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setNewsList([newsItem]);
        }
    }, [i18n.language]);

    return { data: newsList, isLoading, error };
};
