import { getfromDataObject, validateStringData } from 'utils/formatting';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import { getAccountInceptionDate } from '../utils';

const getOverviewGainYTD = (portfolios) => {
    let gainYTD = 0;

    portfolios.forEach((item) => {
        gainYTD += item.gainYTD;
    });

    return gainYTD;
};

export const adaptInvestmentOverview = (
    data,
    portfolioActions,
    t,
    getFormattedCurrency,
    getFormattedDate
) => {
    let positions;
    const currency = data.BaseCurrency.CurrencyCode;

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }

    const portfolios =
        data.Portfolios !== undefined
            ? data.Portfolios.filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN).map(
                  (item) => {
                      const customAttr = JSON.parse(item.CustomAttributes);

                      return {
                          Name: { label: item.Name, link: `/portfolios/${item.Id}` },
                          Product: getfromDataObject(item, 'Product.Name'),
                          Profile: validateStringData(item.RiskCategory.Name),
                          Value: getFormattedCurrency(item.CurrentValue, {
                              currency: item.Currency.CurrencyCode,
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                          }),
                          Performance: item.CurrentPerformance
                              ? item.CurrentPerformance * 100
                              : undefined,
                          Actions: {
                              value: 'action',
                              actions: portfolioActions(item.Id, item.Product.Name, t),
                          },
                          gainYTD: customAttr.GainLossYTD || 0,
                          createDate: item.CreateDate,
                      };
                  }
              )
            : [];

    return {
        overview: {
            currency,
            totalValue: data.CurrentValue,
            performanceValue: data.CurrentPerformance * 100,
            gainYTD: getOverviewGainYTD(portfolios),
            inceptionDate: getFormattedDate(getAccountInceptionDate(portfolios)),
        },
        positions,
        portfolios,
    };
};
