/* eslint-disable import/no-cycle */
import { reset, SubmissionError } from 'redux-form';
import Cookies from 'universal-cookie';
import { get } from 'lodash/fp';
import i18next from 'i18next';
import ServiceManager from 'services/core/ServiceManager';
import { SUCCESS } from 'static/requestResults';
import { FORM_NAME } from 'pages/pages/Login/components/LoginForm/LoginForm';
import {
    getErrorModelByStatus,
    getAuthErrorByStatus,
    LOGIN_FAILED_UNEXPECTEDLY,
} from 'error/authErrors';
import history from 'services/core/history';
import CustomServiceManager from 'services/custom/CustomServiceManager';
import { contactIdSelector, jwtAccessTokenSelector } from './authSelectors';

export const LOGIN_SUCCESS = 'react-template-project/auth/LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'react-template-project/auth/LOGOUT_SUCCESS';
export const GET_USER_PROFILE_START = 'react-template-project/auth/GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'react-template-project/auth/GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'react-template-project/auth/GET_USER_PROFILE_FAIL';
export const PRE_AUTH_WITH_2FA_COMPLETE = 'react-template-project/auth/PRE_AUTH_WITH_2FA_COMPLETE';
export const LOGIN_WITHOUT_2FA_SUCCESS = 'react-template-project/auth/LOGIN_WITHOUT_2FA_SUCCESS';
export const PRE_AUTH_SUCCESS = 'react-template-project/auth/PRE_AUTH_SUCCESS';
export const AUTH_COMPLETE = 'react-template-project/auth/AUTH_COMPLETE';
export const REMEMBER_ME = 'react-template-project/auth/REMEMBER_ME';
export const TERMS_ACCEPTED = 'termsAccepted';

export const RESET_LOGIN = 'react-template-project/auth/RESET_LOGIN';

export const cookies = new Cookies();

export function setTermsAccepted() {
    return { type: TERMS_ACCEPTED };
}

export function loginWithout2FASuccess(payload) {
    return { type: LOGIN_WITHOUT_2FA_SUCCESS, payload };
}

export function preAuthWith2FAComplete() {
    return { type: PRE_AUTH_WITH_2FA_COMPLETE };
}

export function authComplete(payload) {
    return { type: AUTH_COMPLETE, payload };
}

export function loginSuccess(payload) {
    return { type: LOGIN_SUCCESS, payload };
}

export function getUserProfileStart() {
    return { type: GET_USER_PROFILE_START };
}

export function getUserProfileSuccess(payload) {
    return { type: GET_USER_PROFILE_SUCCESS, payload };
}

export function getUserProfileFail() {
    return { type: GET_USER_PROFILE_FAIL };
}

export function logoutSuccess() {
    return { type: LOGOUT_SUCCESS };
}

export function preAuthComplete(payload) {
    return { type: PRE_AUTH_SUCCESS, payload };
}

export function rememberMyDevice(payload) {
    return { type: REMEMBER_ME, payload };
}

export function resetLogin() {
    return { type: RESET_LOGIN };
}

export function getUserProfile() {
    return (dispatch, getState) => {
        const state = getState();
        const contactId = contactIdSelector(state);

        dispatch(getUserProfileStart());

        return new Promise((resolve, reject) => {
            ServiceManager.contactManagement('getClientProfile', [contactId, i18next.language])
                .then((contactManagementResponse) => {
                    ServiceManager.contactManagement('getContactMembers', [contactId])
                        .then((membersResponse) => {
                            dispatch(
                                getUserProfileSuccess({
                                    ...contactManagementResponse.data,
                                    members: membersResponse.data,
                                })
                            );
                            resolve(contactManagementResponse);
                        })
                        .catch((reason) => {
                            const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                            dispatch(getUserProfileFail(errorModel));
                            reject(reason);
                        });
                })
                .catch((reason) => {
                    const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                    dispatch(getUserProfileFail(errorModel));
                    reject(reason);
                });
        });
    };
}

export function login({ username, password }, dispatch) {
    const validatedLogin = /@/.test(username)
        ? username
        : `${username}@${global.AppConfig.USERNAME_EMAIL_DOMAIN}`;

    return ServiceManager.Security('logIn', [validatedLogin, password]).then((response) => {
        const resultType = response.data.Result;
        const errorObject = getAuthErrorByStatus(resultType);

        if (errorObject !== undefined) {
            throw new SubmissionError(errorObject);
        } else if (resultType === SUCCESS) {
            dispatch(getUserProfile()).then(() => {
                dispatch(
                    loginSuccess({
                        ...response.data,
                        username,
                        password,
                    })
                );
            });
            dispatch(reset(FORM_NAME));
        }
        throw new SubmissionError(getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY));
    });
}

export const LOGIN_WITH_URL_SUCCESS = 'react-template-project/auth/LOGIN_WITH_URL_SUCCESS';
export const LOGIN_WITH_URL_START = 'react-template-project/auth/LOGIN_WITH_URL_START';

export function loginWithURLSuccess() {
    return { type: LOGIN_WITH_URL_SUCCESS };
}

export function loginWithURLStart(payload) {
    return { type: LOGIN_WITH_URL_START, payload };
}

export function loginByURL({ urlContactId, urlAccessToken, roleId }) {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch(loginWithURLStart({ contactId: urlContactId, jwt: urlAccessToken, roleId }));
            dispatch(getUserProfile()).then(() => {
                dispatch(loginWithURLSuccess());
                resolve();
            });
        });
}

export const SESSION_CHECKED = 'react-template-project/session/SESSION_CHECKED';

export function sessionChecked(payload) {
    return { type: SESSION_CHECKED, payload };
}

export function checkSession() {
    return (dispatch, getState) => {
        const state = getState();
        const jwtAccessToken = jwtAccessTokenSelector(state);

        return new Promise((resolve) => {
            const [promise] = ServiceManager.Security('validateToken', [
                { accessToken: jwtAccessToken },
            ]);

            promise
                .then((response) => {
                    const resultType = get('data.Result', response);

                    dispatch(
                        sessionChecked({
                            isCurrentSessionChecked: true,
                            isSessionValid: resultType === 'Success',
                        })
                    );
                    resolve();
                })
                .catch(() => {
                    dispatch(
                        sessionChecked({
                            isCurrentSessionChecked: false,
                            isSessionValid: false,
                        })
                    );

                    resolve();
                });
        });
    };
}

export function login2FA({ username, password, rememberMe }, dispatch) {
    const validatedLogin = /@/.test(username)
        ? username
        : `${username}@${global.AppConfig.USERNAME_EMAIL_DOMAIN}`;

    return new Promise((resolve, reject) => {
        const trustedDeviceToken = cookies.get('trustedDeviceToken');

        ServiceManager.Security('logIn2', [
            { username: validatedLogin, password, trustedDeviceToken },
        ])
            .then((logIn2Response) => {
                const resultType = get('data.Result', logIn2Response);
                const errorObject = getAuthErrorByStatus(resultType);

                if (errorObject !== undefined) {
                    reject(new SubmissionError(errorObject));
                } else if (resultType === SUCCESS) {
                    const isTwoFactorEnabled = get(
                        'data.CampaignContact.IsTwoFactorEnabled',
                        logIn2Response
                    );
                    const session = get('data.Session', logIn2Response);

                    dispatch(
                        preAuthComplete({
                            ...logIn2Response.data,
                            username,
                            rememberMe,
                        })
                    );
                    if (isTwoFactorEnabled === false || session !== undefined) {
                        dispatch(reset(FORM_NAME));
                        dispatch(
                            authComplete({
                                sessionId: get('data.Session.SessionId', logIn2Response),
                                jwtAccessToken: get('data.Session.JwtAccessToken', logIn2Response),
                            })
                        );
                        dispatch(reset(FORM_NAME));
                        resolve();
                        dispatch(getUserProfile());
                    } else {
                        dispatch(preAuthWith2FAComplete());
                        dispatch(reset(FORM_NAME));
                        resolve();
                    }
                } else {
                    reject(new SubmissionError(getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY)));
                }
            })
            .catch(() => {
                const errorObject = getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                reject(new SubmissionError(errorObject));
            });
    });
}

export function status2FASuccess(data) {
    return (dispatch) => {
        const trustedDeviceToken = get('TrustedDeviceToken', data);

        if (trustedDeviceToken !== undefined && trustedDeviceToken !== null) {
            const date = new Date();
            const expirationDays = process.env.REACT_APP_COOKIES_EXPIRES_IN_DAYS;

            date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
            cookies.set('trustedDeviceToken', trustedDeviceToken, {
                path: '/',
                expires: date,
            });
        }
        dispatch(
            authComplete({
                sessionId: get('Session.SessionId', data),
                jwtAccessToken: get('Session.JwtAccessToken', data),
            })
        );
        dispatch(getUserProfile());
        dispatch(reset(FORM_NAME));
    };
}

export const RESET2FA = 'react-template-project/auth/RESET2FA';
export function reset2FA() {
    return { type: RESET2FA };
}

export function logout() {
    return async (dispatch, getState) => {
        try {
            const contactId = getState().auth?.user?.contactId;

            if (contactId) {
                await CustomServiceManager.logout(contactId);
            }

            global.localStorage.removeItem('auth');
            dispatch(logoutSuccess());
            history.push({
                pathname: '/login',
            });
        } catch {
            console.log('logout error');
        }
    };
}
