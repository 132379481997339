import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting, useLocale } from 'locale';
import { getLastDayOfMonth } from 'utils/datetime';
import { MONTH_TO_MONTH } from 'filters/investementPerformanceFilters';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { getPerformancePeriodByFilterOrDates } from 'utils/charts';
import { adaptInvestmentOverviewPerformance } from '../adapters/adaptInvestmentOverviewPerformance';

const getParamsByFilter = ({ startDate, endDate }, language) => {
    return startDate === undefined ? {} : { from: startDate, to: endDate, language };
};

export const useWithInvestmentOverviewPerformance = (
    filter,
    clientId,
    currency,
    isLoadingCurrency,
    filterDates
) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({
        assets: [{}],
        performance: [{}],
    });
    const { i18n } = useTranslation();
    const { locale } = useLocale();
    const { getFormattedNumber, getFormattedCurrency, getFormattedDate } = useFormatting();
    const { isMobileSize } = useDetectedMobileDevice();

    useEffect(() => {
        const isMonthToMothLoadAllowed =
            filter === MONTH_TO_MONTH &&
            !!filterDates.startDate &&
            !!filterDates.endDate &&
            getLastDayOfMonth(filterDates.startDate) < getLastDayOfMonth(filterDates.endDate);
        const isDataLoadAllowed =
            !isLoadingCurrency && filter !== MONTH_TO_MONTH ? true : isMonthToMothLoadAllowed;

        if (isDataLoadAllowed) {
            const params = getParamsByFilter(
                getPerformancePeriodByFilterOrDates(filter, filterDates),
                i18n.language
            );

            setLoading(true);
            ServiceManager.portfolioManagement('getInvestmentOverviewPerformance', [
                clientId,
                params,
            ])
                .then((response) => {
                    try {
                        setData(
                            adaptInvestmentOverviewPerformance(
                                response.data.Values,
                                currency,
                                params.from,
                                filter,
                                isMobileSize,
                                getFormattedNumber,
                                getFormattedCurrency,
                                getFormattedDate
                            )
                        );
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        }
    }, [
        filter,
        locale,
        clientId,
        i18n.language,
        isLoadingCurrency,
        filterDates.startDate,
        filterDates.endDate,
    ]);

    return { data, isLoading, error };
};
