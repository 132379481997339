// validation params probably auth domain
import CustomServiceManager from 'services/custom/CustomServiceManager';

export const validateUrlParams = async (targetObject, propertiesToCheck) => {
    if (typeof propertiesToCheck !== 'object' || propertiesToCheck?.length === 0) return false;

    for (let i = 0; i < propertiesToCheck.length; i += 1) {
        if (!targetObject[propertiesToCheck[i]]) {
            return false;
        }
    }

    const { data: validationData } = await CustomServiceManager.validateToken(
        targetObject.urlAccessToken
    );

    return validationData.Result === 'Success';
};
