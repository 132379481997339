import React from 'react';
import { ContentBox } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PlayStoreQrCode from '../images/play_store_qr.png';
import AppStoreQrCode from '../images/app_store_qr.png';
import { NsConnect, NsTopDomain } from '../const';
import '../Activation.css';
// eslint-disable-next-line react/prop-types
function ActivationPageEN() {
    return (
        <PageSection>
            <ContentBox>
                <p>
                    NS Partners provides you with an online access to your portfolio(s) directly on
                    a dedicated secured website and application: NS Connect.
                </p>
                <p>
                    The successful completion of the IT integration of transactional flows with our
                    main banking partners has made possible such a service. Assets prices are also
                    regularly updated. Please note the website and the app are not intended for
                    direct transactions. In addition, we remind you that only bank documents shall
                    be used for all your tax and regulatory reporting needs.
                </p>
                <p>You will find below the procedure to follow to benefit from this service.</p>
                <p>
                    For increased security, we have chosen for both the website and application a
                    two-factor authentication method.
                </p>
                <p>
                    Once you have set your password via the link provided to this effect in the
                    email you just received from <u>no-reply@{NsTopDomain}</u>
, please proceed as
                    explained below.
</p>
                <p>1. For access through the application</p>
                <p>
                    Download the NS Connect app from the AppStore or GooglePlay on your smartphone
                    or tablet. You can also scan the QR code below.
                </p>
                <p />
                <div className="qr-container">
                    <figure>
                        <img className="qr-image" src={AppStoreQrCode} alt="App Store" />
                        <figcaption className="centered-qr">AppStore</figcaption>
                    </figure>
                    <figure>
                        <img className="qr-image" src={PlayStoreQrCode} alt="Play Store" />
                        <figcaption className="centered-qr">GooglePlay</figcaption>
                    </figure>
                </div>
                <p>
                    Enter your username and password. Your smartphone or tablet will use the Facial
                    Recognition (&quot;Face ID&quot;) option to authenticate you.
                </p>
                <p>2. For access through the website </p>
                <p>
                    Go to &nbsp;
                    <a href={NsConnect} rel="noreferrer noopener">
                        nsconnect.{NsTopDomain}
                    </a>
                    &nbsp;(do not use Internet Explorer as a browser).
                </p>
                <p>Enter your username and password. </p>
                <p>
                    You will be prompted to download the Futurae app (Futurae Technologies AG) from
                    the App Store or GooglePlay on your smartphone or tablet. This will allow you to
                    authenticate yourself. Please simply follow the steps indicated as you proceed
                    on NS Connect, summarized hereafter.
                </p>
                <p>
                    For the first use only, you are invited to register your smartphone or tablet on
                    the Futurae app. To do this, re-enter your username and password on your
                    computer, then open the Futurae application on your device and scan the QR code
                    that appears on your computer.
                </p>
                <p>Your device is registered, you are now ready to log in.</p>
                <p>Enter your username and password. </p>
                <p>
                    Then enter the 6-digit code that will be given to you when opening the Futurae
                    application on your smartphone or tablet. This will systematically be necessary
                    for each connection, in order to identify you.
                </p>
                <p>
                    Do not hesitate to call your relationship manager or one of our employees,
                    especially during the first connection and the first uses of the product.
                </p>
            </ContentBox>
        </PageSection>
    );
}

ActivationPageEN.defaultProps = {};

export default ActivationPageEN;
