import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'components/Loading';
import { Button } from 'components/Button';
import { useTranslation } from 'react-i18next';
import { LOGIN_FAILED_UNEXPECTEDLY } from 'error/authErrors';
import { FuturaePasscode } from './FuturaePasscode';

const MobileAppApprove = (props) => {
    const {
        isPreAuthLoading,
        isLoading,
        error,
        onPreAuth,
        validatePasscode,
        passcodeLength,
        contactId,
        onBackToLogin,
    } = props;

    useEffect(() => {
        if (!contactId) {
            onPreAuth();
        }
    }, []);
    const { t } = useTranslation();

    // const { passcodeLength, provider, onLogin, t } = this.props;
    // const Passcode = provider === MTAN ? MTanPasscode : FuturaePasscode;
    const Passcode = FuturaePasscode;

    if (error?.type === LOGIN_FAILED_UNEXPECTEDLY) {
        return (
            <div className="buttons buttons-inline button-group button-group--horiz">
                <Button
                    type="primary"
                    onClick={onBackToLogin}
                    className="button button-primary back-to-login"
                >
                    {t('forgotPassword.backToLogin')}
                </Button>
            </div>
        );
    }

    return isPreAuthLoading ? (
        <Loading />
    ) : (
        <Passcode
            isLoading={isLoading}
            error={error}
            isMTan={false}
            onSubmit={validatePasscode}
            passcodeLength={passcodeLength}
            // onCancel={onLogin}
            // isMTan={provider === MTAN}
        />
    );
};

MobileAppApprove.propTypes = {
    isPreAuthLoading: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({ type: PropTypes.string, message: PropTypes.string }).isRequired,
    onPreAuth: PropTypes.func.isRequired,
    validatePasscode: PropTypes.func.isRequired,
    passcodeLength: PropTypes.number.isRequired,
    contactId: PropTypes.number.isRequired,
    onBackToLogin: PropTypes.func.isRequired,
};

MobileAppApprove.defaultProps = {};

MobileAppApprove.displayName = 'LoginPasscode';

export default MobileAppApprove;
