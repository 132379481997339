import React from 'react';
import PropTypes from 'prop-types';
import { renderers } from 'ui-library';

const { CellLinked } = renderers;

function CellLink({ label, link }) {
    return (
        <span className="table-cell_checkboxed">
            {CellLinked({
                value: label,
                link,
            })}
        </span>
    );
}

CellLink.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
};

CellLink.defaultProps = {
    label: '',
    link: '',
};

export default CellLink;
