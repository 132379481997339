import React, { useState } from 'react';
import { TabPane, Tabs, TabsExtraItem } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import PropTypes from 'prop-types';
import { allocationPercentFormat } from 'utils/formatting';
import { BAR, LIST } from 'constants/constants';
import ChartType from 'components/organisms/ChartType';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { useFormatting } from 'locale';

function Allocation({ data, isLoading, error }) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedCurrency } = useFormatting();
    const [chartView, setChartView] = useState(BAR);

    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) =>
        getFormattedCurrency(number, {
            currency: data.currency,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        });
    const labelFormat = (label, value) =>
        `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value)}`;

    const percentFormat = isMobileSize ? undefined : allocationPercentFormat;

    const tabBarExtraContent = () => (
        <>
            <TabsExtraItem
                icon="chart-bar-horizontal"
                isActive={chartView === BAR}
                onClick={changeView(BAR)}
            />
            <TabsExtraItem icon="list" isActive={chartView === LIST} onClick={changeView(LIST)} />
        </>
    );

    return (
        <Preloader isLoading={isLoading} error={error}>
            <Tabs
                className="investment-allocation"
                defaultActiveKey="1"
                tabBarExtraContent={tabBarExtraContent()}
            >
                {data.chart.map((item, i) => (
                    <TabPane tab={item.name} key={(i + 1).toString()}>
                        <EmptyContent data={item.data[chartView].data} text={t('dashboard.noData')}>
                            <div className={`chart-${i}`}>
                                <ChartType
                                    data={item.data[chartView].data}
                                    title={item.title || item.name}
                                    labels={item.data[chartView].labels}
                                    currency={data.currency}
                                    chartView={chartView}
                                    labelFormat={labelFormat}
                                    numberFormat={numberFormat}
                                    percentFormat={percentFormat}
                                    t={t}
                                />
                            </div>
                        </EmptyContent>
                    </TabPane>
                ))}
            </Tabs>
        </Preloader>
    );
}

Allocation.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        })
                    ),
                }),
            })
        ),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Allocation.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
};

export default Allocation;
