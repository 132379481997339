import { useEffect, useState } from 'react';
import DocumentsService from 'additiv-services/dist/services/core/documents';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import adaptDocument from '../adapters/adaptDocument';

export const file = (data) => ({
    // eslint-disable-next-line no-undef
    stringFile: atob(data.File),
    fileType: data.CustomAttributes,
    fileName: data.OriginalFilename,
});

export default (documentId, contactId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setError(null);
        setLoading(true);

        DocumentsService.downloadDocument(documentId, contactId)
            .then((response) => {
                try {
                    setData(adaptDocument(response.data));

                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, []);

    return { data, isLoading, error };
};
