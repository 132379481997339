import React from 'react';
import { ContentBox } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PlayStoreQrCode from '../images/play_store_qr.png';
import AppStoreQrCode from '../images/app_store_qr.png';
import { NsConnect, NsTopDomain } from '../const';
// eslint-disable-next-line react/prop-types
import '../Activation.css';

function ActivationPageIT() {
    return (
        <PageSection>
            <ContentBox>
                <p>
                    NS Partners vi fornisce un accesso on line al vostro portafoglio(i) direttamente
                    su un&apos;app e un sito web appositi: NS Connect.
                </p>
                <p>
                    Tale servizio è stato reso possibile grazie a l&apos;automatizzazione e a l&apos;integrazione
                    informatica relativa dei flussi transazionali con i nostri principali partner bancari.
                    L&apos;app e il sito web non sono progettati per eseguire delle transazioni in diretta.
                    Vi ricordiamo inoltre che solamente i documenti bancari saranno utilizzati per
                    l&apos;insieme delle vostre esigenze fiscali e di notificazione normativa.
                </p>
                <p>Di seguito troverete la procedura da seguire per attivare questo servizio. </p>
                <p>
                    Per una maggiore sicurezza, sia per il sito web che per l&apos;app abbiamo
                    selezionato un metodo di autenticazione a due fattori.
                </p>
                <p>
                    Una volta impostata la vostra password tramite il link fornito a tal scopo nell&apos;e-mail
                    che avete appena ricevuto da <u>no-reply@{NsTopDomain}</u>, vi preghiamo di procedere come
                    illustrato di seguito.
                </p>

                <p>1. Per l&apos;accesso via l&apos;app Scaricate da Play Store o App Store l&apos;app NS Connect sul vostro
                    smartphone o tablet. Avete anche la possibilità di scannerizzare il codice QR riportato di
                    seguito.
                </p>

                <div className="qr-container">
                    <figure>
                        <img className="qr-image" src={PlayStoreQrCode} alt="Play Store" />
                        <figcaption className="centered-qr">Play Store</figcaption>
                    </figure>
                    <figure>
                        <img className="qr-image" src={AppStoreQrCode} alt="App Store" />
                        <figcaption className="centered-qr">App Store</figcaption>
                    </figure>
                </div>

                <p>Digitate il vostro nome d&apos;utente e la password.</p>
                <p>
                    Il vostro smartphone o tablet utilizzerà l'opzione di riconoscimento facciale
                    (&quot;Face ID&quot;) per l&apos;autoidentificazione.
                </p>

                <p>2. Per l&apos;accesso via il sito web</p>
                <p>
                    Andate sulla pagina &nbsp;
                    <a href={NsConnect} rel="noreferrer noopener">
                        nsconnect.{NsTopDomain}
                    </a>
                    &nbsp;(non utilizzate Internet Explorer come browser).
                </p>
                <p>Digitate il vostro nome d&apos;utente e la password.</p>
                <p>
                    Vi verrà richiesto di scaricare da App Store o GooglePlay l&apos;app
                    Futurae (Futurae Technologies AG) sul vostro smartphone o tablet.
                    Così facendo avrete la possibilità di procedere alla vostra autentificazione.
                    Vi preghiamo di seguire i passaggi indicati mano a mano che proseguite su NS Connect
                    qui di seguito riassunti.
                </p>
                <p>
                    Unicamente per il primo utilizzo, siete invitati a registrare il vostro smartphone o tablet
                    sull&apos;app Futurae. Per procedere in tal senso, digitate il vostro nome utente e la password
                    sul vostro computer, in seguito accedete all&apos;app Futurae dal vostro dispositivo e scannerizzare
                    il codice QR che appare sul vostro computer.
                </p>
                <p>
                    Il vostro dispositivo è adesso registrato, potete quindi effettuare il vostro login.
                </p>
                <p>Digitate il vostro nome d&apos;utente e la password.</p>

                <p>
                    In seguito digitate il codice di 6 cifre che vi sarà fornito al momento dell&apos;accesso all'app
                    Futurae dal vostro smartphone o tablet. Tale passaggio vi sarà chiesto sistematicamente ad ogni
                    connessione per procedere alla vostra identificazione.
                </p>
                <p>
                    <p>
                        Non esitate a contattare il vostro relationship manager soprattutto nel corso della prima connessione
                        e durante le prime fasi di utilizzo del prodotto.
                    </p>
                </p>
            </ContentBox>
        </PageSection>
    );
}

ActivationPageIT.defaultProps = {};

export default ActivationPageIT;
