/* eslint-disable */
import React from 'react';
import { getColorClass } from 'utils/formatting';

const CellPercent = (getFormattedNumber) => (value) => {
    if (value === undefined || value === null) return '- -';

    const num = getFormattedNumber ? getFormattedNumber(value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }) : value;

    const sign = num > 0 ? '+' : '';

    return (
        <span className={getColorClass(value, 'table-cell')}>
            {sign}{num}%
        </span>
    );
};

export default CellPercent;
