class Error {
    constructor({ type, message, ...additionalData }) {
        this.type = type || 'SERVER_ERROR';
        this.message = message;
        this.additionalData = additionalData;
    }

    get getError() {
        return {
            type: this.type,
            message: this.message,
            additionalData: this.additionalData,
        };
    }
}

export default Error;
