import { innerHTMLRender } from 'utils/innerHTMLRender';
import { EXTERNAL_ORIGIN } from '../constants/constants';
import { checkNullData } from '../../../../utils';
import { dateTimeFormat, formatDate } from '../../../../utils/datetime';

export const adaptWPNews = ({ data, totalPages }) => {
    if (checkNullData(data) || checkNullData(data)) return [];

    return {
        posts: data.map((item) => ({
            id: item.id,
            date: formatDate(item?.date, dateTimeFormat, 'YYYY-MM-DD HH:mm:ss'),
            title: innerHTMLRender(item?.title?.rendered),
            image: item?.image,
            content: innerHTMLRender(item?.excerpt?.rendered),
            fullContent: innerHTMLRender(item?.content?.rendered),
            originLink: decodeURIComponent(item?.link),
            origin: EXTERNAL_ORIGIN,
        })),
        totalPages,
    };
};
