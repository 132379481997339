import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { Preloader } from 'components/molecules/Preloader';
import PageSection from 'components/PageSection';
import { EmptyContent } from 'components/molecules/EmptyContent';
import PortfoliosCard from './component/PortfoliosCard';
import './PortfoliosList.css';

const PortfoliosList = ({ data, isLoading, error }) => {
    const { t } = useTranslation();

    return (
        <PageSection>
            <Preloader isLoading={isLoading} error={error}>
                <Title type={2} link="/portfolios">
                    {t('portfolios.title')}
                </Title>
                <EmptyContent data={data} text={t('portfolios.noPortfoliosFound')}>
                    <div className="PortfoliosList">
                        {data.map((item) => (
                            <PortfoliosCard key={item.Name?.link} {...item} />
                        ))}
                    </div>
                </EmptyContent>
            </Preloader>
        </PageSection>
    );
};

PortfoliosList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};
PortfoliosList.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
};

export default PortfoliosList;
