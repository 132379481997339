import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import { useTranslation } from 'react-i18next';
import EnrollementInfoBox from 'components/EnrollmentInfoBox';

const ToEnrollDevice = ({ isSubmitting, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <>
            <EnrollementInfoBox />
            <div className="buttons buttons-inline button-group button-group--horiz">
                <Button type="primary" htmlType="submit" onClick={onSubmit} disabled={isSubmitting}>
                    {t('login.continue')}
                </Button>
            </div>
        </>
    );
};

ToEnrollDevice.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

ToEnrollDevice.defaultProps = {};

ToEnrollDevice.displayName = 'ToEnrollDevice';

export default ToEnrollDevice;
