export const GA_CATEGORIES = {
    dashboard: 'Dashboard',
    portfolio: 'Portfolio',
    download: 'Download',
    faq: 'FAQ',
    language: 'Language',
    contactRM: 'contact_RM',
    error: 'error',
};

export const GA_ACTIONS = {
    pageView: 'page_view',
    startLanguage: 'start_language',
    changeLanguage: 'change_language',
    viewRM: 'view_RM',
    expandFAQBlock: 'expand_block',
    changeGraphView: 'change_graph_view',
    changePerformanceFilter: 'change_performance_filter',
    factsheet: 'factsheet',
    document: 'document',
    showDetails: 'show_details',
    login: 'login',
};

export const gaSendPageView = ({ pathname }) => {
    if (window.gtag) {
        window.gtag('event', GA_ACTIONS.pageView, {
            page_path: pathname,
        });
    }
};

export const initAnalitics = (id, history) => {
    if (id) {
        const analiticsSrcScirpt = document.createElement('script');

        analiticsSrcScirpt.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        analiticsSrcScirpt.async = true;
        document.head.appendChild(analiticsSrcScirpt);

        const analiticsScirpt = document.createElement('script');

        analiticsScirpt.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${id}');
    `;

        document.head.appendChild(analiticsScirpt);

        if (history) {
            history.listen(gaSendPageView);
        }
    }
};

export const sendGAEvent = (actionName, categoryName, labelName) => {
    if (window.gtag) {
        window.gtag('event', actionName, {
            event_category: categoryName,
            event_label: labelName,
        });
    }
};
