import { useCallback, useMemo } from 'react';
// import { getDateFormat, getNumberFormat } from 'additiv-intl-formatting';
import { getDateFormat } from 'additiv-intl-formatting';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { formatNumberValue } from 'utils/formatting';

import { getDateTimeSeparators, getUTCDate } from './utils';
import { dateTimeFormats } from './formatTypes';
import useLocale from './useLocale';

export default () => {
    const { locale } = useLocale();

    const getFormattedDate = useCallback(
        (date, options = {}) => {
            if (!Date.parse(date)) return date;

            return getDateFormat({
                locale,
                date: getUTCDate(date),
                options: {
                    // timeZone: 'UTC',
                    year: dateTimeFormats.NUMERIC,
                    month: dateTimeFormats.NUMERIC,
                    day: dateTimeFormats.NUMERIC,
                    ...options,
                },
            });
        },
        [locale]
    );

    // hook for case when only HH:mm:ss should be displayed
    const getFormattedTime = useCallback(
        (date, options = {}) => {
            if (!Date.parse(date)) return date;

            return getDateFormat({
                locale,
                date: getUTCDate(date),
                options: {
                    hour: dateTimeFormats.NUMERIC,
                    minute: dateTimeFormats.NUMERIC,
                    ...options,
                },
            });
        },
        [locale]
    );

    // override original getFormattedNumber and getFormattedCurrency functions due to
    // client's request to format numbers ignoring locales
    const getFormattedNumber = (number = 0, options = {}) =>
        typeof number === 'number'
            ? formatNumberValue(number, options?.maximumFractionDigits)
            : number;

    const getFormattedCurrency = (number = 0, options = {}) => {
        const { currency } = options;

        const formatted = typeof number === 'number' ? formatNumberValue(number, 0) : number;

        return currency ? `${currency} ${formatted}` : formatted;
    };

    // const getFormattedNumber = useCallback(
    //     (number = 0, options = {}) => {
    //         const formatted =
    //             typeof number === 'number'
    //                 ? getNumberFormat({
    //                       // locale,
    //                       number,
    //                       options: {
    //                           maximumFractionDigits: 2,
    //                           minimumFractionDigits: 2,
    //                           ...options,
    //                       },
    //                   })
    //                 : number;
    //
    //         return formatted;
    //     },
    //     [locale]
    // );
    //
    // const getFormattedCurrency = useCallback(
    //     (value, options = {}) => {
    //         const { currency, ...rest } = options;
    //
    //         const formatted = getNumberFormat({
    //             locale,
    //             number: value,
    //             options: {
    //                 maximumFractionDigits: 0,
    //                 minimumFractionDigits: 0,
    //                 ...rest,
    //             },
    //         });
    //
    //         return currency ? `${currency} ${formatted}` : formatted;
    //     },
    //     [locale]
    // );

    const getFormattedPhoneNumber = useCallback(
        (phone) => {
            // eslint-disable-next-line prefer-template
            const parsed = parsePhoneNumberFromString('+' + String(phone).replace(/\D/g, ''));

            return parsed.formatInternational();
        },
        [locale]
    );

    const getFormattedXAxisFormat = useMemo(() => {
        const { dateSeparator, timeSeparator } = getDateTimeSeparators(locale.locale);

        return {
            millisecond: `%H${timeSeparator}%M${timeSeparator}%S.%L`,
            second: `%H${timeSeparator}%M${timeSeparator}%S`,
            minute: `%H${timeSeparator}%M`,
            hour: `%H${timeSeparator}%M`,
            day: `%d${dateSeparator}%m`,
            week: `%d${dateSeparator}%m`,
            month: '%b %y',
            year: '%Y',
        };
    }, [locale]);

    return {
        getFormattedCurrency,
        getFormattedDate,
        getFormattedTime,
        getFormattedNumber,
        getFormattedXAxisFormat,
        getFormattedPhoneNumber,
    };
};
