import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import { CURRENT_PAGE, PAGE_SIZE, TOTAL_PAGES } from 'constants/constants';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { useFormatting } from 'locale';
import { positionsColumns, positionsMobileColumns } from '../constants';

function Positions({ data, isLoading, error }) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedNumber } = useFormatting();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('portfolios.noPositionsFound')}>
                <Table
                    data={data}
                    columns={
                        isMobileSize
                            ? positionsMobileColumns(t)
                            : positionsColumns(t, getFormattedNumber)
                    }
                    expandedColumn="name"
                    paging={{
                        total: TOTAL_PAGES,
                        currentPage: CURRENT_PAGE,
                        pageSize: PAGE_SIZE,
                    }}
                    headerControls={{
                        selectControlsLabel: {
                            selectAll: t('headerControls.selectAll'),
                            reset: t('headerControls.reset'),
                        },
                    }}
                />
            </EmptyContent>
        </Preloader>
    );
}

Positions.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    Name: PropTypes.string,
                    Product: PropTypes.string,
                    Profile: PropTypes.string,
                    Value: PropTypes.string,
                    Performance: PropTypes.string,
                    Icon: PropTypes.string,
                    subRows: PropTypes.arrayOf(
                        PropTypes.shape({
                            Name: PropTypes.string,
                            Product: PropTypes.string,
                            Profile: PropTypes.string,
                            Value: PropTypes.string,
                            Performance: PropTypes.string,
                            Icon: PropTypes.string,
                        })
                    ),
                })
            ),
        })
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Positions.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
};

export default Positions;
