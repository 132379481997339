import { useEffect, useState } from 'react';
import { getDownloadDocumentUrlCredentials } from 'utils/url';
import { validateUrlParams } from 'pages/pages/DocumentsPreview/api/auth';
import { updateStateApi } from 'pages/pages/DocumentsPreview/api/common';

const useDocumentLoadFromUrl = (service, requiredPropsList) => {
    const [document, setDocument] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            const urlParams = getDownloadDocumentUrlCredentials(requiredPropsList);

            if (await validateUrlParams(urlParams, requiredPropsList)) {
                await service(urlParams, updateStateApi(setError, setDocument));
            }

            setLoading(false);
        })();
    }, []);

    return { isLoading, error, document };
};

export default useDocumentLoadFromUrl;
