import React from 'react';
import { ContentBox } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PlayStoreQrCode from '../images/play_store_qr.png';
import AppStoreQrCode from '../images/app_store_qr.png';
import { NsConnect, NsTopDomain } from '../const';
// eslint-disable-next-line react/prop-types
import '../Activation.css';

function ActivationPagePR() {
    return (
        <PageSection>
            <ContentBox>
                <p>
                    A NS Partners disponibiliza-lhe acesso online à(s) sua(s) carteira(s)
                    diretamente através de um site Web e aplicação específca: NS Connect.
                </p>
                <p>
                    Este serviço foi possível graças à integração informática dos fluxos transacionais
                    com os nossos principais parceiros da banca. Os dados dos ativos são atualizados
                    regularmente. O site Web e a aplicação, não se destinam a efetuar transações.
                    Gostaríamos de recordar que apenas os documentos bancários, deverão ser usados
                    para efeitos de cumprimento das obrigações fiscais e regulatórias.
                </p>
                <p>
                    Em seguida, apresentamos-lhe os passos a seguir para beneficiar deste serviço.
                </p>
                <p>
                    Para garantir uma maior segurança, optámos pelo método de autentificação de dois fatores,
                    tanto para o site Web como para a aplicação.
                </p>
                <p>
                    Depois de definir a password através da ligação fornecida para o efeito, no email que acabou de
                    receber da <u>no-reply@{NsTopDomain}</u>, siga os passos indicados abaixo.
                </p>

                <p>1. Para aceder através da aplicação </p>
                <p>
                    Transfira a aplicação NS Connect a partir da AppStore ou GooglePlay ara o seu smartphone ou tablet.
                    Também pode ler o código QR abaixo.
                </p>
                <div className="qr-container">
                    <figure>
                        <img className="qr-image" src={AppStoreQrCode} alt="App Store" />
                        <figcaption className="centered-qr">AppStore</figcaption>
                    </figure>
                    <figure>
                        <img className="qr-image" src={PlayStoreQrCode} alt="Play Store" />
                        <figcaption className="centered-qr">GooglePlay</figcaption>
                    </figure>
                </div>
                <p>
                    Insira o nome de utilizador e a password. O seu smartphone ou tablet,
                    utilizará a opção de reconhecimento facial (&quot;Face ID&quot;) ara proceder
                    à sua autenticação.
                </p>

                <p>2. Para aceder através do sítio Web</p>
                <p>
                    Vá à seguinte página: &nbsp;
                    <a href={NsConnect} rel="noreferrer noopener">
                        nsconnect.{NsTopDomain}
                    </a>
                    &nbsp;(não utilize o navegador Internet Explorer).
                </p>
                <p>Insira o nome de utilizador e a password.</p>
                <p>
                    Ser-lhe-á pedido que transfira a aplicação Futurae (Futurae Technologies AG) da AppStore
                    ou GooglePlay para o seu smartphone ou tablet.
                    Tal servirá para proceder à sua autenticação. Queira seguir os passos
                    indicados na NS Connect, resumidos abaixo.
                </p>
                <p>
                    Apenas aquando da primeira utilização, será convidado a registar o seu smartphone ou tablet
                    na aplicação Futurae. Para tal, volte a introduzir o nome de utilizador e palavra-passe no seu
                    computador, em seguida, abra a aplicação Futurae no seu dispositivo e leia o código QR que aparece
                    no computador.
                </p>
                <p>O seu dispositivo está registado e já pode iniciar sessão.</p>
                <p>Insira o nome de utilizador e a password.</p>
                <p>
                    De seguida, insira o código de 6 dígitos indicado quando abre a aplicação Futurae, no seu smartphone
                    ou tablet. Sempre que quiser iniciar sessão terá de indicar este código para fins de autenticação.
                </p>
                <p>
                    Não hesite em contactar o seu gestor ou um dos nossos funcionários,
                    especialmente da primeira vez que se ligar e utilizar o produto.
                </p>
            </ContentBox>
        </PageSection>
    );
}

ActivationPagePR.defaultProps = {};

export default ActivationPagePR;
