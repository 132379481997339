import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionPanel as Panel,
    ContentBox,
    Title,
    Row,
    Column,
    BreadCrumbs,
    Button,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { Preloader } from 'components/molecules/Preloader';
import history from 'services/core/history';
import { createBlobFile, openDocumentUrl } from 'utils/documentUtils';
import { getSecurityFactsheet } from 'services/custom/getSecurityFactsheet';
import { useFormatting } from 'locale';

import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';
import Overview from './components/Overview';
import { adaptPortfolioDetails } from './adapters/adaptPortfolioDetails';
import { adaptSecurities } from './adapters/adaptSecurities';
import { usePortfolioDetails } from '../../../common/hooks/usePortfolioDetails';
import { useSecuritiesSearch } from '../../../common/hooks/useSecuritiesSearch';
import { useSecurityDetails } from '../../../common/hooks/useSecurityDetails';

function PositionDetailsOverview(props) {
    const {
        match: {
            params: { positionId, portfolioId, productId, tabId },
        },
        contactId: clientId,
    } = props;

    const [isDownLoading, setLoading] = useState(false);

    const {
        dataPortfolioDetails,
        isLoadingPortfolioDetails,
        errorPortfolioDetails,
    } = usePortfolioDetails(adaptPortfolioDetails, clientId, portfolioId, positionId);

    const { getFormattedDate } = useFormatting();
    const { i18n } = useTranslation();

    const { securityDetails } = useSecurityDetails(dataPortfolioDetails.securityId);
    const securityPriceDate = getFormattedDate(securityDetails?.PriceHistory[0]?.Date);

    const onDownloadFactsheet = useCallback(() => {
        setLoading(true);
        getSecurityFactsheet(securityDetails, i18n.language)
            .then((data) => {
                const newBlob = createBlobFile(data);

                openDocumentUrl(newBlob);
                sendGAEvent(GA_ACTIONS.factsheet, GA_CATEGORIES.download, newBlob.fileName);
                setLoading(false);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                setLoading(false);
            });
    }, [securityDetails]);

    const { dataSecurities, isLoadingSecurities, errorSecurities } = useSecuritiesSearch(
        adaptSecurities,
        portfolioId,
        productId,
        null,
        positionId
    );

    const { t } = useTranslation();
    const defaultActiveKeyList = tabId === '1' ? ['1'] : ['1', '2'];

    return (
        <Row className="position-details-overview">
            <Column size="12">
                <Preloader
                    isLoading={isLoadingPortfolioDetails || isLoadingSecurities}
                    error={errorPortfolioDetails || errorSecurities}
                >
                    <ContentBox underline={false}>
                        <div className="row">
                            <div className="col-sm-12">
                                <BreadCrumbs current={securityDetails?.Name}>
                                    <Link to="/portfolios/">{t('portfolios.title')}</Link>
                                    <Link to={`/portfolios/${portfolioId}`}>
                                        {dataPortfolioDetails.portfolioName}
                                    </Link>
                                </BreadCrumbs>
                                <Title className="mb-0" type={1}>
                                    {securityDetails?.Name}
                                </Title>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <Accordion defaultActiveKey={defaultActiveKeyList}>
                                <Panel header={t('position.overview')} key="1" className="overview">
                                    <Overview
                                        isDownLoading={isDownLoading}
                                        currency={dataPortfolioDetails.currency}
                                        positionCurrency={dataPortfolioDetails.positionCurrency}
                                        // the calculation of positionValue is checked with the BA
                                        positionValue={
                                            dataPortfolioDetails.quantity *
                                            dataSecurities.price *
                                            dataPortfolioDetails.FxRate *
                                            dataPortfolioDetails.factor
                                        }
                                        quantity={dataPortfolioDetails.quantity}
                                        units={dataPortfolioDetails.units}
                                        price={dataSecurities.price}
                                        priceDate={securityPriceDate}
                                        performance={securityDetails?.performanceYTD}
                                        costPrice={dataPortfolioDetails.costPrice}
                                        portfolioAllocation={
                                            dataPortfolioDetails.portfolioAllocation
                                        }
                                        onDownload={onDownloadFactsheet}
                                        isFactsheet={securityDetails?.Factsheet}
                                    />
                                </Panel>
                            </Accordion>
                        </div>
                        <div className="buttons-block">
                            <Row className="mb-0">
                                <Column size={['sm-12']} className="text-sm-left button-group">
                                    <Button
                                        type="secondary"
                                        size="small"
                                        className="pull-left"
                                        onClick={() => history.goBack()}
                                    >
                                        {t('position.back')}
                                    </Button>
                                </Column>
                            </Row>
                        </div>
                    </ContentBox>
                </Preloader>
            </Column>
        </Row>
    );
}

PositionDetailsOverview.propTypes = {
    match: PropTypes.shape({
        params: {
            portfolioId: PropTypes.string.isRequired,
            positionId: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            tabId: PropTypes.string.isRequired,
        },
        url: PropTypes.string,
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

PositionDetailsOverview.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(PositionDetailsOverview);
