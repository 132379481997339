import ServiceManager from 'services/core/ServiceManager';
import {
    getAuthErrorByStatus,
    getErrorModelByStatus,
    LOGIN_FAILED_UNEXPECTEDLY,
} from 'error/authErrors';
import {
    EXPIRED,
    PENDING,
    SESSION_EXPIRED,
    SUCCESS,
    UNEXPECTED_ERROR,
} from 'static/requestResults';
import { cookies } from 'redux/auth/authActions';
import { AUTH_PASSCODE, ENROLL_DEVICE } from '../constants';

async function checkEnrollStatus({ username, enrollmentSessionId, qrCode }, callback) {
    const result = {};

    try {
        const { data } = await ServiceManager.deviceEnrollment('checkEnroll', [
            username,
            enrollmentSessionId,
        ]);

        const enrollResult = data.Result;
        const enrollStatus = data.Status;
        const enrollError = getErrorModelByStatus(enrollResult);

        if (enrollError !== undefined) {
            result.error = enrollError;
        } else if (enrollResult !== SUCCESS) {
            result.error = getErrorModelByStatus(UNEXPECTED_ERROR);
        } else if (enrollStatus === SUCCESS) {
            cookies.remove('trustedDeviceToken');
            result.enrollStatusTimeout = undefined;
            result.qrCode = undefined;
            result.status = AUTH_PASSCODE;
        } else if (enrollStatus === PENDING) {
            result.qrCode = qrCode;
            result.enrollStatusTimeout = setTimeout(() => {
                checkEnrollStatus(
                    {
                        username,
                        enrollmentSessionId,
                        qrCode,
                    },
                    callback
                );
            }, process.env.REACT_APP_QR_CODESTATUS_POLLING_INTERVAL);
        } else if (enrollStatus === EXPIRED) {
            result.status = EXPIRED;
            result.error = getErrorModelByStatus(SESSION_EXPIRED);
        }
        callback(result);
    } catch {
        result.error = getErrorModelByStatus(SESSION_EXPIRED);
        callback(result);
    }
}

export async function enrollDeviceByUserAndPassword(username, password, callback) {
    const result = {};

    try {
        const { data } = await ServiceManager.deviceEnrollment('enrollByUserAndPassword', [
            {
                username,
                password,
            },
        ]);

        const enrollStatus = data.Result;
        const errorObject = getAuthErrorByStatus(enrollStatus);

        if (errorObject !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            result.error = errorObject._errror;
        } else if (enrollStatus === SUCCESS) {
            result.qrCode = data.AsynchronousParameters?.ActivationQrCodeUrl;
            result.status = ENROLL_DEVICE;
            checkEnrollStatus(
                {
                    username,
                    enrollmentSessionId: data.EnrollmentSessionId,
                    qrCode: result.qrCode,
                },
                callback
            );
        } else {
            result.enrollError = getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY);
        }
        callback(result);
    } catch {
        result.enrollError = getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY);
        callback(result);
    }
}
