import React from 'react';
import { withAuthentication } from 'hocs';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RouteWithAppTitle from 'components/RouteWithAppTitle';
import { PageNotFound } from './pages/404';
import Dashboard from './pages/Dashboard/Router';
import News from './pages/News/Router';
import Portfolios from './pages/Portfolios/Router';
import { UserActivation } from './pages/UserActivation';
import Documents from './pages/Documents';
import ActivationEn from './pages/Activation/en';
import ActivationFr from './pages/Activation/fr';
import ActivationDe from './pages/Activation/de';
import ActivationIt from './pages/Activation/it';
import ActivationPr from './pages/Activation/pr';
import ActivationEs from './pages/Activation/es';
import Faq from './pages/Faq';
import ResetPassword from './pages/ResetPassword';
import { RootTemplate } from './templates';
import WithTermsOfUse from './containers/hocs/WithTermsOfUse';

export const RootRouter = ({ match: { path }, location: { pathname } }) => (
    <WithTermsOfUse>
        <RootTemplate pathname={pathname}>
            <Switch>
                <RouteWithAppTitle
                    path={`${path}dashboard`}
                    component={withAuthentication(Dashboard)}
                    title="Dashboard"
                />
                <RouteWithAppTitle
                    path={`${path}news`}
                    component={withAuthentication(News)}
                    title="News"
                />
                <RouteWithAppTitle
                    path={`${path}documents`}
                    component={withAuthentication(Documents)}
                    title="Documents"
                />
                <Route path={`${path}portfolios`} component={withAuthentication(Portfolios)} />
                <RouteWithAppTitle
                    path={`${path}faq`}
                    component={withAuthentication(Faq)}
                    title="FAQ"
                />
                <Route path={`${path}activation-en`} component={ActivationEn} />
                <Route path={`${path}activation-fr`} component={ActivationFr} />
                <Route path={`${path}activation-de`} component={ActivationDe} />
                <Route path={`${path}activation-it`} component={ActivationIt} />
                <Route path={`${path}activation-pr`} component={ActivationPr} />
                <Route path={`${path}activation-es`} component={ActivationEs} />
                <Route exact path="/404" component={PageNotFound} />
                <Redirect exact from={path} to={`${path}login`} />
                <RouteWithAppTitle
                    exact
                    path={`${path}activation/:email/:activationKey`}
                    component={withTranslation()(UserActivation)}
                    title="Email activation"
                />
                <RouteWithAppTitle
                    exact
                    path={`${path}reset-password/:email/:activationKey`}
                    component={ResetPassword}
                    title="Reset password activation"
                />
                <Redirect from="*" to="/404" />
            </Switch>
        </RootTemplate>
    </WithTermsOfUse>
);

RootRouter.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

RootRouter.defaultProps = {};

RootRouter.displayName = 'Router';
