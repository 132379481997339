import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ContentBox, Title } from 'ui-library';
import { CellPercent } from 'components/renderers';
import { useTranslation } from 'react-i18next';
import './PortfoliosCard.css';

const PortfoliosCard = ({ Name: { label, link }, Performance, Profile, Value }) => {
    const { t } = useTranslation();

    return (
        <Link to={link}>
            <ContentBox className="PortfoliosCard">
                <div className="portfolios-card-name">
                    <Title type={2}>{label}</Title>
                </div>
                <div className="portfolios-card-value">
                    <Title type={2}>{Value}</Title>
                </div>
                <div className="portfolios-card-profile">
                    <span className="profile-title">{t('dashboard.profile.title')}</span>
                    {Profile}
                </div>

                <div className="portfolios-card-performance">{CellPercent(Performance)}</div>
            </ContentBox>
        </Link>
    );
};

PortfoliosCard.propTypes = {
    Name: PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    }).isRequired,
    Performance: PropTypes.number.isRequired,
    Profile: PropTypes.string.isRequired,
    Value: PropTypes.string.isRequired,
};

PortfoliosCard.defaultProps = {};
export default PortfoliosCard;
