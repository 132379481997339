import React, { Fragment } from 'react';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import { TYPE_CURRENCY, TYPE_PERCENT } from 'utils/formatting';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Overview({ data }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <OverviewPanel>
                <OverviewItem
                    title={t('overview.portfolioValue')}
                    value={data.portfolioValue}
                    type={TYPE_CURRENCY}
                    currency={data.currency}
                />
                <OverviewItem
                    title={t('overview.performanceSI')}
                    htmlTitle={t('overview.performanceSIFull')}
                    value={data.performance}
                    type={TYPE_PERCENT}
                    colored
                />
                <OverviewItem title={t('overview.investmentProfile')} value={data.strategy} />
                <OverviewItem
                    title={t('overview.performanceYTD')}
                    value={data.performanceYTD}
                    type={TYPE_PERCENT}
                    colored
                />
            </OverviewPanel>
        </Fragment>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        currency: PropTypes.string,
        portfolioValue: PropTypes.number,
        strategy: PropTypes.string,
        product: PropTypes.string,
        performance: PropTypes.number,
        sharpRatio: PropTypes.number,
        expectedReturn: PropTypes.number,
        expectedRisk: PropTypes.number,
        firstInvestment: PropTypes.string,
        performanceYTD: PropTypes.number,
        firstInvestmentDate: PropTypes.string,
    }),
};

Overview.defaultProps = {
    data: {},
};

export default Overview;
