import { checkNullData, stripHtmlTags } from 'utils';
import { dateTimeFormats } from 'locale';

export const getNames = (data) => {
    const listOfNames = new Set();

    data.forEach((val) => listOfNames.add(val.name));

    return [...listOfNames];
};

export const adaptDocuments = (data, downloadDocument, t, getFormattedDate) => {
    if (checkNullData(data)) return [];

    return data.map(
        ({
            Id,
            OwnerId,
            ValidTill,
            CreateDate,
            ExternalId,
            OriginalFilename,
            RetrieveFileAsName,
            DocumentMetadata: {
                Name,
                IsActive,
                OutputFilename,
                ShowToCustomer,
                DmsDocumentCategoryName,
            },
        }) => {
            if (checkNullData(data)) return [];

            const documentName = RetrieveFileAsName || stripHtmlTags(Name || OutputFilename);

            return {
                id: Id,
                showToCustomer: {
                    value: '',
                    selected: ShowToCustomer,
                },
                name: documentName,
                nameCol: {
                    link: `/documents/${Id}/owner/${OwnerId}`,
                    value: documentName,
                    onDownload: downloadDocument,
                },
                valid: getFormattedDate(ValidTill, {
                    hour: dateTimeFormats.NUMERIC,
                    minute: dateTimeFormats.NUMERIC,
                    second: dateTimeFormats.NUMERIC,
                }),
                isActive: t(`advisoryDashboard.documents.${IsActive ? 'yes' : 'no'}`),
                filename: OriginalFilename || '',
                category: DmsDocumentCategoryName || '',
                contactId: OwnerId,
                externalId: ExternalId,
                createDate: getFormattedDate(CreateDate, {
                    hour: dateTimeFormats.NUMERIC,
                    minute: dateTimeFormats.NUMERIC,
                    second: dateTimeFormats.NUMERIC,
                }),
                icon: '',
            };
        }
    );
};
