import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DefaultContent from './DefaultContent';
import '../Footer.css';

/** Use the mini footer when required. */

function FooterMini({
    children, link, title, logo,
}) {
    const logoLink = typeof link !== 'string' ? link : <Link to={link} title={title}><img src={logo} alt={title} /></Link>;

    return (
        <footer className="Footer Footer_mini">
            <div className="container Footer__bottom">
                <span>{children}</span>
                {logoLink}
            </div>
        </footer>
    );
}

FooterMini.propTypes = {
    /** @ignore */
    children: PropTypes.node,
    /** Logo link */
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Alternative text for logo image and title attribute for the link */
    title: PropTypes.string,
    /** Source for the Logo */
    logo: PropTypes.node,
};

FooterMini.defaultProps = {
    children: <DefaultContent />,
    link: '/',
    title: 'notz stucki',
    logo: 'notz stucki',
};

export default FooterMini;
