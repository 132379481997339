import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Dropdown, MenuItem } from 'ui-library';

const CellWithMoreActions = ({ value, actions }) => (
    <Dropdown placement="bottomRight" title={<Icon type={value} />}>
        {actions &&
            actions.map((action) => <MenuItem onClick={action.onClick}>{action.text}</MenuItem>)}
    </Dropdown>
);

CellWithMoreActions.propTypes = {
    value: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
};

CellWithMoreActions.defaultProps = {
    value: '',
    actions: [],
};

export default CellWithMoreActions;
