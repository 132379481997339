import React, { useEffect, useRef } from 'react';

const ScrollTop = () => {
    const scrollTopElement = useRef(null);

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        let visible = false;

        function checkScrollHeight() {
            if (
                window.innerHeight <= window.pageYOffset * 1.2 &&
                !visible &&
                scrollTopElement.current
            ) {
                scrollTopElement.current.classList.add('visible');
                visible = true;
            } else if (
                window.innerHeight > window.pageYOffset * 1.2 &&
                visible &&
                scrollTopElement.current
            ) {
                scrollTopElement.current.classList.remove('visible');
                visible = false;
            }
        }

        window.addEventListener('scroll', checkScrollHeight);

        return () => window.removeEventListener('scroll', () => {});
    }, []);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className="scroll-top" onClick={scrollTop} ref={scrollTopElement}>
            ↑
            <br />
            BACK TO THE TOP
        </div>
    );
};

export default ScrollTop;
