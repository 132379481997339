import { checkNullData } from 'utils';
import { formatDate, dateTimeFormat } from 'utils/datetime';
import { INTERNAL_ORIGIN } from '../constants/constants';

const getValueByKey = (object, key) => {
    const value = object.Content.find((item) => item.Key === key);

    return value !== undefined ? value.Value : null;
};

export const adaptNews = ({ data }) => {
    if (checkNullData(data)) return [];

    return {
        posts: data.map((item) => ({
            id: item.Id,
            date: formatDate(getValueByKey(item, 'date'), dateTimeFormat, 'DD.MM.YYYY HH:mm'),
            title: getValueByKey(item, 'title'),
            image: getValueByKey(item, 'image'),
            content: getValueByKey(item, 'body'),
            link: getValueByKey(item, 'link'),
            origin: INTERNAL_ORIGIN,
        })),
    };
};
