// eslint-disable-next-line no-unused-vars
import React from 'react';

export const faqContent = (t) => [
    {
        title: 'faq.typesOfPersonalDataAvailable.heading',
        content: `<p>${t('faq.typesOfPersonalDataAvailable.paragraph')}</p>`,
    },
    {
        title: 'faq.whereDataStored.heading',
        content: `<p>${t('faq.whereDataStored.paragraph')}</p>`,
    },
    {
        title: 'faq.whenDataUpdated.heading',
        content: `<p>${t('faq.whenDataUpdated.paragraph')}</p>`,
    },
    {
        title: 'faq.datesUsedForChartSelections.heading',
        content: `<p>${t('faq.datesUsedForChartSelections.paragraph')}</p>`,
    },
    {
        title: 'faq.startDateRefers.heading',
        content: `<p>${t('faq.startDateRefers.paragraph')}</p>`,
    },
    {
        title: 'faq.anotherEmail.heading',
        content: `<p>${t('faq.anotherEmail.paragraph')}</p>`,
    },
    {
        title: 'faq.subscribeToNews.heading',
        content: `<p>${t('faq.subscribeToNews.paragraph')}</p>`,
    },
    {
        title: 'faq.seeLiveData.heading',
        content: `<p>${t('faq.seeLiveData.paragraph')}</p>`,
    },
    {
        title: 'faq.secureMyConnection.heading',
        content: `
            <p>
                ${t('faq.secureMyConnection.paragraph.weUseAppropriateTech')}
                <br />
                ${t('faq.secureMyConnection.paragraph.basicSecurityRecommended')}
            </p>
            <ul>
                <li>${t('faq.secureMyConnection.list.selectKnownProvider')}</li>
                <li>${t('faq.secureMyConnection.list.preferSecureWifi')}</li>
                <li>${t('faq.secureMyConnection.list.installAntivirus')}</li>
                <li>${t('faq.secureMyConnection.list.updateFireWall')}</li>
                <li>${t('faq.secureMyConnection.list.installOsUpdates')}</li>
                <li>${t('faq.secureMyConnection.list.installTrustedSoftware')}</li>
                <li>${t('faq.secureMyConnection.list.useLatestBrowserVersion')}</li>
                <li>${t('faq.secureMyConnection.list.checkCertificate')}</li>
                <li>${t('faq.secureMyConnection.list.logOutFormSession')}</li>
                <li>${t('faq.secureMyConnection.list.cleanCache')}</li>
            </ul>
        `,
    },
    {
        title: 'faq.portfolioPerformanceCalculation.heading',
        content: `
            <p>
                ${t('faq.portfolioPerformanceCalculation.paragraph.accordingTWR')}
                <br />
                ${t('faq.portfolioPerformanceCalculation.paragraph.thisMethodCalculates')}
            </p>
        `,
    },
    {
        title: 'faq.assetPerformanceCalculation.heading',
        content: `
            <p>
                ${t('faq.assetPerformanceCalculation.paragraph.assetsAreInstruments')}
                <br />
                ${t('faq.assetPerformanceCalculation.paragraph.calculatedAsFollows')}
                <br />
                ${t('faq.assetPerformanceCalculation.paragraph.costPriceWeighted')}
            </p>
        `,
    },
    {
        title: 'faq.strategyDictionary.heading',
        content: `
            <p>
                <dfn>${t('faq.strategyDictionary.cash')}: </dfn>
                ${t('faq.strategyDefinition.cash')}
            </p>
            <p>
                <dfn>${t('faq.strategyDictionary.currencyHedge')}: </dfn>
                ${t('faq.strategyDefinition.currencyHedge')}
            </p>
            <p>
                <dfn>${t('faq.strategyDictionary.fixedIncome')}: </dfn>
                ${t('faq.strategyDefinition.fixedIncome')}
            </p>
            <p>
                <dfn>${t('faq.strategyDictionary.equities')}: </dfn>
                ${t('faq.strategyDefinition.equities')}
            </p>
            <p>
                <dfn>${t('faq.strategyDictionary.absoluteReturn')}: </dfn>
                ${t('faq.strategyDefinition.absoluteReturn')}
            </p>
            <p>
                <dfn>${t('faq.strategyDictionary.gold')}: </dfn>
                ${t('faq.strategyDefinition.gold')}
            </p>
            <p>
                <dfn>${t('faq.strategyDictionary.loan')}: </dfn>
                ${t('faq.strategyDefinition.loan')}

            <p>
                <dfn>${t('faq.strategyDictionary.longTermCommitment')}: </dfn>
                ${t('faq.strategyDefinition.longTermCommitment')}
            </p>
        `,
    },
    {
        title: 'faq.subSrategyDictionary.heading',
        content: `
            <p>
                <dfn>${t('faq.subSrategyDictionary.cash')}: </dfn>
                ${t('faq.subSrategyDefinition.cash')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.currencyHedge')}: </dfn>
                ${t('faq.subSrategyDefinition.currencyHedge')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.credit')}: </dfn>
                ${t('faq.subSrategyDefinition.credit')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.globalBonds')}: </dfn>
                ${t('faq.subSrategyDefinition.globalBonds')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.emergingBonds')}:</dfn>
                ${t('faq.subSrategyDefinition.emergingBonds')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.equitiesLong')}:</dfn>
                ${t('faq.subSrategyDefinition.equitiesLong')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.equitiesLongShort')}:</dfn>
                ${t('faq.subSrategyDefinition.equitiesLongShort')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.flexible')}:</dfn>
                ${t('faq.subSrategyDefinition.flexible')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.globalMacro')}:</dfn>
                ${t('faq.subSrategyDefinition.globalMacro')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.relativeValue')}:</dfn>
                ${t('faq.subSrategyDefinition.relativeValue')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.multiStrategyBonds')}:</dfn>
                ${t('faq.subSrategyDefinition.multiStrategyBonds')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.gold')}:</dfn>
                ${t('faq.subSrategyDefinition.gold')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.loan')}:</dfn>
                ${t('faq.subSrategyDefinition.loan')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.privateEquity')}:</dfn>
                ${t('faq.subSrategyDefinition.privateEquity')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.realEstate')}:</dfn>
                ${t('faq.subSrategyDefinition.realEstate')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.guarantee')}:</dfn>
                ${t('faq.subSrategyDefinition.guarantee')}
            </p>
            <p>
                <dfn>${t('faq.subSrategyDictionary.others')}:</dfn>
                ${t('faq.subSrategyDefinition.others')}
            </p>
        `,
    },
];
