// Finds Inception date for Dashboard overview widget.
// If Account has multiple portfolios, uses the oldest date
export const getAccountInceptionDate = (portfolios) => {
    let oldestDate = new Date();

    portfolios.forEach((portfolio) => {
        if (portfolio.createDate) {
            const createDate = new Date(portfolio.createDate);

            if (createDate < oldestDate) {
                oldestDate = createDate;
            }
        }
    });

    return oldestDate;
};
