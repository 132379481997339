import React from 'react';
import history from 'services/core/history';
import { Button, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import LogoImage from 'clientRequirements/images/logo-mobile.svg';

export function ForgotPassword() {
    const { t } = useTranslation();

    const gotoLogin = () => {
        history.push('/login');
    };

    return (
        <div className="container container-small detail_page ForgotPassword">
            <div className="detail_content content-pane">
                <Title className="application-title" type={1}>
                    <img src={LogoImage} alt="NS Partners" />
                </Title>
                <div className="content-pane-wrapper">
                    <div className="UsernameIdentity content-pane">
                        <h2 className="Title">{t('ForgotPassword.Title')}</h2>
                        <p className="info-text">{t('ForgotPassword.toResetPassContactRM')}</p>
                        <div className="buttons buttons-inline button-group button-group--horiz">
                            <Button type="primary" htmlType="button" onClick={gotoLogin}>
                                {t('forgotPassword.backToLogin')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
