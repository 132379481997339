import { cookies } from 'redux/auth/authActions';
import { DEVICE_NOT_ENROLLED, SUCCESS } from 'static/requestResults';
import CustomServiceManager from 'services/custom/CustomServiceManager';
import { getAuthErrorByStatus, LOGIN_FAILED_UNEXPECTEDLY } from 'error/authErrors';
import { AUTH_PASSCODE, AUTH_ALLOW, TO_ENROLL_DEVICE } from '../constants';

export async function preAuth(username, password, callback) {
    try {
        const result = { error: {} };
        const trustedDeviceToken = cookies.get('trustedDeviceToken');

        const { data } = await CustomServiceManager.logIn2({
            username,
            password,
            trustedDeviceToken,
        });

        const preAuthResult = data.Result;
        const errorObject = getAuthErrorByStatus(preAuthResult);

        if (errorObject !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            const responseError = errorObject._error;

            if (responseError.type === DEVICE_NOT_ENROLLED) {
                result.status = TO_ENROLL_DEVICE;
            } else {
                // eslint-disable-next-line no-underscore-dangle
                result.error = errorObject._error.type
                    ? responseError
                    : { type: 'error', message: responseError };
            }
        } else if (preAuthResult === SUCCESS) {
            result.contactId = data.CampaignContact.ContactId;

            const session = data.Session;

            if (session !== undefined) {
                result.sessionId = session.SessionId;
                result.jwtAccessToken = session.JwtAccessToken;
                result.status = AUTH_ALLOW;
            } else {
                result.status = AUTH_PASSCODE;
            }
        } else {
            result.error = { type: DEVICE_NOT_ENROLLED };
        }
        callback(result);
    } catch {
        callback({
            error: {
                type: LOGIN_FAILED_UNEXPECTEDLY,
                message: getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY)._error,
            },
        });
    }
}
