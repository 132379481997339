import { checkNullData } from 'utils';
import { getTimestamp, getDatesDifference } from 'utils/datetime';
import { adaptPlotsAmount } from 'utils/adaptPlotsAmount';

export const filterHistoricalPerformanceByDates = (
    historicalPerformance,
    { startDate, endDate }
) => {
    const performanceValues = historicalPerformance.Values || [];

    if (!startDate && !endDate) {
        return performanceValues;
    }

    const filteredValues = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < performanceValues.length; i++) {
        if (
            getDatesDifference(performanceValues[i].Date, startDate, 'days') >= 0 &&
            getDatesDifference(performanceValues[i].Date, endDate, 'days') <= 0
        ) {
            filteredValues.push(performanceValues[i]);
        }
    }

    return filteredValues;
};

export const adaptPerformancePlots = (data, isMobileSize) => {
    if (checkNullData(data)) return [];

    const values = adaptPlotsAmount(data, isMobileSize);
    const adaptedData = [];
    const basePerformance = values[0].Value;

    values.forEach(({ Value, Date }) => {
        const graphPerformance = (Value + 1) / (basePerformance + 1) - 1;

        adaptedData.push([getTimestamp(Date), Math.round(graphPerformance * 10000) / 100]);
    });

    return adaptedData;
};
