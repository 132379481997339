import { get } from 'lodash/fp';
import contactManagement from 'additiv-services/dist/services/core/contactManagement';
import instrumentsService from 'additiv-services/dist/services/core/instruments';
import PortfolioManagement from 'additiv-services/dist/services/core/portfolioManagement';
import Performance from 'additiv-services/dist/services/core/performance';
import Products from 'additiv-services/dist/services/core/products';
import Security, { security } from 'additiv-services/dist/services/core/security';
import commonService from 'additiv-services/dist/services/core/common';
import commonContent from 'additiv-services/dist/services/core/commonContent';
import currenciesService from 'additiv-services/dist/services/core/currencies';
import tradingService from 'additiv-services/dist/services/core/trading';
import complianceService from 'additiv-services/dist/services/core/compliance';
import attachments from 'additiv-services/dist/services/core/attachments';
import deviceEnrollment from 'additiv-services/dist/services/core/deviceEnrollment';
import messageService from 'additiv-services/dist/services/core/messages';
import Service from 'additiv-services/dist/services/core/service';
import { checkStatus } from './generalStatusCheck';

const serviceCall = (service, method, argsArray = []) => {
    const result = service[method](...argsArray);
    const promise = result instanceof Promise ? result : result[0];

    promise
        .then(() => {})
        .catch((reason) => {
            checkStatus(get('response.status', reason));
        });

    return result;
};

class ServiceManager {
    static Service(method, argsArray) {
        return serviceCall(Service, method, argsArray);
    }

    static tradingService(method, argsArray) {
        return serviceCall(tradingService, method, argsArray);
    }

    static compliance(method, argsArray) {
        return serviceCall(complianceService, method, argsArray);
    }

    static contactManagement(method, argsArray) {
        return serviceCall(contactManagement, method, argsArray);
    }

    static instrumentsService(method, argsArray) {
        return serviceCall(instrumentsService, method, argsArray);
    }

    static performance(method, argsArray) {
        return serviceCall(Performance, method, argsArray);
    }

    static portfolioManagement(method, argsArray) {
        return serviceCall(PortfolioManagement, method, argsArray);
    }

    static products(method, argsArray) {
        return serviceCall(Products, method, argsArray);
    }

    static security(method, argsArray) {
        return serviceCall(security, method, argsArray);
    }

    static Security(method, argsArray) {
        return serviceCall(Security, method, argsArray);
    }

    static commonService(method, argsArray) {
        return serviceCall(commonService, method, argsArray);
    }

    static currenciesService(method, argsArray) {
        return serviceCall(currenciesService, method, argsArray);
    }

    static commonContent(method, argsArray) {
        return serviceCall(commonContent, method, argsArray);
    }

    static attachments(method, argsArray) {
        return serviceCall(attachments, method, argsArray);
    }

    static messageService(method, argsArray) {
        return serviceCall(messageService, method, argsArray);
    }

    static deviceEnrollment(method, argsArray) {
        return serviceCall(deviceEnrollment, method, argsArray);
    }
}

export default ServiceManager;
