import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { adaptProfile } from '../adapters/adaptProfile';

export const useWithClientProfile = (clientId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ clientProfile: {}, RMProfile: {} });
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { getFormattedPhoneNumber } = useFormatting();

    useEffect(() => {
        setLoading(true);

        ServiceManager.contactManagement('getClientProfile', [clientId, `${i18n.language}`])
            .then((response) => {
                ServiceManager.contactManagement('getContactMembers', [clientId])
                    .then((responseMember) => {
                        try {
                            setData(
                                adaptProfile(
                                    response.data,
                                    responseMember.data,
                                    t,
                                    getFormattedPhoneNumber
                                )
                            );
                            setLoading(false);
                        } catch (err) {
                            throw new AdapterError(err);
                        }
                    })
                    .catch((err) => {
                        setError(err.type !== undefined ? err : new ServerError(err));
                        setLoading(false);
                    });
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language]);

    return { data, isLoading, error };
};
