import React from 'react';
import { Link } from 'react-router-dom';
import { ContentBox, Title, Row, Column, BreadCrumbs } from 'ui-library';
import PropTypes from 'prop-types';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import { useWPNewsPost } from '../../hooks/useWPNewsPost';

function SingleWPNewsView(props) {
    const {
        location: { query },
        match: {
            params: { newsId },
        },
        t,
    } = props;
    const { data, isLoading, error } = useWPNewsPost(query, { include: newsId });

    return (
        <Row className="single-news-item">
            <Preloader isLoading={isLoading} error={error}>
                <Column size="12">
                    <EmptyContent data={data?.[0]?.fullContent} text={t('news.noNews')}>
                        <ContentBox>
                            <div>
                                <BreadCrumbs current={data?.[0]?.title}>
                                    <Link to="/dashboard">{t('dashboard.title')}</Link>
                                    <Link to="/news">{t('news.title')}</Link>
                                </BreadCrumbs>
                                <Title className="mb-0" type={1}>
                                    {data?.[0]?.title}
                                </Title>
                            </div>
                            <div className="origin-link">
                                <a
                                    href={data?.[0]?.originLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('news.origin')}
                                </a>
                            </div>
                            <hr />
                            <Row className="mb-0">
                                <Column size={['md-11', 'sm-12']}>
                                    <div className="single-news-item--date">{data?.[0]?.date}</div>
                                    <div className="single-news-item--content">
                                        {data?.[0]?.fullContent}
                                    </div>
                                </Column>
                            </Row>
                        </ContentBox>
                    </EmptyContent>
                </Column>
            </Preloader>
        </Row>
    );
}

SingleWPNewsView.propTypes = {
    location: PropTypes.shape({
        query: {
            id: PropTypes.number,
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
        },
    }).isRequired,
    match: PropTypes.shape({
        params: { newsId: PropTypes.string.isRequired },
    }).isRequired,
    t: PropTypes.func.isRequired,
};

SingleWPNewsView.defaultProps = {};

export default SingleWPNewsView;
