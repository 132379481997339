/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Infobox, Title } from 'ui-library';
import historyRouter from 'services/core/history';
import { DEVICE_NOT_ENROLLED, PENDING, SUCCESS } from "static/requestResults";
import UserAndPassword from 'components/Form/UserAndPassword';
import { useTranslation } from 'react-i18next';
import EnrollementInfoBox from '../../../../../components/EnrollmentInfoBox/EnrollementInfoBox';
import ServiceManager from "../../../../../services/core/ServiceManager";
import { get } from "lodash/fp";
import { getErrorModelByStatus, LOGIN_FAILED_UNEXPECTEDLY } from "../../../../../error/authErrors";

export const FORM_NAME = 'login';
export const LOGIN_FORM_VIEW = 'LOGIN_FORM_VIEW';
export const ENROLL_DEVICE_VIEW = 'ENROLL_DEVICE_VIEW';

const renderForgotLink = (t) => (
    <Button
        onClick={() => {
            historyRouter.push('/forgot-password');
        }}
        type="secondary"
    >
        {t('login.FORGOT_PASSWORD')}
    </Button>
);

const LoginForm = (props) => {
    const {
        error,
        submitting,
        handleSubmit,
        onLogin2FA,
        qrCode,
        onEnroll,
        isEnrollSuccess,
        isEnrollDeviceSuccess,
        isEnrollUnsuccess,
    } = props;
    const { t } = useTranslation();
    const [view, setView] = useState(LOGIN_FORM_VIEW);
    const [errorContent, setError] = useState(null);

    const login = () => {
        const { reset } = props;

        reset();
        setView(LOGIN_FORM_VIEW);
    };

    if (error !== undefined && error !== null) {
        if (typeof error === 'string') {
            setError(
                <div className="validation-summary-errors">
                    <Infobox accent error>
                        {t(error)}
                    </Infobox>
                </div>
            );
        } else if (
            typeof error === 'object' &&
            error.type === DEVICE_NOT_ENROLLED &&
            view !== ENROLL_DEVICE_VIEW
        ) {
            setView(ENROLL_DEVICE_VIEW);
        } else if (isEnrollUnsuccess === true) {
            setError(
                <div className="validation-summary-errors">
                    <Infobox error>{t(error.message)}</Infobox>
                </div>
            );
        }
    }

    if (isEnrollDeviceSuccess === true && view !== LOGIN_FORM_VIEW) {
        return (
            <div className="content-pane">
                <Title type={2}>{t('enrollDevice.deviceHasBeenEnrolled')}</Title>
                <p className="info-text">{t('enrollDevice.readyToUse')}</p>
                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button type="primary" htmlType="submit" onClick={login}>
                        {t('enrollDevice.backToLogin')}
                    </Button>
                </div>
            </div>
        );
    }

    if (isEnrollSuccess && view !== LOGIN_FORM_VIEW) {
        return (
            <div className="content-pane">
                <h2 className="page-title paragraph-title t-center">{t('enrollDevice.title')}</h2>
                <p className="info-text">{`${t('enrollDevice.scanQRCode')}:`}</p>
                <div className="qr-code" style={{ backgroundImage: `url(${qrCode})` }} />
            </div>
        );
    }
    let newProps = null;

    if (view === LOGIN_FORM_VIEW) {
        newProps = {
            title: 'login.LOGIN',
            bottomLinks: renderForgotLink(t),
            error: errorContent,
            submitLabel: 'login.LOGIN',
            onSubmit: onLogin2FA,
        };
    }
    if (view === ENROLL_DEVICE_VIEW) {
        newProps = {
            title: 'login.LOGIN',
            info: EnrollementInfoBox,
            submitLabel: 'common.CONTINUE_MSG',
            onSubmit: onEnroll,
        };
    }

    return (
        <UserAndPassword
            {...newProps}
            error={errorContent}
            submitting={submitting}
            handleSubmit={handleSubmit}
            login={login}
        />
    );
};

LoginForm.propTypes = {
    error:
        PropTypes.string ||
        PropTypes.shape({
            message: PropTypes.string,
            type: PropTypes.string,
        }),
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
};

LoginForm.defaultProps = {
    error: undefined,
    title: null,
};

LoginForm.displayName = 'LoginForm';

export default LoginForm;
