import { SubmissionError } from 'redux-form';
import Cookies from 'universal-cookie';
import { get } from 'lodash/fp';
import {
    getErrorModelByStatus,
    getAuthErrorByStatus,
    LOGIN_FAILED_UNEXPECTEDLY,
} from 'error/authErrors';
import { enrollStatusSelector } from 'components/Profile/selector';
import {
    EXPIRED,
    PENDING,
    SESSION_EXPIRED,
    SUCCESS,
    UNEXPECTED_ERROR,
} from 'static/requestResults';
import ServiceManager from 'services/core/ServiceManager';
import { contactIdSelector } from '../auth/authSelectors';
import { getUserProfile } from '../auth/authActions';

let checkEnrollStatusTimeout;

const cookies = new Cookies();

export const UNENROLL_START = 'react-template-project/enroll/UNENROLL_START';
export const UNENROLL_SUCCESS = 'react-template-project/enroll/UNENROLL_SUCCESS';
export const UNENROLL_FAIL = 'react-template-project/enroll/UNENROLL_FAIL';
export const ENROLL_START = 'react-template-project/enroll/ENROLL_START';
export const ENROLL_SUCCESS = 'react-template-project/enroll/ENROLL_SUCCESS';
export const ENROLL_FAIL = 'react-template-project/enroll/ENROLL_FAIL';
export const DEVICE_ENROLL_START = 'react-template-project/enroll/DEVICE_ENROLL_START';
export const DEVICE_ENROLL_SUCCESS = 'react-template-project/enroll/DEVICE_ENROLL_SUCCESS';
export const DEVICE_ENROLL_FAIL = 'react-template-project/enroll/DEVICE_ENROLL_FAIL';
export const CLEAN_ENROLL = 'react-template-project/enroll/CLEAN_ENROLL';

export function cleanEnroll() {
    return { type: CLEAN_ENROLL };
}

export function unenrollStart() {
    return { type: UNENROLL_START };
}

export function unenrollComplete(payload) {
    return { type: UNENROLL_SUCCESS, payload };
}

export function unenrollFail(payload) {
    return { type: UNENROLL_FAIL, payload };
}

export function enrollStart() {
    return { type: ENROLL_START };
}

export function enrollComplete(payload) {
    return { type: ENROLL_SUCCESS, payload };
}

export function enrollFail(payload) {
    return { type: ENROLL_FAIL, payload };
}

export function enrollDeviceStart() {
    return { type: DEVICE_ENROLL_START };
}

export function enrollDeviceSuccess() {
    return { type: DEVICE_ENROLL_SUCCESS };
}

export function enrollDeviceFail(payload) {
    return { type: DEVICE_ENROLL_FAIL, payload };
}

export function cleanEnrollment() {
    return (dispatch) => {
        clearTimeout(checkEnrollStatusTimeout);
        dispatch(cleanEnroll());
    };
}

function checkEnrollStatus({ username, enrollmentSessionId, triggerUserProfile }, dispatch) {
    dispatch(enrollDeviceStart());
    ServiceManager.deviceEnrollment('checkEnroll', [username, enrollmentSessionId])
        .then((response) => {
            const enrollStatus = enrollStatusSelector(response.data);
            const resultType = response.data.Result;
            const errorObject = getErrorModelByStatus(resultType);

            if (errorObject !== undefined) {
                dispatch(enrollDeviceFail(errorObject));
            } else if (resultType === SUCCESS) {
                if (enrollStatus === SUCCESS) {
                    cookies.remove('trustedDeviceToken');
                    dispatch(enrollDeviceSuccess());
                    if (triggerUserProfile === true) {
                        dispatch(getUserProfile());
                    }
                } else if (enrollStatus === PENDING) {
                    checkEnrollStatusTimeout = setTimeout(() => {
                        checkEnrollStatus(
                            {
                                username,
                                enrollmentSessionId,
                                triggerUserProfile,
                            },
                            dispatch
                        );
                    }, process.env.REACT_APP_QR_CODESTATUS_POLLING_INTERVAL);
                } else if (enrollStatus === EXPIRED) {
                    dispatch(enrollDeviceFail(getErrorModelByStatus(SESSION_EXPIRED)));
                }
            } else {
                dispatch(enrollDeviceFail(getErrorModelByStatus(UNEXPECTED_ERROR)));
            }
        })
        .catch(() => {
            dispatch(enrollDeviceFail(getErrorModelByStatus(UNEXPECTED_ERROR)));
        });
}

export function deactivateTwoFactorAuthentication({ doNotTriggerUserProfile } = {}) {
    return (dispatch, getState) => {
        const contactId = contactIdSelector(getState());

        return new Promise((resolve, reject) => {
            dispatch(unenrollStart());
            ServiceManager.deviceEnrollment('unenroll', [
                contactId,
                {
                    DisableTwoFactor: true,
                },
            ])
                .then((response) => {
                    const resultType = response.data.Result;
                    const errorObject = getErrorModelByStatus(resultType);

                    if (errorObject !== undefined) {
                        dispatch(unenrollFail(errorObject));
                        reject(errorObject);
                    } else if (resultType === SUCCESS) {
                        if (doNotTriggerUserProfile === undefined) {
                            dispatch(getUserProfile());
                        }
                        dispatch(unenrollComplete(response.data));
                        resolve(response);
                    } else {
                        const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                        dispatch(unenrollFail(errorModel));
                        reject(errorModel);
                    }
                })
                .catch(() => {
                    const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                    dispatch(unenrollFail(errorModel));
                    reject(errorModel);
                });
        });
    };
}

export function enrollDeviceByContactId() {
    return (dispatch, getState) => {
        const contactId = contactIdSelector(getState());

        return new Promise((resolve, reject) => {
            dispatch(enrollStart());
            ServiceManager.deviceEnrollment('enroll', [contactId])
                .then((response) => {
                    const resultType = response.data.Result;
                    const errorObject = getErrorModelByStatus(resultType);

                    if (errorObject !== undefined) {
                        dispatch(enrollFail(errorObject));
                        reject(errorObject);
                    } else if (resultType === SUCCESS) {
                        dispatch(enrollComplete(response.data));
                        checkEnrollStatus(
                            {
                                username: get('data.UserName', response),
                                enrollmentSessionId: get('data.EnrollmentSessionId', response),
                                triggerUserProfile: true,
                            },
                            dispatch
                        );
                        resolve(response);
                    } else {
                        const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                        reject(enrollFail(errorModel));
                        dispatch(enrollFail(errorModel));
                    }
                })
                .catch(() => {
                    const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                    dispatch(enrollFail(errorModel));
                    reject(errorModel);
                });
        });
    };
}

export function replaceDevice() {
    return (dispatch) => {
        dispatch(deactivateTwoFactorAuthentication({ doNotTriggerUserProfile: true })).then(() => {
            dispatch(enrollDeviceByContactId());
        });
    };
}

export function enrollDeviceByUserAndPassword({ username, password }, dispatch) {
    const validatedLogin = /@/.test(username)
        ? username
        : `${username}@${global.AppConfig.USERNAME_EMAIL_DOMAIN}`;

    return new Promise((resolve, reject) => {
        ServiceManager.deviceEnrollment('enrollByUserAndPassword', [
            {
                username: validatedLogin,
                password,
            },
        ])
            .then((response) => {
                const resultType = response.data.Result;
                const errorObject = getAuthErrorByStatus(resultType);

                if (errorObject !== undefined) {
                    reject(new SubmissionError(errorObject));
                } else if (resultType === SUCCESS) {
                    dispatch(enrollComplete(response.data));
                    checkEnrollStatus(
                        {
                            username: get('data.UserName', response),
                            enrollmentSessionId: get('data.EnrollmentSessionId', response),
                        },
                        dispatch
                    );
                    resolve(response);
                } else {
                    reject(getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY));
                }
            })
            .catch(() => {
                reject(getAuthErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY));
            });
    });
}
