// NOTICE: this is custom hook used for Dashboard overview widget only
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YTD } from 'filters/investementPerformanceFilters';
import ServiceManager from 'services/core/ServiceManager';
import { getPeriodByFilter } from 'utils';
import ServerError from 'errors/ServerError';

const usePerformanceYTD = ({ isLoadingCurrency, clientId }) => {
    const [performanceData, setData] = useState();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!isLoadingCurrency) {
            setLoading(true);

            const { startDate, endDate } = getPeriodByFilter(YTD, 'MM/DD/YYYY');

            setLoading(true);
            ServiceManager.portfolioManagement('getInvestmentOverviewPerformance', [
                clientId,
                {
                    from: startDate,
                    to: endDate,
                    language: i18n.language,
                },
            ])
                .then(({ data }) => {
                    if (data?.Values?.length) {
                        setData(
                            Math.round(data.Values[data.Values.length - 1].Value * 10000) / 100
                        );
                    }

                    setLoading(false);
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        }
    }, [isLoadingCurrency, clientId]);

    return { value: performanceData, isLoading, error };
};

export default usePerformanceYTD;
