import React from 'react';
import PropTypes from 'prop-types';
import News from 'components/organisms/News';
import { useNews } from './hooks/useNews';

function NewsPage() {
    const news = useNews();

    return <News {...news} />;
}

NewsPage.propTypes = {
    match: PropTypes.shape({ params: { id: PropTypes.string.isRequired } }).isRequired,
};

NewsPage.defaultProps = {};

export default NewsPage;
