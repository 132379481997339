import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import TermsText from './TermsText';

const TermsOfUse = ({ setTerms }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="terms-section">
                <div className="terms-container">
                    <div className="terms__header">
                        <h1>{t('termsOfUse.disclaimer')}</h1>
                    </div>
                    <div className="terms__content">
                        <TermsText />
                    </div>
                    <div className="terms__footer">
                        <p className="terms__notification">
                            {t('termsOfUse.disclaimerDescription')}
                        </p>
                        <Button
                            type="primary"
                            className="button button-primary"
                            onClick={() => setTerms()}
                        >
                            {t('termsOfUse.accept')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

TermsOfUse.propTypes = {
    setTerms: PropTypes.func.isRequired,
};

export default TermsOfUse;
