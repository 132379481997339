import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import {
    formatSignPercent,
    formatValueByType,
    getColorClass,
    TYPE_TEXT,
    VALUE_TYPES,
} from 'utils/formatting';
import { useFormatting } from 'locale';
import { getClassNames } from '../../utils';
import { OVERVIEW_SIZE_LAGRE, OVERVIEW_SIZES } from './constants';

function OverviewItem({
    title,
    htmlTitle,
    value,
    size,
    type,
    colored,
    percent,
    // link,
    // linkLabel,
    currency,
}) {
    const { getFormattedNumber } = useFormatting();

    const className = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__value'),
        `overview-item__value_${size}`
    );

    return (
        <dl className="overview-item">
            {title && (
                <dt title={htmlTitle} className="overview-item__title">
                    {title}
                </dt>
            )}
            <dd>
                <span
                    className={className}
                    title={formatValueByType(value, type, false, getFormattedNumber)}
                >
                    {formatValueByType(value, type, colored, getFormattedNumber)}
                </span>
                {currency !== null && <span className="overview-item__currency">{currency}</span>}
                {percent !== null && (
                    <span className={getColorClass(percent, 'overview-item__percent')}>
                        {formatSignPercent(percent)}
                    </span>
                )}
            </dd>
            {/* {link && ( */}
            {/*    <Link className="paragraph_secondary" to={link}> */}
            {/*        {linkLabel} */}
            {/*    </Link> */}
            {/* )} */}
        </dl>
    );
}

OverviewItem.propTypes = {
    title: PropTypes.string.isRequired,
    htmlTitle: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOf(OVERVIEW_SIZES),
    type: PropTypes.oneOf(VALUE_TYPES),
    colored: PropTypes.bool,
    percent: PropTypes.number,
    // link: PropTypes.string,
    // linkLabel: PropTypes.string,
    currency: PropTypes.string,
};

OverviewItem.defaultProps = {
    htmlTitle: '',
    size: OVERVIEW_SIZE_LAGRE,
    type: TYPE_TEXT,
    colored: false,
    percent: null,
    // link: null,
    // linkLabel: null,
    currency: '',
};

export default OverviewItem;
