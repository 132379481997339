import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';

export const useSecuritiesSearch = (adaptSecurities, portfolioId, productId, data, positionId) => {
    const [errorSecurities, setError] = useState(null);
    const [isLoadingSecurities, setLoading] = useState(true);
    const [dataSecurities, setData] = useState([]);
    const [totalSecurities, setTotalSecurities] = useState(0);
    const { i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        ServiceManager.instrumentsService('searchSecurities', [
            productId,
            data || {
                Page: 1,
                PageSize: 1000,
            },
            { language: i18n.language },
        ])
            .then((response) => {
                try {
                    setData(
                        adaptSecurities(response.data, {
                            portfolioId,
                            productId,
                            positionId,
                        })
                    );
                    setTotalSecurities(response.data.TotalNumberOfResults || 0);
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [portfolioId, productId, data, i18n.language]);

    return {
        dataSecurities,
        totalSecurities,
        isLoadingSecurities,
        errorSecurities,
    };
};
