import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberRounding } from 'utils/formatting';
import PropTypes from 'prop-types';
import AssetDevelopment from 'components/organisms/AssetDevelopment';
import useFormatting from 'locale/useFormatting';
import { SINCE_INCEPTION } from 'filters/investementPerformanceFilters';
import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';
import { useWithInvestmentOverviewPerformance } from '../hooks/useWithInvestmentOverviewPerformance';

const chartValueFormat = {
    performance: {
        symbol: '%',
        precision: 2,
        thousand: ',',
        decimal: '.',
        format: '%v%s',
    },
    assets: {
        symbol: 'K',
        precision: 0,
        thousand: '.',
        decimal: ',',
        format: '%v%s',
    },
};

const AssetDevelopmentComponent = ({ clientId, currency, isLoading }) => {
    const { t } = useTranslation();
    const [currentFilter, setCurrentFilter] = useState(SINCE_INCEPTION);
    const [filterDates, setFilterDates] = useState({});
    const setCurrentFilterWithGA = (filter) => {
        sendGAEvent(GA_ACTIONS.changePerformanceFilter, GA_CATEGORIES.dashboard, filter);
        setCurrentFilter(filter);
    };
    const performance = useWithInvestmentOverviewPerformance(
        currentFilter,
        clientId,
        currency,
        isLoading,
        filterDates
    );

    const { getFormattedNumber } = useFormatting();
    const yAxisFormat = {
        performance: ({ value }) => `${getFormattedNumber(value)}%`,
        assets: ({ value }) => formatNumberRounding(value, getFormattedNumber),
    };

    return (
        <AssetDevelopment
            {...performance}
            title={t('dashboard.portfolioPerformance.title')}
            currency={currency}
            currentFilter={currentFilter}
            onFilterChange={setCurrentFilterWithGA}
            yAxisFormat={yAxisFormat}
            chartValueFormat={chartValueFormat}
            filterDates={filterDates}
            setFilterDates={setFilterDates}
        />
    );
};

AssetDevelopmentComponent.propTypes = {
    currency: PropTypes.string.isRequired,
    clientId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

AssetDevelopmentComponent.defaultProps = {};

export default AssetDevelopmentComponent;
