import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OVERVIEW_SIZE_SMALL, OverviewItem, OverviewPanel } from 'components/Overview';
import { TYPE_CURRENCY, TYPE_PERCENT, TYPE_TEXT } from 'utils/formatting';

function Overview({
    currency,
    positionCurrency,
    positionValue,
    units,
    quantity,
    price,
    priceDate,
    costPrice,
    portfolioAllocation,
    onDownload,
    isFactsheet,
    isDownLoading,
}) {
    const { t } = useTranslation();
    const showFullView = Number(quantity) > 0;

    return (
        <div>
            {isFactsheet && (
                // eslint-disable-next-line react/button-has-type
                <button
                    type="secondary"
                    className="button button-small button-secondary factsheet-button"
                    onClick={onDownload}
                    disabled={isDownLoading}
                >
                    {t('overview.downloadFactsheet')}
                </button>
            )}
            <OverviewPanel>
                <div>
                    <OverviewItem
                        title={t('overview.price')}
                        value={price}
                        type={TYPE_CURRENCY}
                        currency={currency}
                    />
                    {showFullView && <OverviewItem title={t('overview.units')} value={units} />}
                </div>
                <div>
                    {showFullView && (
                        <OverviewItem
                            title={t('overview.positionValue')}
                            value={positionValue}
                            type={TYPE_CURRENCY}
                            currency={positionCurrency}
                        />
                    )}
                    <OverviewItem
                        title={t('overview.priceDate')}
                        value={priceDate}
                        type={TYPE_TEXT}
                        size={OVERVIEW_SIZE_SMALL}
                    />
                </div>
                {showFullView && (
                    <div>
                        <OverviewItem
                            title={t('overview.costPrice')}
                            value={costPrice}
                            type={TYPE_CURRENCY}
                            currency={currency}
                            size={OVERVIEW_SIZE_SMALL}
                        />
                        <OverviewItem
                            title={t('overview.portfolioAllocation')}
                            value={portfolioAllocation}
                            type={TYPE_PERCENT}
                            size={OVERVIEW_SIZE_SMALL}
                        />
                    </div>
                )}
            </OverviewPanel>
        </div>
    );
}

Overview.propTypes = {
    currency: PropTypes.string,
    positionCurrency: PropTypes.string,
    positionValue: PropTypes.number,
    units: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes.number,
    costPrice: PropTypes.number,
    portfolioAllocation: PropTypes.number,
    onDownload: PropTypes.func,
    isFactsheet: PropTypes.bool,
    priceDate: PropTypes.string.isRequired,
    isDownLoading: PropTypes.bool,
};

Overview.defaultProps = {
    isDownLoading: false,
    currency: '',
    positionCurrency: '',
    positionValue: undefined,
    units: '',
    quantity: 0,
    price: undefined,
    costPrice: undefined,
    portfolioAllocation: undefined,
    onDownload: () => {},
    isFactsheet: false,
};

export default Overview;
