import React from 'react';
import { AccordionPanel as Panel, ContentBox, Title, Accordion } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { innerHTMLRender } from 'utils/innerHTMLRender';
import PageSection from '../../../components/PageSection';
import { faqContent } from './content';

const Faq = () => {
    const { t } = useTranslation();

    return (
        <PageSection>
            <ContentBox className="faq">
                <Title type={2}>{t('faq.title')}</Title>
                <Accordion defaultActiveKey={[1]}>
                    {faqContent(t).map((item) => (
                        <Panel header={t(item.title)} key={item.title} className="faq__item">
                            {innerHTMLRender(item.content)}
                        </Panel>
                    ))}
                </Accordion>
            </ContentBox>
        </PageSection>
    );
};

export default Faq;
