import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';

const securityDetailsAdapter = (data) => {
    const customAttr = data.CustomAttributes ? JSON.parse(data.CustomAttributes) : {};

    return {
        ...data,
        performanceYTD: Math.round(customAttr.performanceYTD * 10000) / 100,
    };
};

export const useSecurityDetails = (securityId) => {
    const [securityDetails, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (securityId) {
            setLoading(true);

            ServiceManager.instrumentsService('getSecurity', [securityId, i18n.language])
                .then((response) => {
                    setData(securityDetailsAdapter(response.data));
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [securityId]);

    return { securityDetails, isLoading, isError };
};
