import { BAR, LIST, PIE } from 'constants/constants';
import {
    checkNullData,
    getParent,
    roundAllocations,
    sortAssetsByOrderPropOrAllocation,
} from 'utils';
import {
    allocationPercentFormat,
    formatCurrencyWithPrecision,
    formatPercentage,
    NA,
} from 'utils/formatting';
import { toPascalCase } from 'utils/toPascalCase';
import {
    getAssetClassOrder,
    ASSET_STRATEGY_IDS,
} from '../../Portfolios/pages/SinglePortfolioView/constants';

const adaptChartTypes = (data, currency) => ({
    [PIE]: { data },
    [BAR]: {
        data: [
            {
                data: data.map(({ value }) => value),
            },
        ],
        labels: data.map((item) => `${item.name} ${allocationPercentFormat(item.percent)}`),
    },
    [LIST]: {
        data: data.map((item) => ({
            name: item.name,
            value: formatCurrencyWithPrecision(item.value, 0, currency),
            percent: allocationPercentFormat(item.percent),
            children: item.children,
        })),
    },
});

// TODO: refactor the groups logic
const getAllocationsGroups = (positions, assetClasses, currencyPortfolio, t) => {
    // roundedAllocations: Array({id: [positionId], value: [allocationValue]})
    const roundedAllocations = roundAllocations(positions, 100, 1);

    const getPositionAllocation = (position) => {
        const roundedAllocation = roundedAllocations.find(({ id }) => id === position.Security.Id);

        return roundedAllocation ? roundedAllocation.value : position.Allocation * 100;
    };
    const groups = positions.reduce(
        (obj, item) => {
            const result = { ...obj };
            const positionAllocation = getPositionAllocation(item);

            if (item.Security.AssetClass) {
                // Asset class level
                const assetClassParent = getParent(item.Security.AssetClass);
                const typeName =
                    ASSET_STRATEGY_IDS.indexOf(assetClassParent.Id) >= 0
                        ? toPascalCase(assetClassParent.Name)
                        : assetClassParent.Name;

                result.type[typeName] = obj.type[typeName] || {};
                const type = result.type[typeName];

                type.sortingOrder = getAssetClassOrder(assetClassParent.Id);
                type.percentage = (type.percentage || 0) + positionAllocation;
                type.amount = (type.amount || 0) + item.InvestmentValue;

                // Sub-Asset class level
                const assetParent =
                    assetClasses[
                        assetClasses.findIndex((i) => i.children.includes(assetClassParent.Id))
                    ];
                const assetName = assetParent ? assetParent.Name : assetClassParent.Name;

                result.asset[assetName] = obj.asset[assetName] || {
                    children: [],
                };
                const asset = result.asset[assetName];
                const assetInd = asset.children.findIndex(
                    (child) => child.id === item.Security.AssetClass.Id
                );

                asset.id = assetParent ? assetParent.Id : item.Security.AssetClass.Id;
                asset.sortingOrder = getAssetClassOrder(asset.id);
                asset.percentage = (asset.percentage || 0) + positionAllocation;
                asset.amount = (asset.amount || 0) + item.InvestmentValue;

                if (assetInd !== -1) {
                    asset.children[assetInd].percent += roundedAllocations.find(
                        ({ id }) => id === item.Security.Id
                    ).value;
                    asset.children[assetInd].value += item.InvestmentValue
                        ? item.InvestmentValue
                        : 0;
                } else {
                    asset.children.push({
                        id: item.Security.AssetClass.Id,
                        name: toPascalCase(item.Security.AssetClass.Name),
                        percent: roundedAllocations.find(({ id }) => id === item.Security.Id).value,
                        value: item.InvestmentValue ? item.InvestmentValue : 0,
                    });
                }
            }

            if (item.Security.Currency !== undefined) {
                const currencyName = item.Security.Currency.Name;

                result.currency[currencyName] = obj.currency[currencyName] || {};
                const currency = result.currency[currencyName];

                currency.percentage = (currency.percentage || 0) + positionAllocation;
                currency.amount = (currency.amount || 0) + item.InvestmentValue;
            }

            return result;
        },
        {
            type: {},
            asset: {},
            currency: {},
        }
    );

    const gruopArrays = Object.keys(groups).map((groupKey) => {
        const group = groups[groupKey];

        const sorted = Object.keys(group)
            .map((key) => {
                return {
                    ...group[key],
                    id: group[key].id,
                    name: key,
                    value: group[key].amount,
                    percent: group[key].percentage,
                    children: group[key].children
                        ? group[key].children
                              .map((item) => ({
                                  ...item,
                                  percent: allocationPercentFormat(item.percent),
                                  value: item.value
                                      ? formatCurrencyWithPrecision(
                                            item.value,
                                            0,
                                            currencyPortfolio
                                        )
                                      : NA,
                              }))
                              .sort(sortAssetsByOrderPropOrAllocation)
                        : undefined,
                    label: formatPercentage(group[key].percentage * 100),
                };
            })
            .sort(sortAssetsByOrderPropOrAllocation);

        return sorted;
    });

    return {
        chart: [
            {
                name: 'Asset Classes',
                title: t('allocationTabs.strategyAllocation'),
                data: adaptChartTypes(gruopArrays[1], currencyPortfolio),
            },
            {
                name: 'Type',
                title: t('allocationTabs.subStrategy'),
                data: adaptChartTypes(gruopArrays[0], currencyPortfolio),
            },
            {
                name: 'Currency',
                title: t('allocationTabs.currencies'),
                data: adaptChartTypes(gruopArrays[2], currencyPortfolio),
            },
        ],
        currency: currencyPortfolio,
    };
};

const traversalTree = (current, childrenArr) => {
    // substategy level
    childrenArr.push(current.Data.Id);

    if (current.Children) {
        current.Children.forEach((child) => {
            traversalTree(child, childrenArr);
        });
    }
};

const flatArray = (array) => {
    const flatted = [];

    array.forEach((item) => {
        const { length } = flatted;

        // strategy level
        flatted.push({ ...item.Data, children: [] });
        traversalTree(item, flatted[length].children);
    });

    return flatted;
};

export const adaptParentsAllocations = (positions, assetClasses, currency, t) => {
    if (checkNullData(positions)) return { chart: [{ data: { pie: {}, bar: {}, list: {} } }] };
    const flatAssetClasses = flatArray(assetClasses);

    return getAllocationsGroups(positions, flatAssetClasses, currency, t);
};
