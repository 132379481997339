import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Infobox, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';

export const EnrollQRCode = ({
    qrCode,
    enrollError,
    // activatePassCodeMode,
    enrollStatusTimeout,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        let cleanUp = () => {};

        if (enrollStatusTimeout !== undefined) {
            cleanUp = clearTimeout(enrollStatusTimeout);
        }

        return cleanUp;
    }, []);

    return (
        <>
            {enrollError !== undefined && enrollError !== null && (
                <div className="validation-summary-errors">
                    <Infobox error>{t(enrollError)}</Infobox>
                </div>
            )}
            <p className="info-text">
                {t('enrollDevice.scanQRCode')}
                <br />
                {t('enrollDevice.ifYourMobileIsOffline')}
                <br />
                <br />
                <div className="buttons buttons-inline button-group button-group--horiz">
                    {/* <Button type="primary" htmlType="submit" onClick={activatePassCodeMode}> */}
                    {/*    {t('enrollDevice.backToLogin')} */}
                    {/* </Button> */}
                </div>
            </p>
            <div className="qr-code" style={{ backgroundImage: `url(${qrCode})` }} />
        </>
    );
};

EnrollQRCode.propTypes = {
    qrCode: PropTypes.string,
    enrollStatusTimeout: PropTypes.number,
    enrollError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

EnrollQRCode.defaultProps = {
    qrCode: null,
    enrollStatusTimeout: null,
    enrollError: null,
};

EnrollQRCode.displayName = 'EnrollQRCode';

export default EnrollQRCode;
