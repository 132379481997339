import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Header as HeaderUILibrary,
    Navigation,
    NavigationLink as NavLink,
    Toggle,
} from 'ui-library';
import { Logo } from 'components/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from 'redux/theme/themeActions';
import { getThemeSelector } from 'redux/theme/themeSelectors';
import { setThemeVariables } from 'utils/configureTheme';
import DetectorSystemTheme from 'services/core/DetectorSystemTheme';
import { NOTZ_STUCKI_MAIN_SITE } from '../../../constants/constants';

export const Header = ({ isAuthenticated, onRightButtonClick, pathname, profile }) => {
    const { t, i18n } = useTranslation();
    const [profileOpen, setProfileOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleNavigation = () => {
        setIsOpen(!isOpen);
    };
    const closeNavigation = () => {
        setIsOpen(false);
    };
    const current = useMemo(() => pathname.split('/')[1], [pathname]);
    const navigation = useMemo(
        () => [
            {
                title: t('MainMenu.DashboardMenuItem'),
                link: '/dashboard',
                active: current === 'dashboard',
            },
            {
                title: t('MainMenu.PortfoliosMenuItem'),
                link: '/portfolios',
                active: current === 'portfolios',
            },
            {
                title: t('MainMenu.Documents'),
                link: '/documents',
                active: current === 'documents',
            },
            { title: t('MainMenu.NewsMenuItem'), link: '/news', active: current === 'news' },
            { title: t('MainMenu.Faq'), link: '/faq', active: current === 'faq' },
        ],
        [pathname, i18n.language]
    );

    const dispatch = useDispatch();
    const theme = useSelector(getThemeSelector);
    const defaultChecked = useMemo(() => theme === 'dark', [theme]);
    const changeTheme = useCallback(
        (value) => {
            const newTheme = value ? 'dark' : 'light';

            dispatch(setTheme(newTheme));
            setThemeVariables(newTheme);
            document.documentElement.setAttribute('data-theme', newTheme);
        },
        [setTheme, setThemeVariables]
    );

    const detectorTheme = (e) => {
        changeTheme(e.matches);
    };

    useEffect(() => {
        DetectorSystemTheme.subscribe(detectorTheme);

        return () => {
            DetectorSystemTheme.unsubscribe(detectorTheme);
        };
    }, []);
    const profileInfo = useMemo(
        () => ({
            ...profile,
            withEdit: false,
            withAlert: true,
            logoutText: t('dashboard.profile.logout'),
            onLogout: () => {
                setProfileOpen(false);
                onRightButtonClick();
            },
            additionalInfo: (
                <>
                    <b>
                        {t('dashboard.profile.riskProfile')}
                        {': '}
                    </b>
                    {profile.riskProfile}
                    <br />
                    <b>
                        {t(
                            profile.portfolios > 1
                                ? 'dashboard.profile.portfolios'
                                : 'dashboard.profile.portfolio'
                        )}
                        {': '}
                    </b>
                    {profile.portfolios}
                    <div className="dark-theme-togle">
                        <h4>{t('dashboard.turnOnDarkMode')}</h4>
                        <Toggle onChange={changeTheme} defaultChecked={defaultChecked} />
                    </div>
                </>
            ),
            visible: profileOpen,
            onVisibleChange: setProfileOpen,
        }),
        [profile, profileOpen, defaultChecked, setTheme]
    );
    const navigationTablet = useMemo(
        () => (
            <Navigation isOpen={isOpen} onClose={closeNavigation}>
                <NavLink to="/dashboard">{t('MainMenu.DashboardMenuItem')}</NavLink>
                <NavLink to="/portfolios">{t('MainMenu.PortfoliosMenuItem')}</NavLink>
                <NavLink to="/documents">{t('MainMenu.Documents')}</NavLink>
                <NavLink to="/news">{t('MainMenu.NewsMenuItem')}</NavLink>
                <NavLink to="/faq">{t('MainMenu.Faq')}</NavLink>
            </Navigation>
        ),
        [isOpen, pathname, closeNavigation]
    );
    const leftButton = { type: isAuthenticated === true ? 'menu' : null, label: null };
    const rightButton = { type: isAuthenticated === true ? 'login' : null, label: null };

    return (
        <Fragment>
            <HeaderUILibrary
                link={({ children }) => (
                    <a href={NOTZ_STUCKI_MAIN_SITE} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                )}
                title="Notz Stucki"
                navigation={isAuthenticated && navigation}
                leftButton={leftButton}
                rightButton={rightButton}
                profileInfo={isAuthenticated && profileInfo}
                navigationTablet={navigationTablet}
                onLeftButtonClick={toggleNavigation}
                content={<Logo />}
            />
        </Fragment>
    );
};

Header.propTypes = {
    pathname: PropTypes.string.isRequired,
    onRightButtonClick: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        portfolios: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        riskProfile: PropTypes.string,
    }).isRequired,
};

Header.defaultProps = {};

Header.displayName = 'Header';
