import { roundAllocations } from 'utils';

export const getAllocations = (data) => {
    const roundedAllocations = data.length ? roundAllocations(data, 1000, 1000) : [];

    return roundedAllocations
        .map(({ id, value }) => ({
            Allocation: Number(value),
            InstrumentId: id,
            Notes: [],
            IsNotAdvised: false,
        }))
        .filter(({ Allocation }) => Allocation !== 0);
};
