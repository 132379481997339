import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { ContentBox, Table, Title } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';

import useDocuments from './hooks/useDocument';
import getColumns from './constants';

import './Documents.css';

// eslint-disable-next-line react/prop-types
function Documents({ contactId: clientId, t }) {
    const { data, isLoading, error } = useDocuments(clientId);

    return (
        <PageSection>
            <ContentBox className="documents">
                <Title type={2}>{t('dashboard.documents.title')}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t('dashboard.documents.noDocuments')}>
                        <Table
                            data={data}
                            columns={getColumns(t)}
                            headerControls={{
                                selectControlsLabel: {
                                    selectAll: t('headerControls.selectAll'),
                                    reset: t('headerControls.reset'),
                                },
                            }}
                        />
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Documents.propTypes = {
    match: PropTypes.shape({ params: { id: PropTypes.string.isRequired } }).isRequired,
};

Documents.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Documents);
