import React from 'react';
import { ContentBox } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PlayStoreQrCode from '../images/play_store_qr.png';
import AppStoreQrCode from '../images/app_store_qr.png';
import { NsConnect, NsTopDomain } from '../const';
// eslint-disable-next-line react/prop-types
import '../Activation.css';

function ActivationPageDE() {
    return (
        <PageSection>
            <ContentBox>
                <p>
                    NS Partners bietet Ihnen einen direkten Online-Zugang zu Ihrem Portfolio/Ihren
                    Portfolios über eine entsprechende Website und App: NS Connect.
                </p>
                <p>
                    Der erfolgreiche Abschluss der IT-Integration der Transaktionsströme mit unseren
                    wichtigsten Bankpartnern hat diesen Service ermöglicht. Auch die Daten der
                    Vermögenswerte werden regelmässig aktualisiert. Die Website und die App sind
                    nicht für direkte Transaktionen bestimmt. Wir möchten Sie auch daran erinnern,
                    dass für Ihre steuerlichen und aufsichtsrechtlichen Meldungen ausschliesslich
                    Bankbelege zu verwenden sind.
                </p>
                <p>
                    Nachstehend ist das Verfahren beschrieben, mit dem Sie diesen Service nutzen
                    können.
                </p>
                <p>
                    Um Ihnen eine erhöhte Sicherheit zu bieten, haben wir sowohl die Website als
                    auch die App mit einer Zwei-Faktor-Authentifizierung ausgestattet.
                </p>
                <p>
                    Sie haben gerade eine E-Mail von <u>no-reply@{NsTopDomain}</u> erhalten. Nachdem
                    Sie über den darin enthaltenen Link Ihr Passwort festgelegt haben, gehen Sie
                    bitte wie nachstehend beschrieben vor.
</p>

                <p>1. Zugang über die App</p>
                <p>
                    Laden Sie die NS Connect App aus dem App Store oder von Google Play auf Ihr
                    Smartphone oder Tablet. Sie können dazu auch den unten angezeigten QR-Code
                    scannen.
                </p>
                <div className="qr-container">
                    <figure>
                        <img className="qr-image" src={AppStoreQrCode} alt="App Store" />
                        <figcaption className="centered-qr">App Store</figcaption>
                    </figure>

                    <figure>
                        <img className="qr-image" src={PlayStoreQrCode} alt="Play Store" />
                        <figcaption className="centered-qr">Google Play</figcaption>
                    </figure>
                </div>
                <p>
                    Geben Sie Ihren Benutzernamen und Ihr Passwort ein. Ihr Smartphone oder Tablet
                    verwendet die Gesichtserkennung (&quot;Face ID&quot;), um Sie zu
                    authentifizieren.
                </p>

                <p>2. Zugang über die Website </p>
                <p>
                    Rufen Sie folgende Seite auf: &nbsp;
                    <a href={NsConnect} rel="noreferrer noopener">
                        nsconnect.{NsTopDomain}
                    </a>
                    &nbsp;(verwenden Sie nicht den Internet Explorer als Browser).
                </p>
                <p>Geben Sie Ihren Benutzernamen und Ihr Passwort ein.</p>
                <p>
                    Sie werden aufgefordert, die Futurae-App (Futurae Technologies AG) aus dem App
                    Store oder von Google Play auf Ihr Smartphone oder Tablet herunterzuladen. Mit
                    dieser App können Sie sich authentifizieren. Folgen Sie bitte einfach den in NS
                    Connect angezeigten Schritten, die nachstehend zusammengefasst werden.
                </p>
                <p>
                    Bei der ersten Nutzung werden Sie aufgefordert, Ihr Smartphone oder Tablet in
                    der Futurae-App zu registrieren. Geben Sie dazu auf Ihrem Computer erneut Ihren
                    Benutzernamen und Ihr Passwort ein, öffnen Sie anschliessend die Futurae-App auf
                    Ihrem Gerät und scannen Sie den von Ihrem Computer angezeigten QR-Code.
                </p>
                <p>Ihr Gerät ist nun registriert und Sie können sich einloggen.</p>
                <p>Geben Sie Ihren Benutzernamen und Ihr Passwort ein.</p>

                <p>
                    Geben Sie dann den 6-stelligen Code ein, den Sie beim Öffnen der Futurae-App auf
                    Ihrem Smartphone oder Tablet erhalten. Dieser Code wird bei jeder Verbindung
                    systematisch abgefragt, um Sie zu identifizieren.
                </p>
                <p>
                    Bitte wenden Sie sich bei Fragen an Ihren Kundenberater oder einen unserer
                    Mitarbeiter, insbesondere \ bei Ihrer ersten Anmeldung und Verwendung des
                    Produkts.
                </p>
            </ContentBox>
        </PageSection>
    );
}

ActivationPageDE.defaultProps = {};

export default ActivationPageDE;
