import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import RouteWithAppTitle from 'components/RouteWithAppTitle';
import PositionDetailsOverview from './pages/SinglePortfolioView/pages/PositionDetails/PositionDetailsOverview';
import SinglePortfolioView from './pages/SinglePortfolioView';
import Portfolios from './Portfolios';

const Router = (props) => {
    const {
        match: { path },
    } = props;

    return (
        <Switch>
            <RouteWithAppTitle exact path={`${path}`} component={Portfolios} title="Portfolios" />
            <RouteWithAppTitle
                exact
                path={`${path}/:portfolioId`}
                component={SinglePortfolioView}
                title="Portfolio"
            />
            <RouteWithAppTitle
                exact
                path={`${path}/:portfolioId/position/:productId/:positionId/:tabId/overview`}
                component={PositionDetailsOverview}
                title="Position"
            />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
