import Error from '../models/Error';

const AdapterError = (err) =>
    new Error({
        type: 'ADAPTER_ERROR',
        message: 'Unexpected error',
        ...err,
    });

export default AdapterError;
