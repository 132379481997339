import {
    ACTIVATION_KEY_INVALID,
    PASSWORD_SAME_ALREADY_SET,
    PASSWORD_SET_ACTIVE,
} from 'static/requestResults';
import commonErrorMessageToErrorModelXref from './commonErrors';
import { ErrorModel } from './ErrorModel';

export const RESET_PASSWORD_ERROR = 'ResetPasswordError';

const ACTIVATION_KEY_INVALID_MESSAGE = 'error.activationKeyInvalidMessage';
const THE_SAME_PASSWORD_WAS_USED_MESSAGE = 'error.samePasswordWasUsedMessage';
const PASSWORD_SET_ACTIVE_MESSAGE = 'error.passwordSerActiveMessage';

const errorMessageToErrorModelXref = {
    ...commonErrorMessageToErrorModelXref,
    [ACTIVATION_KEY_INVALID]: new ErrorModel({
        id: 21,
        type: RESET_PASSWORD_ERROR,
        message: ACTIVATION_KEY_INVALID_MESSAGE,
    }),
    [PASSWORD_SAME_ALREADY_SET]: new ErrorModel({
        id: 22,
        type: RESET_PASSWORD_ERROR,
        message: THE_SAME_PASSWORD_WAS_USED_MESSAGE,
    }),
    [PASSWORD_SET_ACTIVE]: new ErrorModel({
        id: 23,
        type: RESET_PASSWORD_ERROR,
        message: PASSWORD_SET_ACTIVE_MESSAGE,
    }),
};

export const getErrorModelByStatus = (status) => errorMessageToErrorModelXref[status];
