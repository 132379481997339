import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PortfoliosTable from 'components/organisms/Portfolios';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { usePortfolios } from './hooks/usePortfolios';

function Portfolios(props) {
    // eslint-disable-next-line react/prop-types
    const { contactId: clientId } = props;
    const portfolios = usePortfolios(clientId);

    return <PortfoliosTable {...portfolios} />;
}

Portfolios.propTypes = {
    match: PropTypes.shape({ params: { id: PropTypes.string.isRequired } }).isRequired,
};

Portfolios.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Portfolios);
