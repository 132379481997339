import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionPanel as Panel,
    ContentBox,
    Title,
    Row,
    Column,
    BreadCrumbs,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { contactIdSelector, roleIdSelector } from 'redux/auth/authSelectors'; [NS-720] temporary disabled
import { contactIdSelector } from 'redux/auth/authSelectors';
import { Preloader } from 'components/molecules/Preloader';
import { YTD } from 'filters/investementPerformanceFilters';
// import ReportGenerationButton from './components/ReportGenerationButton'; [NS-720] temporary disabled
import Allocation from './components/Allocation';
import Overview from './components/Overview';
import Positions from './components/Positions';
import Analysis from '../common/components/Analysis';
import { usePortfolioDetails } from './hooks/usePortfolioDetails';
import { useAnalysisDetails } from '../common/hooks/useAnalysisDetails';

function SinglePortfolioView(props) {
    const {
        match: {
            params: { portfolioId },
        },
    } = props;
    // const { contactId: clientId, roleId } = props; [NS-720] temporary disabled
    const { contactId: clientId } = props;
    const [performanceDates, setPerformanceDates] = useState({});
    const { data, isLoading, error } = usePortfolioDetails(clientId, portfolioId);
    const { analysisData, onPerformanceChange, performanceFilter } = useAnalysisDetails(
        data,
        isLoading,
        YTD,
        performanceDates
    );

    const { t } = useTranslation();

    return (
        <Row className="single-portfolio">
            <Column size="12">
                <Preloader isLoading={isLoading} error={error}>
                    <ContentBox underline={false}>
                        <Row>
                            <Column size={['sm-6']}>
                                <BreadCrumbs current={data.backTitle}>
                                    <Link to="/portfolios">{t('portfolios.title')}</Link>
                                </BreadCrumbs>
                                <Title className="mb-0" type={1}>
                                    {data.title}
                                </Title>
                                <p className="mb-0">
                                    {t('portfolios.updated')}
                                    &nbsp;
                                    {data.updateDate}
                                </p>
                                {/* <ReportGenerationButton roleId={roleId} clientId={clientId} /> [NS-720] temporary disabled */}
                            </Column>
                        </Row>
                        <div className="content-wrapper">
                            <Accordion defaultActiveKey={['1', '2', '3', '4', '5']}>
                                <Panel header={t('portfolios.overview')} key="1">
                                    <Overview portfolioId={portfolioId} data={data.overview} />
                                </Panel>
                                <Panel
                                    header={t('portfolios.allocation')}
                                    key="2"
                                    className="allocation"
                                >
                                    <Allocation
                                        data={data.investmentAllocation}
                                        isLoading={isLoading}
                                        error={error}
                                    />
                                </Panel>
                                <Panel
                                    header={t('portfolios.analysis')}
                                    key="3"
                                    className="performance"
                                >
                                    <Analysis
                                        data={analysisData}
                                        onFilterChange={onPerformanceChange}
                                        performanceFilter={performanceFilter}
                                        setPerformanceDates={setPerformanceDates}
                                    />
                                </Panel>
                                <Panel
                                    header={t('portfolios.positions')}
                                    className="positions"
                                    key="5"
                                >
                                    <Positions
                                        data={data.positions}
                                        isLoading={isLoading}
                                        error={error}
                                        secutiryId={3}
                                    />
                                </Panel>
                            </Accordion>
                        </div>
                    </ContentBox>
                </Preloader>
            </Column>
        </Row>
    );
}

SinglePortfolioView.propTypes = {
    match: PropTypes.shape({
        params: {
            portfolioId: PropTypes.string.isRequired,
        },
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    // roleId: PropTypes.number, [NS-720] temporary disabled
};

SinglePortfolioView.defaultProps = {
    // roleId: null, [NS-720] temporary disabled
};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
    // roleId: roleIdSelector(state), [NS-720] temporary disabled
});

export default connect(mapStateToProps)(SinglePortfolioView);
