import { usePerformanceAndRiskCompare } from './usePerformanceAndRiskCompare';

export const useAnalysisDetails = (portfolio, isLoading, defaultFilter, performanceDates) => {
    const {
        performanceAndRisk: analysisData,
        performanceFilter,
        onPerformanceChange,
    } = usePerformanceAndRiskCompare(portfolio, isLoading, defaultFilter, performanceDates);

    return {
        analysisData,
        performanceFilter,
        onPerformanceChange,
    };
};
