import React from 'react';
import { ContentBox } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import PlayStoreQrCode from '../images/play_store_qr.png';
import AppStoreQrCode from '../images/app_store_qr.png';
import { NsConnect, NsTopDomain } from '../const';
// eslint-disable-next-line react/prop-types
import '../Activation.css';

function ActivationPageFR() {
    return (
        <PageSection>
            <ContentBox>
                <p>
                    NS Partners met à votre disposition un accès pour la consultation de l’ensemble
                    de vos comptes confiés en gestion directement sur un site internet dédié et sur
                    une application mobile: NS Connect.
                </p>
                <p>
                    L’aboutissement du travail d’intégration informatique des flux transactionnels
                    avec nos principaux partenaires bancaires a rendu possible un tel service. Les
                    prix des actifs investis sont également régulièrement mis à jour. Le site et
                    l’application n’ont pas pour but d’effectuer directement des transactions. De
                    plus, nous vous rappelons que seuls les documents des banques font foi pour tous
                    vos besoins de rapports fiscaux et réglementaires.
                </p>
                <p>
                    Nous avons le plaisir de vous indiquer ci-après la marche à suivre pour
                    bénéficier de ce service. Pour une sécurité renforcée, nous avons choisi, que ce
                    soit pour le site ou l’application, une méthode d’authentification à deux
                    facteurs.
                </p>
                <p>
                    Après avoir initialisé votre mot de passe grâce au lien fournit à cet effet via le
                    mail que vous venez de recevoir de <u>no-reply@{NsTopDomain}</u>, veuillez procéder comme
                    suit:
                </p>

                <p>1. Pour l’accès par l’application</p>
                <p>
                    Téléchargez l’application NS Connect depuis l’AppStore ou GooglePlay sur votre
                    smartphone ou tablette. Vous pouvez également scanner le code QR ci-dessous.
                </p>
                <div className="qr-container">
                    <figure>
                        <img className="qr-image" src={AppStoreQrCode} alt="App Store" />
                        <figcaption className="centered-qr">AppStore</figcaption>
                    </figure>
                    <figure>
                        <img className="qr-image" src={PlayStoreQrCode} alt="Play Store" />
                        <figcaption className="centered-qr">GooglePlay</figcaption>
                    </figure>
                </div>
                <p>
                    Saisissez votre nom d’utilisateur et mot de passe. Votre smartphone ou tablette
                    utilisera l’option Reconnaissance Facial (&quot;Face ID&quot;) pour vous
                    authentifier.
                </p>

                <p>2. Pour l’accès par le site </p>
                <p>
                    Rendez-vous sur la page &nbsp;
                    <a href={NsConnect} rel="noreferrer noopener">
                        nsconnect.{NsTopDomain}
                    </a>
                    &nbsp;(ne pas utiliser Internet Explorer comme browser).
                </p>
                <p>
                    Vous serez invité à télécharger l’application Futurae (Futurae Technologies AG)
                    depuis l’AppSstore ou GooglePlay sur votre smartphone ou tablette. Celle-ci vous
                    permettra de vous authentifier.
                </p>
                <p>
                    Saisissez votre nom d’utilisateur et choisissez votre mot de passe. Vous serez
                    invité à télécharger l’application Futurae (Futurae Technologies AG) sur votre
                    smartphone ou tablette. Celle-ci permettra de vous identifier lors de vos
                    connexions. Il vous suffit de suivre les étapes indiquées à l’écran, et résumées
                    ci-après.
                </p>
                <p>
                    Pour la première utilisation uniquement, vous devez enregistrer votre smartphone
                    ou tablette. Pour ce faire, saisissez votre nom d’utilisateur et mot de passe
                    sur votre ordinateur puis ouvrez l’application Futurae sur votre appareil et
                    scannez le QR code qui apparait sur votre ordinateur.
                </p>
                <p>
                    Votre appareil est désormais reconnu, vous êtes maintenant prêt pour vous
                    connecter.
                </p>
                <p>Saisissez votre nom d’utilisateur et votre mot de passe. </p>
                <p>
                    Puis entrez le code à 6 chiffres qui vous sera donné en ouvrant l’application
                    Futurae sur votre smartphone ou tablette. Ceci vous sera systématiquement
                    demandé à chaque connexion, afin de vous identifier.
                </p>

                <p>
                    N’hésitez pas à faire appel à nos collaborateurs et à votre chargé de relation,
                    notamment lors de la première connexion et des premières utilisations du
                    produit.
                </p>
            </ContentBox>
        </PageSection>
    );
}

ActivationPageFR.defaultProps = {};

export default ActivationPageFR;
