import { dateYearFormat, subtractUnits } from 'utils/datetime';

export const filterNamespace = 'dashboard.assetDevelopment.filters';

export const YTD = 'YTD';
export const YEARS1 = 'YEARS1';
export const YEARS3 = 'YEARS3';
export const YEARS5 = 'YEARS5';
export const ONE_YEAR_BEFORE = 'OneYearBefore';
export const TWO_YEARS_BEFORE = 'TwoYearsBefore';
export const THREE_YEARS_BEFORE = 'ThreeYearsBefore';
export const SINCE_INCEPTION = 'SinceInception';
export const MONTH_TO_MONTH = 'MonthToMonth';

export const performanceSelectOptions = [
    { value: YTD },
    { value: YEARS1 },
    { value: YEARS3 },
    { value: YEARS5 },
    { value: SINCE_INCEPTION },
];

export const additionalPerformanceSelectOptions = (t) => [
    {
        value: ONE_YEAR_BEFORE,
        label: subtractUnits(new Date(), 1, 'year', dateYearFormat),
    },
    {
        value: TWO_YEARS_BEFORE,
        label: subtractUnits(new Date(), 2, 'year', dateYearFormat),
    },
    {
        value: THREE_YEARS_BEFORE,
        label: subtractUnits(new Date(), 3, 'year', dateYearFormat),
    },
    {
        value: MONTH_TO_MONTH,
        label: t(`${filterNamespace}.MonthToMonth`),
    },
];

export const getPerformanceSelectOptions = (t) => [
    ...performanceSelectOptions.map((item) => ({
        ...item,
        label: t(`${filterNamespace}.${item.value}`),
    })),
    ...additionalPerformanceSelectOptions(t),
];
