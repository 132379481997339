import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EXTERNAL_ORIGIN } from 'pages/pages/News/constants/constants';

export const LinkNews = (props) => {
    const { link, id, children, origin } = props;

    if (link) {
        return (
            <a className="LinkNews" href={link} rel="noopener noreferrer" target="_blank">
                {children}
            </a>
        );
    }

    return (
        <Link
            className="LinkNews"
            to={{
                pathname: origin === EXTERNAL_ORIGIN ? `/news/ext/${id}` : `/news/${id}`,
                query: { ...props },
            }}
        >
            {children}
        </Link>
    );
};

LinkNews.propTypes = {
    id: PropTypes.number,
    link: PropTypes.string,
    origin: PropTypes.string,
    children: PropTypes.node,
};

LinkNews.defaultProps = {
    id: null,
    link: null,
    children: null,
    origin: '',
};
