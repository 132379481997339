/* eslint-disable no-plusplus */
export function roundLargestRemainder(arr, target = 10000) {
    const off = target - arr.reduce((acc, x) => acc + Math.floor(x), 0);
    const offObject = arr.reduce((acc, n, i) => ({ ...acc, [i]: n - Math.floor(n) }), {});
    const offKeys = Object.keys(offObject)
        .sort((a, b) => offObject[b] - offObject[a])
        .map((index) => +index);

    return arr.map((n, i) => Math.floor(n) + (off > offKeys.indexOf(i) && offKeys.includes(i)));
}

export function roundSmallestError(arr, target = 10000) {
    const errorFactor = (actual, rounded) => Math.abs(rounded - actual) / actual;

    const { length } = arr;
    const rounded = arr.map((n) => Math.floor(n));
    const off = Math.abs(target - rounded.reduce((acc, x) => acc + x, 0));
    const errors = [...Array(length).keys()].map((i) => [errorFactor(arr[i], rounded[i] + 1), i]);
    const rank = [...errors].sort((a, b) => a[0] - b[0]);

    [...Array(off).keys()].forEach((i) => {
        if (rank[i]) rounded[rank[i][1]] += 1;
    });

    return rounded;
}

/*
export function roundSmallestError(arr, target = 10000) {
    const errorFactor = (oldNum, newNum) => Math.abs(oldNum - newNum) / oldNum;
    const { length } = arr;
    const values = arr.map((n) => Math.round(n));
    let total = arr.reduce((a, n) => a + Math.round(n), 0);
    const change = total < target ? 1 : -1;

    while (total !== target) {
        let next;
        let j = 0;

        values.forEach((item, i) => {
            next = i === length - 1 ? 0 : i + 1;

            const factor1 = errorFactor(arr[i], item + change);
            const factor2 = errorFactor(arr[next], values[next] + change);

            if (factor1 > factor2) {
                j = next;
            }
        });

        values[j] += change;
        total += change;
    }

    const marginOfErrors = values.map((item, i) => item && errorFactor(arr[i], item));

    for (let i = 0; i < length; i++) {
        for (let j = 0; j < length; j++) {
            if (j !== i) {
                const roundUpFactor = errorFactor(arr[i], values[i] + 1)
                    + errorFactor(arr[j], values[j] - 1);
                const roundDownFactor = errorFactor(arr[i], values[i] - 1)
                    + errorFactor(arr[j], values[j] + 1);
                const sumMargin = marginOfErrors[i] + marginOfErrors[j];

                if (roundUpFactor < sumMargin) {
                    values[i] += 1;
                    values[j] -= 1;
                    marginOfErrors[i] = values[i] && errorFactor(arr[i], values[i]);
                    marginOfErrors[j] = values[j] && errorFactor(arr[j], values[j]);
                }

                if (roundDownFactor < sumMargin) {
                    values[i] -= 1;
                    values[j] += 1;
                    marginOfErrors[i] = values[i] && errorFactor(arr[i], values[i]);
                    marginOfErrors[j] = values[j] && errorFactor(arr[j], values[j]);
                }
            }
        }
    }

    return values;
}
*/
