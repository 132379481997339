import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';

export const usePortfolioDetails = (adaptPortfolioDetails, clientId, portfolioId, positionId) => {
    const [errorPortfolioDetails, setError] = useState(null);
    const [isLoadingPortfolioDetails, setLoading] = useState(true);
    const [dataPortfolioDetails, setData] = useState({ title: '', positions: [], overview: {} });
    const { i18n } = useTranslation();
    const { getFormattedDate, getFormattedNumber } = useFormatting();

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getPortfolioDetails', [portfolioId, clientId, params])
            .then((response) => {
                try {
                    setData(
                        adaptPortfolioDetails(
                            response.data,
                            positionId,
                            getFormattedDate,
                            getFormattedNumber
                        )
                    );
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [clientId, portfolioId, positionId, i18n.language]);

    return { dataPortfolioDetails, isLoadingPortfolioDetails, errorPortfolioDetails };
};
