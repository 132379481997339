/* eslint no-underscore-dangle:off */
import { compose, createStore, applyMiddleware } from 'redux';
import { save, load } from 'redux-localstorage-simple';
import { save as saveSession, load as loadedSession } from 'middleware/redux-sessionstorage';
import { get } from 'lodash/fp';
import { setup } from 'additiv-services';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
import history from './services/core/history';
import { $_GET } from './utils/url';

global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = undefined;
const composeEnhancer = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loadStateAndSetupServices = () => {
    const loaded = load({ states: ['auth', 'theme'] });
    const loadedSessionData = loadedSession({ states: ['session'] });
    const jwt = get('auth.user.jwt', loaded);
    const withURLLogin = $_GET('contactId') && $_GET('accessToken') === undefined;
    const {
        withJWT,
        SERVICES_BASIC_AUTH,
        DMS_BASIC_AUTH,
        GATEWAY_API_URL,
        DMS_URL_SUFFIX,
        SERVICES_URL_SUFFIX,
    } = global.reactAdditiv.settings.services;

    setup({
        jwt: withURLLogin ? undefined : jwt,
        withJWT,
        SERVICES_BASIC_AUTH,
        DMS_BASIC_AUTH,
        GATEWAY_API_URL,
        DMS_URL_SUFFIX,
        SERVICES_URL_SUFFIX,
    });

    if (withURLLogin) {
        return {};
    }

    return {
        ...loaded,
        ...loadedSessionData,
    };
};

export default ((preloadedState) =>
    createStore(
        rootReducer,
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                thunk,
                save({ states: ['auth', 'theme'] }),
                saveSession({ states: ['session'] })
            )
        )
    ))(loadStateAndSetupServices());
