import { CellPDFView } from 'components/renderers';
import { sorterByDate, sorterString } from 'utils/sorting';

export default (t) => [
    {
        key: 'nameCol',
        title: t('clientDashboard.documents.name'),
        width: 200,
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('nameCol.value'),
    },
    {
        key: 'createDate',
        title: t('clientDashboard.documents.createDate'),
        width: 160,
        minWidth: 160,
        sorter: sorterByDate('createDate'),
        sortable: true,
        className: 'table-cell__date',
    },
    {
        key: 'icon',
        title: '',
        titleType: 'columns',
        width: 20,
        className: 'text-right',
    },
];
