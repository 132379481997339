// import { validateStringData, validateName } from 'utils/formatting';
// import { validateStringData } from 'utils/formatting';

// import avatar from 'assets/images/avatar/AdvisorMale.jpg';

// export const adaptProfile = (data, imageData) => ({
export const adaptProfile = (dataProfile, clientName) => ({
    name: clientName,
    // name: `${validateName(data.FirstName)} ${validateName(data.LastName)}`,
    // image: imageData.ImageContentBase64
    //     ? `data:image/jpeg;base64,${imageData.ImageContentBase64}`
    //     : avatar,
    // riskProfile: validateStringData(data.ChosenRiskCategory),
    portfolios: dataProfile.Investments.reduce((acc, { Count }) => acc + Count, 0),
});
