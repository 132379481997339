import { find } from 'lodash/fp';
import { getfromDataObject, validateData, validateNumber, NA } from 'utils/formatting';

export const adaptPortfolioDetails = (data, positionId, getFormatedDate, getFormattedNumber) => {
    const positions = data.Positions || [];
    const item = positions.length ? find(['Id', Number(positionId)], positions) : '';
    const customAttr = item.CustomAttributes ? JSON.parse(item.CustomAttributes) : {};

    return {
        securityId: item ? item.Security.Id : null,
        portfolioName: getfromDataObject(data, 'Name'),
        productId: getfromDataObject(data.Product, 'Id'),
        quantity: item ? item.Quantity || 0 : 0,
        units: item
            ? getFormattedNumber(validateNumber(item.Quantity), {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
              })
            : 0,
        currency: validateData(
            item ? item.Security.Currency.CurrencyCode : data.Currency.CurrencyCode
        ),
        currencyId: validateData(item ? item.Security.Currency.Id : data.Currency.Id),
        positionCurrency: validateData(data.Currency.CurrencyCode),
        tradingPrice: item ? validateNumber(item.BuyPrice) : 0,
        priceDate: item.customAttr?.LastPriceDate
            ? getFormatedDate(item.customAttr.LastPriceDate)
            : NA,
        FxRate: item ? validateNumber(item.FxRate) : 0,
        factor: item && item?.Security.Factor ? validateNumber(item?.Security.Factor) : 1,
        portfolioAllocation: item ? validateNumber(item.Allocation) * 100 : 0,
        costPrice: item ? validateNumber(item.BuyPrice) : 0,
        performanceSP: validateNumber(customAttr.performanceSI),
        performanceYTD: validateNumber(customAttr.performanceYTD),
    };
};
