import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLocale } from 'ui-library';
import { importLocale } from 'utils/datetime';
import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';
import { languagesAdapt } from '../adaptors/languagesAdapt';

export const useLanguageSwitcher = () => {
    const { i18n, t } = useTranslation();
    // NS-692 fix for browser firefox language from navigator.language
    const languageWithoutLocale = useMemo(() => i18n.language.split('-')[0], [i18n.language]);
    const languages = useMemo(() => languagesAdapt(global.availableLanguages, t), [
        global.availableLanguages,
        i18n.language,
    ]);

    const changeLanguage = useCallback(
        (lang) => {
            sendGAEvent(GA_ACTIONS.changeLanguage, GA_CATEGORIES.language, lang);
            i18n.changeLanguage(lang);
            importLocale(lang);
            changeLocale(lang);
        },
        [i18n.language]
    );

    useEffect(() => {
        // todo: move next operation inside of LanguageDetector on i18n initialization
        i18n.changeLanguage(languageWithoutLocale);
        importLocale(i18n.language);
        changeLocale(i18n.language);
        sendGAEvent(GA_ACTIONS.startLanguage, GA_CATEGORIES.language, i18n.language);
    }, []);

    useEffect(() => {
        try {
            if (
                !global.availableLanguages.map(({ value }) => value).includes(languageWithoutLocale)
            ) {
                if (i18n.options.fallbackLng && i18n.options.fallbackLng.length) {
                    changeLanguage(i18n.options.fallbackLng[0]);
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }, [i18n.language]);

    return { languages, changeLanguage, language: languageWithoutLocale, t };
};
