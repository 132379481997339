import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ContentBox, Title, BreadCrumbs } from 'ui-library';
import { useTranslation } from 'react-i18next';
import PageSection from 'components/PageSection';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import useDocument from './hooks/useDocument';

function DocumentView(props) {
    const { t } = useTranslation();
    const {
        match: {
            params: { documentId, ownerId },
        },
    } = props;
    const { data, isLoading, error } = useDocument(documentId, ownerId);

    return (
        <PageSection className="document-view" id="pdf-viewer">
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={data}>
                    <ContentBox>
                        <div>
                            <BreadCrumbs current={data.name}>
                                <Link to="/dashboard">{t('advisoryDashboard.title')}</Link>
                                <Link to="/documents">
                                    {t('advisoryDashboard.documents.title')}
                                </Link>
                            </BreadCrumbs>
                            <Title className="mb-0" type={1}>
                                {data.name}
                            </Title>
                        </div>
                    </ContentBox>
                    <div id="pdf-viewer">
                        <ContentBox className="pdf-viewer" />
                    </div>
                </EmptyContent>
            </Preloader>
        </PageSection>
    );
}

DocumentView.propTypes = {
    location: PropTypes.shape({
        query: {
            id: PropTypes.number,
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
        },
    }).isRequired,
    match: PropTypes.shape({
        params: { newsId: PropTypes.string.isRequired },
    }).isRequired,
};

DocumentView.defaultProps = {};

export default DocumentView;
