/* eslint react/button-has-type:off */
import React from 'react';
import PropTypes from 'prop-types';

export const Button = ({ type, children, className, bsStyle, ...props }) => (
    <button {...props} type={type} className={`btn ${bsStyle} ${className}`}>
        {children}
    </button>
);

Button.propTypes = {
    children: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    bsStyle: PropTypes.string,
};

Button.defaultProps = {
    children: '',
    type: 'button',
    className: '',
    bsStyle: 'btn-default',
};

Button.displayName = 'Button';
