import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TermsOfUse from '../../../components/TermsOfUse/TermsOfUse';
import { userSelector } from '../../../redux/auth/authSelectors';
import { setTermsAccepted } from '../../../redux/auth/authActions';

const WithTermsOfUsePopUp = ({ children, user, ...props }) => (
    <>
        {!user?.isTermsAccepted && <TermsOfUse {...props} />}
        <div className="overflow">{children}</div>
    </>
);

const mapStateToProps = (state) => ({
    user: userSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    setTerms: () => dispatch(setTermsAccepted(true)),
});

WithTermsOfUsePopUp.propTypes = {
    user: PropTypes.shape({
        isTermsAccepted: PropTypes.bool,
    }).isRequired,
    children: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithTermsOfUsePopUp);
