import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import { Icon } from 'ui-library';

const CellWithPlusMinusIcons = ({ onDecrease, onIncrease }) => (
    <div className="table-cell_icon">
        {onDecrease && <Icon type="minus-button" size="18" onClick={onDecrease} />}
        {onIncrease && <Icon type="plus-button" size="18" onClick={onIncrease} />}
    </div>
);

CellWithPlusMinusIcons.propTypes = {
    onDecrease: PropTypes.func,
    onIncrease: PropTypes.func,
};

CellWithPlusMinusIcons.defaultProps = {
    onIncrease: noop,
    onDecrease: noop,
};

export default CellWithPlusMinusIcons;
