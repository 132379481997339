import React from 'react';
import { Link } from 'react-router-dom';
import { ContentBox, Title, Row, Column, BreadCrumbs, Paragraph } from 'ui-library';
import PropTypes from 'prop-types';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import { useFormatting, dateTimeFormats } from 'locale';
import { useNewsPost } from '../../hooks/useNewsPost';

function SingleNewsView(props) {
    const {
        location: { query },
        match: {
            params: { newsId },
        },
        t,
    } = props;
    const { data, isLoading, error } = useNewsPost(query);
    const newsItem = data.find((item) => item.id === Number(newsId)) || { title: '' };

    const { getFormattedDate } = useFormatting();
    const formattedDate = getFormattedDate(newsItem.date, {
        hour: dateTimeFormats.NUMERIC,
        minute: dateTimeFormats.NUMERIC,
    });

    return (
        <Row className="single-news-item">
            <Preloader isLoading={isLoading} error={error}>
                <Column size="12">
                    <EmptyContent data={newsItem} text={t('news.noNews')}>
                        <ContentBox>
                            <div>
                                <BreadCrumbs current={newsItem.title}>
                                    <Link to="/dashboard">{t('dashboard.title')}</Link>
                                    <Link to="/news">{t('news.title')}</Link>
                                </BreadCrumbs>
                                <Title className="mb-0" type={1}>
                                    {newsItem.title}
                                </Title>
                            </div>
                            <hr />
                            <Row className="mb-0">
                                <Column size={['md-4', 'sm-12']}>
                                    <img
                                        className="single-news-item--image"
                                        src={newsItem.image}
                                        alt="news-img"
                                    />
                                </Column>
                                <Column size={['md-8', 'sm-12']}>
                                    <Paragraph type="secondary" className="single-news-item--date">
                                        {formattedDate}
                                    </Paragraph>
                                    <Paragraph className="single-news-item--content">
                                        {newsItem.content}
                                    </Paragraph>
                                </Column>
                            </Row>
                        </ContentBox>
                    </EmptyContent>
                </Column>
            </Preloader>
        </Row>
    );
}

SingleNewsView.propTypes = {
    location: PropTypes.shape({
        query: {
            id: PropTypes.number,
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
        },
    }).isRequired,
    match: PropTypes.shape({
        params: { newsId: PropTypes.string.isRequired },
    }).isRequired,
    t: PropTypes.func.isRequired,
};

SingleNewsView.defaultProps = {};

export default SingleNewsView;
