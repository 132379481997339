import { getfromDataObject, validateStringData, NA } from 'utils/formatting';

import { portfolioTypeIds } from 'constants/portfolioTypeIds';

const filterPortfolios = (portfolios) =>
    portfolios.filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN);

export const adaptPortfolios = (
    data,
    portfolioActions,
    t,
    getFormattedCurrency,
    getFormattedDate,
    getFormattedNumber
) => {
    if (!data.Portfolios) return [];

    const portfolios = filterPortfolios(data.Portfolios);

    return portfolios.map((item) => {
        const customAttr = JSON.parse(item?.CustomAttributes);

        return {
            Name: { label: validateStringData(item.Name), link: `/portfolios/${item.Id}` },
            Product: getfromDataObject(item, 'Product.Name'),
            Profile: validateStringData(item.RiskCategory.Name),
            Value: {
                title: getFormattedCurrency(item.CurrentValue, {
                    currency: item.Currency.CurrencyCode,
                }),
                subtitle: t('portfolios.columns.asOf', {
                    date: customAttr.UpdateDate ? getFormattedDate(customAttr.UpdateDate) : NA,
                }),
            },
            Performance: customAttr.performanceSI
                ? Math.round(customAttr.performanceSI * 10000) / 100
                : undefined,
            PerformanceYTD: customAttr.performanceYTD
                ? Math.round(customAttr.performanceYTD * 10000) / 100
                : undefined,
            gainYTD: getFormattedNumber(customAttr.GainLossYTD, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            inceptionDate: item.CreateDate ? getFormattedDate(item.CreateDate) : undefined,
            currency: item.Currency ? item.Currency.Name : undefined,

            Actions: {
                value: 'action',
                actions: portfolioActions(item.Id, item.Product.Name, t),
            },
        };
    });
};
