import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getThemeSelector } from '../redux/theme/themeSelectors';
import { setThemeVariables } from '../utils/configureTheme';

const withTheme = (Component) => (props) => {
    const theme = useSelector(getThemeSelector);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        setThemeVariables(theme);
    }, []);

    return <Component {...props} />;
};

export default withTheme;
