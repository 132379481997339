import React, { Component } from 'react';
import history from 'services/core/history';
import PropTypes from 'prop-types';
import { Title, Button } from 'ui-library';
import { Loading } from 'components/Loading';
import ServiceManager from 'services/core/ServiceManager';

import { userSelect } from './selectors';

import './UserActivation.css';

export class UserActivation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 0,
            activationKey: undefined,
            user: undefined,
        };
    }

    componentDidMount() {
        const {
            match: {
                params: { activationKey },
            },
        } = this.props;

        ServiceManager.security('activateUser', [activationKey])
            .then((data) => {
                const user = userSelect(data);

                this.setState({
                    view: 1,
                    activationKey,
                    user,
                });
            })
            .catch(() => {
                this.setState({
                    view: 2,
                });
            });
    }

    render() {
        const { view, user, activationKey } = this.state;
        const { t } = this.props;

        switch (view) {
            case 0:
                return <Loading />;
            case 1:
                return (
                    <div className="UserActivation content-box">
                        <Title type={2}>{t('activation.activationSuccess')}</Title>
                        <p className="m-b-md">
                            {user.username
                                ? t('activation.usernameAccountCreated', {
                                      username: user.username,
                                  })
                                : t('activation.youtAccountCreated')}
                        </p>
                        {user.isPasswordSet ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push('/login');
                                }}
                            >
                                {t('activation.Login')}
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push(
                                        `/reset-password/${user.username}/${activationKey}`
                                    );
                                }}
                            >
                                {t('activation.setPassword')}
                            </Button>
                        )}
                    </div>
                );
            case 2:
                return (
                    <div className="UserActivation content-box">
                        <Title type={2}>{t('activation.activationError')}</Title>
                        <p>{`${t('activation.activationFailed')} `}</p>
                    </div>
                );
            default:
                return null;
        }
    }
}

UserActivation.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        isExact: PropTypes.bool,
        params: PropTypes.shape({
            activationKey: PropTypes.string,
        }),
    }).isRequired,
    t: PropTypes.func.isRequired,
};

UserActivation.defaultProps = {};

UserActivation.displayName = 'UserActivation';
