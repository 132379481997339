import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptProfile } from '../adapters/adaptProfile';

export const useWithClientProfile = (contactId, isAuthenticated) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ name: '', image: '' });
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true);

            Promise.all([
                ServiceManager.contactManagement('getClientProfile', [
                    contactId,
                    `${i18n.language}`,
                ]),
                ServiceManager.products('getAccounts', [contactId, `${i18n.language}`]),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    try {
                        const clientName =
                            JSON.parse(response[1][0].Attributes)?.ClientName || 'N/A';

                        setData(adaptProfile(response[0], clientName, t));
                        // setUnreadMessages(response[1].Total !== 0);
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        }
    }, [isAuthenticated, i18n.language]);

    return {
        data,
        error,
        isLoading,
    };
};
