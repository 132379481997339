import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/core/history';

const withLanguageChange = (Component) => (props) => {
    const { t, i18n } = useTranslation();

    const languageChanged = () => {
        history.push('/news');
    };

    useEffect(() => {
        i18n.on('languageChanged', languageChanged);

        return () => i18n.off('languageChanged', languageChanged);
    }, []);

    return Component({ ...props, t });
};

export default withLanguageChange;
