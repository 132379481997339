import React, { useState } from 'react';
import { TextInput } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PasswordEyeButton from 'components/PasswordEyeButton';

// this Component is a duplicate of FieldRenderer,
// but enhanced by PasswordEyeButton icon
export const PasswordFieldRenderer = ({
    input,
    label,
    type,
    id,
    meta: { touched, error, submitting },
    ...props
}) => {
    const { t } = useTranslation();
    const [isPassVisible, setPassVisible] = useState(false);
    let inputType = type;

    if (!inputType) {
        inputType = isPassVisible ? 'text' : 'password';
    }

    return (
        <TextInput
            {...props}
            {...input}
            prefix={
                <PasswordEyeButton isPassVisible={isPassVisible} setPassVisible={setPassVisible} />
            }
            label={label}
            placeholder={label}
            error={touched === true ? t(error) : undefined}
            id={id}
            type={inputType}
            disabled={submitting}
        />
    );
};

PasswordFieldRenderer.propTypes = {
    autoComplete: PropTypes.string,
    input: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        warning: PropTypes.string,
        submitting: PropTypes.bool,
    }),
};

PasswordFieldRenderer.defaultProps = {
    autoComplete: 'on',
    name: null,
    meta: PropTypes.shape({}),
};

PasswordFieldRenderer.displayName = 'PasswordFieldRenderer';
