import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from 'services/core/history';
import withTheme from 'hocs/withTheme';
import { Loading } from 'components/Loading';
import { RootRouter } from 'pages';
import LoginRouter from 'pages/pages/Login/Router';
import { ForgotPassword } from 'pages/pages/ForgotPassword';
import MobileAppDownloadFactsheet from 'pages/pages/DocumentsPreview/MobileAppFactsheetDownload';
import MobileAppDownloadDocument from 'pages/pages/DocumentsPreview/MobileAppDocumentDownload';
import RouteWithAppTitle from 'components/RouteWithAppTitle';
import 'styles/core/wrapper.css';
import 'styles/custom/wrapper.css';

const App = () => (
    <Suspense fallback={<Loading />}>
        <Router history={history}>
            <Switch>
                <RouteWithAppTitle
                    exact
                    path="/factsheet"
                    component={MobileAppDownloadFactsheet}
                    title="Factsheet preview"
                />
                <RouteWithAppTitle
                    exact
                    path="/document"
                    component={MobileAppDownloadDocument}
                    title="Document preview"
                />
                <RouteWithAppTitle
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                    title="Forgot password"
                />
                <RouteWithAppTitle path="/login" strict component={LoginRouter} title="Login" />
                <Route path="/" component={RootRouter} />
            </Switch>
        </Router>
    </Suspense>
);

export default withTheme(App);
