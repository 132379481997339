import React from 'react';
import { isObject } from 'lodash/fp';
import PropTypes from 'prop-types';
import { NumberInput } from 'ui-library';

const CellInputText = (numberSeparators) => (data) => {
    return isObject(data) ? (
        data.value !== '' && (
            <NumberInput
                addonAfter="%"
                {...numberSeparators}
                validation={{
                    minValue: 0,
                    maxValue: 100,
                }}
                value={data.value}
                onChange={data.onChange}
                disabled={data.disabled}
                className="table-cell_input"
            />
        )
    ) : (
        <b>{data}</b>
    );
};

CellInputText.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            disabled: PropTypes.bool,
            onChange: PropTypes.func,
        }),
    ]),
};

CellInputText.defaultProps = {
    data: null,
};

export default CellInputText;
