import FileSaver from 'file-saver';

export const file = (data) => ({
    stringFile: global.atob(data.File),
    fileType: data.CustomAttributes,
    fileName: data.RetrieveFileAsName || data.OriginalFilename,
});

export const createBlobFile = (data) => {
    const fileData = file(data);
    const byteString = fileData.stringFile;
    const buffer = new ArrayBuffer(byteString.length);
    const dataBuffer = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i += 1) {
        dataBuffer[i] = byteString.charCodeAt(i);
    }

    return {
        file: new Blob([buffer], {
            type: data.MimeType || fileData.fileType,
        }),
        fileName: fileData.fileName,
    };
};

export const onDownloadDocument = (BlobFile) => {
    FileSaver.saveAs(BlobFile.file, BlobFile.fileName);
};

export const openDocumentUrl = (BlobFile) => {
    const url = URL.createObjectURL(BlobFile.file);
    const anchorElement = document.createElement('a');

    anchorElement.setAttribute('href', url);
    anchorElement.setAttribute('download', BlobFile.fileName);
    anchorElement.setAttribute('target', '_blank');
    anchorElement.style.display = 'none';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);

    // window.open(url, 'preview', 'resizable,scrollbars,status=no');
};
