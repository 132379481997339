import React from 'react';
import { useTranslation } from 'react-i18next';
import itunesIcon from 'clientRequirements/images/itunes_icon.svg';
import gplayIcon from 'clientRequirements/images/gplay_icon.svg';

const EnrollementInfoBox = () => {
    const { t } = useTranslation();

    return (
        <div className="Info-box" role="article">
            <div className="validation-summary-errors">
                {t('enrollDevice.accountRequires2FA')}
                <div className="btn-apps-group">
                    <a
                        href="https://itunes.apple.com/us/app/futurae/id1069858990?mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-apps"
                    >
                        <img src={itunesIcon} alt="Temporary email app for Iphone and Ipad" />
                        {' '}
                        App Store
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.futurae.mobileapp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-apps"
                    >
                        <img src={gplayIcon} alt="Temporary email app for Android" />
                        {' '}
                        Google Play
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EnrollementInfoBox;
