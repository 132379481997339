import {
    THREE_YEARS_BEFORE,
    TWO_YEARS_BEFORE,
    ONE_YEAR_BEFORE,
    SINCE_INCEPTION,
    YEARS1,
    YEARS3,
    YEARS5,
    YTD,
} from 'filters/investementPerformanceFilters';
import { roundLargestRemainder } from './rounding';
import { formatDate, subtractUnits, getFirstDayOfYear, getYearDates } from './datetime';

export const checkNullData = (data) =>
    data === undefined || data === null || data === '' || data.length === 0;

export const getClassNames = (...args) =>
    args
        .map((arg) =>
            arg && typeof arg === 'object' ? Object.keys(arg).filter((item) => arg[item]) : arg
        )
        .reduce((acc, val) => acc.concat(val), [])
        .filter((item) => item)
        .join(' ');

export const getPeriodByFilter = (filter, format = 'YYYY-MM-DD') => {
    let filterPeriod = {
        startDate: '',
        endDate: formatDate(new Date(), format),
    };

    switch (filter) {
        case YTD:
            filterPeriod.startDate = `${getFirstDayOfYear()}`;
            break;
        case YEARS1:
            filterPeriod.startDate = subtractUnits(new Date(), 1, 'years');
            break;
        case YEARS3:
            filterPeriod.startDate = subtractUnits(new Date(), 3, 'years');
            break;
        case YEARS5:
            filterPeriod.startDate = subtractUnits(new Date(), 5, 'years');
            break;
        case SINCE_INCEPTION:
            delete filterPeriod.startDate;
            delete filterPeriod.endDate;
            break;
        case ONE_YEAR_BEFORE:
            filterPeriod = getYearDates(1);
            break;
        case TWO_YEARS_BEFORE:
            filterPeriod = getYearDates(2);
            break;
        case THREE_YEARS_BEFORE:
            filterPeriod = getYearDates(3);
            break;
        default:
            delete filterPeriod.startDate;
            delete filterPeriod.endDate;
    }

    return filterPeriod;
};

export const getParent = (data) => {
    let parent = data;

    while (parent.Parent) {
        parent = parent.Parent;
    }

    return parent;
};

const multiply = (a, b) => +(a * b).toFixed(10);

export const sum = (data, prop) => data.reduce((accu, item) => accu + Number(item[prop]), 0);

export const roundItems = (arrDef, multiplier = 10000, sumItems = multiplier) => {
    if (arrDef.length === 0) return arrDef;
    const arr = arrDef.map((item) => multiply(item, multiplier));

    return roundLargestRemainder(arr, sumItems);
};

export const roundAllocations = (
    positions,
    multiplier = 10000,
    divide = 100,
    sumItems = multiplier
) => {
    const allocations = positions.map(({ Allocation }) => Allocation);

    return roundItems(allocations, multiplier, sumItems).map((n, i) => ({
        id: positions[i].Security.Id || positions[i].Id,
        value: n / divide,
    }));
};

export const sortAssetsByOrderPropOrAllocation = (a, b) => {
    if (a.sortingOrder !== undefined) {
        return a.sortingOrder - b.sortingOrder;
    }

    return b.percent - a.percent;
};

export const stripHtmlTags = (string) => string.replace(/<[^>]*>?/gm, '');

export const isToday = (inputDate) => {
    const date = new Date(inputDate);
    const today = new Date();

    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const isYesterday = (inputDate) => {
    const date = new Date(inputDate);
    const today = new Date();

    return (
        date.getDate() === today.getDate() - 1 &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const isTomorrow = (inputDate) => {
    const date = new Date(inputDate);
    const today = new Date();

    return (
        date.getDate() === today.getDate() + 1 &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const isEmpty = (data) => {
    if (data === undefined || data == null || data.length === 0) {
        return true;
    }

    if (
        ['function', 'number', 'boolean'].includes(typeof data) ||
        Object.prototype.toString.call(data) === '[object Date]'
    ) {
        return false;
    }

    if (typeof data === 'object') {
        if (Object.keys(data) && Object.keys(data).length > 0) {
            return false;
        }

        return true;
    }

    return false;
};
