import React from 'react';
import PropTypes from 'prop-types';

function OverviewPanel({ children }) {
    return <div className="overview-panel">{children}</div>;
}

OverviewPanel.propTypes = {
    children: PropTypes.node,
};

OverviewPanel.defaultProps = {
    children: null,
};

export default OverviewPanel;
