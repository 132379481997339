import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { portfolioActions } from 'constants/constants';
import { useFormatting, useLocale } from 'locale';
import { adaptPortfolios } from '../adapters/adaptPortfolios';

export const usePortfolios = (clientId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { i18n, t } = useTranslation();
    const { getFormattedDate, getFormattedCurrency, getFormattedNumber } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params])
            .then((response) => {
                try {
                    setData(
                        adaptPortfolios(
                            response.data,
                            portfolioActions,
                            t,
                            getFormattedCurrency,
                            getFormattedDate,
                            getFormattedNumber
                        )
                    );
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [locale, i18n.language]);

    return { data, isLoading, error };
};
