import React from 'react';
import FooterMini from 'lib/ui-library/Footer/FooterMini';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'translations/component/LanguageSwitcher';
import footerLogo from 'clientRequirements/images/icon_NS.png';
import './Footer.css';
import { LINKEDIN_URL, NOTZ_STUCKI_MAIN_SITE } from 'constants/constants';
import linkedinIcon from 'styles/icons/linkedin.svg';

export const Footer = () => {
    const { t } = useTranslation();
    const footerLink = (
        <div>
            <a href={NOTZ_STUCKI_MAIN_SITE} rel="noreferrer noopener" target="_blank">
                <img src={footerLogo} className="Footer__logo" alt={t('footer.productName')} />
            </a>
            <a href={LINKEDIN_URL} target="_blank">
                <img src={linkedinIcon} className="Footer__icon" alt="" />
            </a>
        </div>
    );

    return (
        <FooterMini link={footerLink}>
            <LanguageSwitcher />
        </FooterMini>
    );
};

Footer.displayName = 'Footer';
