import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
    enrollQRCodeSelector,
    errorSelector,
    isActiveUserNotEnrolledSelector,
    isEnrollDeviceSuccessSelector,
    isEnrollSuccessSelector,
    isEnrollUnsuccessSelector,
} from 'redux/enroll/enrollSelectors';
import { cleanEnrollment, enrollDeviceByUserAndPassword } from 'redux/enroll/enrollActions';
import { withTranslation } from 'react-i18next';
import EnrollDeviceByUserAndPassword, { FORM_NAME } from './EnrollDeviceByUserAndPassword';
import { validate } from '../validation';

const mapStateToProps = (state) => ({
    isActiveUserNotEnrolled: isActiveUserNotEnrolledSelector(state),
    qrCode: enrollQRCodeSelector(state),
    isEnrollSuccess: isEnrollSuccessSelector(state),
    isEnrollDeviceSuccess: isEnrollDeviceSuccessSelector(state),
    error: errorSelector(state),
    isEnrollUnsuccess: isEnrollUnsuccessSelector(state),
});

const mapDispatchToProps = {
    onSubmit: enrollDeviceByUserAndPassword,
    cleanEnrollment,
};

const Translated = withTranslation()(EnrollDeviceByUserAndPassword);

const WrappedIntoReduxForm = reduxForm({
    form: FORM_NAME,
    validate,
})(Translated);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedIntoReduxForm);
