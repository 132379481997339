import ServiceManager from 'services/core/ServiceManager';
import { getErrorModelByStatus } from 'error/authErrors';
import {
    SUCCESS,
    UNEXPECTED_ERROR,
    UNEXPECTED_ERROR_OR_INVALID_PASSCODE,
} from 'static/requestResults';

export async function validatePasscode({ passcode, contactId, isTrustedDevice = false }, callback) {
    const result = {};

    try {
        const { data } = await ServiceManager.Security('authenticate', [
            contactId,
            { passcode, setTrusted: isTrustedDevice },
        ]);

        const resultStatus = data.Result;
        const error = getErrorModelByStatus(resultStatus);

        if (error !== undefined) {
            result.error = { type: 'error', message: error.message };
        } else if (resultStatus === SUCCESS) {
            result.sessionId = data.Session.SessionId;
            result.jwtAccessToken = data.Session.JwtAccessToken;
        } else {
            result.error = getErrorModelByStatus(UNEXPECTED_ERROR).message;
        }

        callback(result);
    } catch {
        callback(getErrorModelByStatus(UNEXPECTED_ERROR_OR_INVALID_PASSCODE).message);
    }
}
