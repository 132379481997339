import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initAnalitics } from 'utils/analytics';
import './configs/config';
import { ConnectedRouter } from 'connected-react-router';
import history from 'services/core/history';
import store from './store';
import App from './App';

import './translations/i18n';
import { LocaleProvider } from './locale';

const MEASUREMENT_ID = window.AppConfig?.GOOGLEANALYTIC_MEASUREMENTID;

initAnalitics(MEASUREMENT_ID, history);

if (!document.documentMode) {
    ReactDOM.render(
        // eslint-disable-next-line react/jsx-filename-extension
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <LocaleProvider>
                    <App />
                </LocaleProvider>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    );
}
