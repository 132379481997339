import { parse } from 'query-string';
import jwtDecode from 'jwt-decode';

const decodeAndParseHash = () => {
    return parse(decodeURIComponent(window.location.hash));
};

const getUrlHashParams = (paramKeys) => {
    const hash = decodeAndParseHash();
    const result = {};

    paramKeys.forEach((key) => {
        result[key] = hash[key];
    });

    return result;
};

export const $_GET = (parameterName) => {
    const hash = decodeAndParseHash();

    return hash && hash[parameterName];
};

export const getUrlTokenCredentials = () => {
    const { token: urlAccessToken } = getUrlHashParams(['token']);

    if (urlAccessToken) {
        const decodedJwt = jwtDecode(urlAccessToken);

        if (!decodedJwt?.cid && !decodedJwt?.jti) {
            return { isURLLogin: false };
        }

        return {
            isURLLogin: true,
            urlAccessToken,
            urlContactId: decodedJwt?.cid,
            roleId: decodedJwt?.mid,
            sessionId: decodedJwt?.jti,
        };
    }

    return {};
};

export const getDownloadDocumentUrlCredentials = (requiredPropsList) => {
    const hashParams = getUrlHashParams(requiredPropsList);
    const otherParams = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < requiredPropsList.length; i++) {
        if (hashParams[requiredPropsList[i]] === undefined) {
            return {};
        }

        otherParams[requiredPropsList[i]] = hashParams[requiredPropsList[i]];
    }

    if (hashParams.auth) {
        const urlAccessToken = global.atob(hashParams.auth).split(':')[1];
        const decodedJwt = urlAccessToken ? jwtDecode(urlAccessToken) : {};

        if (!decodedJwt?.cid || !decodedJwt?.jti) {
            return {};
        }

        return {
            urlAccessToken,
            contactId: decodedJwt.cid,
            ...otherParams,
        };
    }

    return {};
};
