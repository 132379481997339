import React, { Component } from 'react';
import { Title, Button, TextInput, Infobox } from 'ui-library';
import { isEmpty, isUndefined } from 'lodash/fp';
import PropTypes from 'prop-types';
import { SUCCESS } from 'static/requestResults';
import ServiceManager from 'services/core/ServiceManager';
import history from 'services/core/history';
import { validate } from './validation';
import { selectResult } from './selectors';
import { getErrorModelByStatus } from '../../../error/errors';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 0,
            password: undefined,
            confirmPassword: undefined,
            errors: {},
            generalErrors: undefined,
            isGeneralError: false,
        };

        this.changePasswordInputHandler = this.changePasswordInputHandler.bind(this);
        this.changeConfirmPasswordInputHandler = this.changeConfirmPasswordInputHandler.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const {
            match: {
                params: { activationKey },
            },
        } = this.props;
        const { password, confirmPassword } = this.state;
        const initAllValues = { ...this.state };

        Object.keys(initAllValues).forEach((key) => {
            initAllValues[key] = isUndefined(initAllValues[key]) ? null : initAllValues[key];
        });

        const errors = validate(initAllValues);

        this.setState({
            ...initAllValues,
            errors,
        });

        if (isEmpty(errors)) {
            ServiceManager.Security('changePassword', [activationKey, password, confirmPassword])
                .then((response) => {
                    const resultType = selectResult(response.data);
                    const error = getErrorModelByStatus(resultType);

                    if (error !== undefined) {
                        this.setState({
                            isGeneralError: true,
                            generalErrorMessage: error.message,
                        });
                    } else if (resultType === SUCCESS) {
                        this.setState({
                            view: 1,
                            isGeneralError: false,
                        });
                    } else {
                        this.setState({
                            isGeneralError: true,
                            generalErrorMessage: undefined,
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        isGeneralError: true,
                        generalErrorMessage: undefined,
                    });
                });
        }

        return false;
    };

    updateState(fieldName, value) {
        this.setState((prevState) => ({
            [fieldName]: value,
            errors: validate({
                ...prevState,
                [fieldName]: value,
            }),
        }));
    }

    changePasswordInputHandler(e) {
        this.setState({ isGeneralError: false });
        this.updateState('password', e.target.value);
    }

    changeConfirmPasswordInputHandler(e) {
        this.setState({ isGeneralError: false });
        this.updateState('confirmPassword', e.target.value);
    }

    render() {
        const { view, errors, isGeneralError, generalErrorMessage } = this.state;
        const { t } = this.props;

        if (view === 0) {
            return (
                <div className="ResetPassword content-box">
                    <Title type={2}>{t('resetPassword.passwordCreation')}</Title>
                    {isGeneralError && (
                        <Infobox accent error>
                            {generalErrorMessage === undefined
                                ? t('resetPassword.somethingWrong')
                                : t(generalErrorMessage)}
                        </Infobox>
                    )}
                    <p className="info-text">{t('resetPassword.enterNewPassword')}</p>
                    <form onSubmit={this.handleSubmit}>
                        <TextInput
                            label={t('resetPassword.newPassword')}
                            placeholder={t('resetPassword.password')}
                            error={t(errors.password)}
                            id="password"
                            type="password"
                            onChange={this.changePasswordInputHandler}
                        />
                        <TextInput
                            label={t('resetPassword.confirmNewPassword')}
                            placeholder={t('resetPassword.password')}
                            error={t(errors.confirmPassword)}
                            id="password2"
                            type="password"
                            onChange={this.changeConfirmPasswordInputHandler}
                        />
                        <Button type="primary" htmlType="submit">
                            {t('resetPassword.save')}
                        </Button>
                    </form>
                </div>
            );
        }

        return (
            <div className="ResetPassword content-box">
                <Title type={2}>{t('resetPassword.newPasswordIsSet')}</Title>
                <p>{t('resetPassword.passwordIsReadyToUse')}</p>
                <Button
                    type="primary"
                    onClick={() => {
                        history.push('/login');
                    }}
                >
                    {t('resetPassword.goToLogin')}
                </Button>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        isExact: PropTypes.bool,
        params: PropTypes.object,
    }),
};

ResetPassword.defaultProps = {
    match: {},
};

ResetPassword.displayName = 'ResetPassword';
