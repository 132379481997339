export const RESET_THEME = 'react-template-project/theme/RESET_THEME';
export const SET_THEME = 'react-template-project/enroll/SET_THEME';

export function resetTheme() {
    return { type: RESET_THEME };
}

export function setTheme(payload) {
    return { type: SET_THEME, payload };
}
