import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'ui-library';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { logout as logoutAction } from '../redux/auth/authActions';

const events = ['mousemove', 'click', 'scroll', 'keypress'];

const SESSION_TIMEOUT_WARNING_DIALOG = parseInt(
    global.SetupConfig.SESSION_TIMEOUT_WARNING_DIALOG,
    10
);
const SESSION_TIMEOUT_WARNING_DIALOG_COUNTDOWN = parseInt(
    global.SetupConfig.SESSION_TIMEOUT_WARNING_DIALOG_COUNTDOWN,
    10
);

export function withAutoLogout(WrappedComponent) {
    class AutoLogout extends Component {
        constructor(props) {
            super(props);

            this.timer = 0;
            this.modal = null;
            this.showModal = this.showModal.bind(this);
            this.resetTimeout = this.resetTimeout.bind(this);
            this.continueSession = this.continueSession.bind(this);
            this.clearSessionTimeout = this.clearSessionTimeout.bind(this);
            this.state = {
                modalTime: 1000 * SESSION_TIMEOUT_WARNING_DIALOG,
                logoutTime:
                    1000 *
                    (SESSION_TIMEOUT_WARNING_DIALOG + SESSION_TIMEOUT_WARNING_DIALOG_COUNTDOWN),
            };
        }

        componentDidMount() {
            events.forEach((event) => {
                global.window.addEventListener(event, this.resetTimeout);
            });

            this.setSessionTimeout();
        }

        componentWillUnmount() {
            events.forEach((event) => {
                global.window.removeEventListener(event, this.resetTimeout);
            });
            this.clearSessionTimeout();
            if (this.modal) this.modal.destroy();
        }

        setSessionTimeout() {
            const { logout } = this.props;
            const { modalTime, logoutTime } = this.state;

            if (this.modalTimeout) {
                this.clearSessionTimeout();
            }
            this.modalTimeout = setTimeout(this.showModal, modalTime);
            this.logoutTimeout = setTimeout(logout, logoutTime);
        }

        clearSessionTimeout() {
            if (this.timer) clearInterval(this.timer);
            if (this.modalTimeout) clearTimeout(this.modalTimeout);
            if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
        }

        resetTimeout() {
            this.clearSessionTimeout();
            this.setSessionTimeout();
        }

        renderModalContent = (t, seconds) => (
            <>
                {t('session.content')}
                <br />
                <br />
                {`${t('session.remainingTime')}: `}
                {`${seconds} ${t('session.seconds')}`}
            </>
        );

        continueSession() {
            events.forEach((event) => {
                global.window.addEventListener(event, this.resetTimeout);
            });
            this.resetTimeout();
        }

        showModal() {
            const { t } = this.props;
            let remainSeconds = SESSION_TIMEOUT_WARNING_DIALOG_COUNTDOWN;

            events.forEach((event) => {
                global.window.removeEventListener(event, this.resetTimeout);
            });

            this.modal = Modal.success({
                title: t('session.title'),
                onOk: this.continueSession,
                okText: t('session.button'),
                content: this.renderModalContent(t, remainSeconds),
                keyboard: false,
                className: 'auto-logout',
            });

            this.timer = setInterval(() => {
                remainSeconds -= 1;

                this.modal.update({
                    content: this.renderModalContent(t, remainSeconds),
                });
            }, 1000);

            setTimeout(() => {
                clearInterval(this.timer);
                this.modal.destroy();
            }, remainSeconds * 1000);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    AutoLogout.propTypes = {
        t: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
    };

    const mapDispatchToProps = (dispatch) => ({
        logout: () => dispatch(logoutAction(true)),
    });

    return connect(() => {}, mapDispatchToProps)(withTranslation()(AutoLogout));
}
