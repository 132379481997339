import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {
    BarChart,
    Table,
    LineChart,
    // RiskReturnChart,
    // BandwidthChart,
    // ProjectionChart,
} from 'ui-library';
import PieChart, { PieChartSA } from 'ui-library/dist/components/Charts/PieChart';
import {
    PIE,
    PIE_SA,
    BAR,
    LIST,
    LINE,
    // RISKRETURN,
    // BANDWIDTH,
    // PROJECTION,
    allocationColumns,
    lineChartLabels,
} from 'constants/constants';
import { useFormatting } from 'locale';
// import { useDetectedMobileDevice } from '../../hooks/useDetectedMobileDevice';
// import { RISKRETURN_HEIGHT, RISKRETURN_MOBILE_HEIGHT } from '../../constants/constants';

const isPropsEqual = (
    { data: prevPropsData, chartView: prevChartView },
    { data: nextPropsData, chartView: nextChartView }
) => {
    const isEqualData = isEqual(prevPropsData, nextPropsData);
    const isEqualChartView = prevChartView === nextChartView;

    return isEqualData && isEqualChartView;
};

function ChartType({ chartView, title, currency, t, ...props }) {
    const { getFormattedXAxisFormat } = useFormatting();

    // const { isMobileSize } = useDetectedMobileDevice();

    if (chartView === PIE_SA) return <PieChartSA {...props} />;
    if (chartView === PIE) return <PieChart {...props} />;
    if (chartView === BAR) return <BarChart {...props} />;
    if (chartView === LINE)
        return (
            <LineChart
                xAxisFormat={getFormattedXAxisFormat}
                labels={lineChartLabels(t)}
                {...props}
            />
        );
    if (chartView === LIST) {
        return <Table {...props} columns={allocationColumns(t)} />;
    }

    return null;
}

const MemoizedChartType = memo(ChartType, isPropsEqual);

ChartType.propTypes = {
    title: PropTypes.string,
    currency: PropTypes.string,
    chartView: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

ChartType.defaultProps = {
    title: '',
    currency: null,
};

export default MemoizedChartType;
