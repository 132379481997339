import {
    AUTH_COMPLETE,
    LOGOUT_SUCCESS,
    LOGIN_WITH_URL_SUCCESS,
    SESSION_CHECKED,
} from '../auth/authActions';

const defaultState = {
    isCurrentSessionChecked: false,
    isSessionValid: false,
};

const initState = defaultState;

export default function reducer(state = initState, action = {}) {
    switch (action.type) {
        case SESSION_CHECKED:
            return {
                ...state,
                isCurrentSessionChecked: true,
                isSessionValid: action.payload.isSessionValid,
            };
        case AUTH_COMPLETE:
            return {
                ...state,
                isCurrentSessionChecked: true,
                isSessionValid: true,
            };
        case LOGIN_WITH_URL_SUCCESS:
            return {
                ...state,
                isCurrentSessionChecked: true,
                isSessionValid: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isCurrentSessionChecked: false,
                isSessionValid: false,
            };
        default:
            return state;
    }
}
