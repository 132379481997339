import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-library';
import { Passcode } from 'components/molecules';
import './FuturaePasscode.css';

export const FuturaePasscode = ({ onSubmit, onChange, isLoading, infoText, passcodeLength }) => {
    const [passcode, setPasscode] = useState('');
    const changeTanHandler = (value) => {
        setPasscode(value);
        onChange(value);
    };
    const { t } = useTranslation();

    const submit = (event) => {
        event.preventDefault();
        onSubmit(passcode);
    };

    return (
        <div className="FuturaePasscode">
            <form onSubmit={submit}>
                <p className="info-text">{t(infoText)}</p>

                <Passcode onChange={changeTanHandler} passcodeLength={passcodeLength} />

                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading || passcode.length !== passcodeLength}
                    >
                        {isLoading ? t('login.validating') : t('login.confirm')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

FuturaePasscode.propTypes = {
    passcodeLength: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    infoText: PropTypes.string,
};

FuturaePasscode.defaultProps = {
    passcodeLength: 6,
    onChange: () => {},
    onCancel: () => {},
    error: undefined,
    isLoading: false,
    infoText: 'login.enterTheCode',
};
