import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from 'services/core/history';
import { useTranslation } from 'react-i18next';
import { Column, Row, PageVariant } from 'ui-library';
import InvestmentAllocation from 'components/organisms/InvestmentAllocation';
import News from 'components/organisms/News';
import { contactIdSelector } from 'redux/auth/authSelectors';
import AssetDevelopmentComponent from './components/AssetDevelopmentComponent';
import Overview from './components/Overview/Overview';
import RelationshipManager from './components/RelationshipManager';
import { useWithClientProfile } from './hooks/useWithClientProfile';
import { useWithInvestmentOverview } from './hooks/useWithInvestmentOverview';
import { useNews } from '../News/hooks/useNews';
import { useParentsAllocations } from './hooks/useParentsAllocations';
import PortfoliosList from './components/PortfoliosCard';
import './Dashboard.css';
import usePerformanceYTD from './hooks/usePerformanceYTD';

function Dashboard(props) {
    const { contactId: clientId } = props;
    const {
        data,
        isLoading: isLoadingInvestmentOverview,
        error: errorInvestmentOverview,
    } = useWithInvestmentOverview(clientId);
    const { investmentAllocation, isLoadingAllocation, errorAllocation } = useParentsAllocations(
        data.positions,
        isLoadingInvestmentOverview,
        data.overview.currency
    );
    const {
        data: { RMProfile },
        isLoading: isLoadingProfile,
        error: errorProfile,
    } = useWithClientProfile(clientId);

    const performanceYTD = usePerformanceYTD({ isLoadingInvestmentOverview, clientId });

    const news = useNews();
    const { t } = useTranslation();

    if (clientId === undefined) {
        return (
            <PageVariant
                type="error"
                title={t('error.title')}
                message={t('error.clientNotFound')}
                actionButtonLabel={t('error.button')}
                onActionButtonClick={() => {
                    history.push('/');
                }}
            />
        );
    }

    const oRelationManagerRowClick = ({ Icon }) => ({
        onClick: () => Icon.href && window.open(Icon.href, '_self'),
    });

    return (
        <div className="dashboard">
            <section className="first-section">
                <Row className="display-flex relationship-manager-section">
                    <Column size="lg-6" className="ColPrimary base-margin-bottom">
                        <Overview
                            data={data.overview}
                            isLoading={isLoadingInvestmentOverview || performanceYTD.isLoading}
                            error={errorInvestmentOverview || performanceYTD.error}
                            performanceYTD={performanceYTD.value}
                        />
                    </Column>
                    <Column size="lg-6" className="ColSecondary base-margin-bottom">
                        <RelationshipManager
                            data={RMProfile}
                            isLoading={isLoadingProfile}
                            error={errorProfile}
                            onRow={oRelationManagerRowClick}
                        />
                    </Column>
                </Row>
            </section>
            <section className="second-section">
                <Row className="display-flex">
                    <Column size="lg-6" className="ColPrimary base-margin-bottom">
                        <InvestmentAllocation
                            data={investmentAllocation}
                            isLoading={isLoadingAllocation}
                            error={errorAllocation}
                        />
                    </Column>
                    <Column size="lg-6" className="ColSecondary base-margin-bottom">
                        <AssetDevelopmentComponent
                            currency={data.overview.currency}
                            isLoading={isLoadingInvestmentOverview}
                            clientId={clientId}
                        />
                    </Column>
                </Row>
            </section>
            <section className="base-margin-bottom">
                <PortfoliosList
                    data={data.portfolios}
                    isLoading={isLoadingInvestmentOverview}
                    error={errorInvestmentOverview}
                />
            </section>
            <section className="base-margin-bottom">
                <News {...news} withLink />
            </section>
        </div>
    );
}

Dashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

Dashboard.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(Dashboard);
