import React, { useMemo, useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import { useTranslation } from 'react-i18next';
import useDocumentLoadFromUrl from '../hooks/useDocumentLoadFromUrl';
import { getFactsheetWithUrlParams } from '../api/documents';

const FACTSHEET_URL_PROPS = ['auth', 'docname'];

const MobileAppDownloadFactsheet = () => {
    const [pagesCount, setPagesCount] = useState(null);
    const { t } = useTranslation();
    const { isLoading, error, document } = useDocumentLoadFromUrl(
        getFactsheetWithUrlParams,
        FACTSHEET_URL_PROPS
    );

    const pages = useMemo(() => {
        if (!pagesCount) return [];

        const pagesArray = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < pagesCount; i++) {
            pagesArray.push('');
        }

        return pagesArray;
    }, [pagesCount]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={document?.file} text={t('dashboard.noData')}>
                <Document
                    file={document?.file}
                    onLoadSuccess={({ numPages }) => {
                        setPagesCount(numPages);
                    }}
                >
                    {pages.map((el, index) => (
                        <Page pageNumber={index + 1} key={`page_${index + 1}`} />
                    ))}
                </Document>
            </EmptyContent>
        </Preloader>
    );
};

export default MobileAppDownloadFactsheet;
