// api respose statuses
export const PRE_AUTH = 'preAuth';
export const TO_ENROLL_DEVICE = 'gotoDeviceEnrollment';
export const ENROLL_DEVICE = 'enrollYourDevice';
export const AUTH_PASSCODE = 'enterPasscode';
export const AUTH_ALLOW = 'authAllow';
// export const AUTH_MTAN = 'enterPasscode';
// export const AUTH_QRCODE = 'enterPasscode';

export const TITLE_VIEWS = {
    [PRE_AUTH]: 'login.LOGIN',
    [TO_ENROLL_DEVICE]: 'login.LOGIN',
    [ENROLL_DEVICE]: 'enrollDevice.twoFactorActivation',
    [AUTH_PASSCODE]: 'login.passCode',
}
