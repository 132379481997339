import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button, Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { FieldRenderer } from '../Field/index';
import { PasswordFieldRenderer } from '../Field/PasswordRenderer';
import EnrollementInfoBox from '../EnrollmentInfoBox';

const UserAndPassword = ({
    submitLabel,
    title,
    submitting,
    handleSubmit,
    error,
    info,
    onSubmit,
    bottomLinks,
}) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="content-pane">
                {title !== null && <Title type={2}>{t(title)}</Title>}
                {info && <EnrollementInfoBox />}
                {submitting || !error ? null : error}
                <Field
                    name="username"
                    type="text"
                    id="username"
                    autoComplete="off"
                    label={t('common.USERNAME')}
                    component={FieldRenderer}
                />
                <br />
                <Field
                    name="password"
                    id="password"
                    autoComplete="off"
                    label={t('common.PASSWORD')}
                    component={PasswordFieldRenderer}
                />
                <br />
                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button type="primary" htmlType="submit" disabled={submitting}>
                        {t(submitLabel)}
                    </Button>
                    {bottomLinks}
                </div>
            </div>
        </form>
    );
};

UserAndPassword.propTypes = {
    bottomLinks: PropTypes.instanceOf(Object),
    error: PropTypes.func,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    // rememberMe: PropTypes.func,
    info: PropTypes.string,
    title: PropTypes.string,
    submitLabel: PropTypes.string.isRequired,
};

UserAndPassword.defaultProps = {
    bottomLinks: null,
    // rememberMe: null,
    error: null,
    info: null,
    title: null,
};

UserAndPassword.displayName = 'UserAndPassword';

export default UserAndPassword;
