import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import setHTMLDocumentTitle from 'utils/setHTMLDocumentTitle';

const RouteWithAppTitle = (props) => {
    const { title } = props;

    if (title) setHTMLDocumentTitle(title);

    return <Route {...props} />;
};

RouteWithAppTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default RouteWithAppTitle;
