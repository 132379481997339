import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';

export const EmptyContent = ({ data, text, buttonText, onClick, children }) => {
    if (data === null || (data && data.length === 0)) {
        return [
            text && <p className="widget__empty">{text}</p>,
            buttonText && (
                <div className="widget__button">
                    <Button type="secondary" size="small" onClick={onClick}>
                        {buttonText}
                    </Button>
                </div>
            ),
        ];
    }

    return children;
};

EmptyContent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    text: PropTypes.text,
    onClick: PropTypes.func,
    buttonText: PropTypes.text,
};

EmptyContent.defaultProps = {
    data: null,
    text: null,
    onClick: () => {},
    buttonText: null,
};
