import CustomServiceManager from './CustomServiceManager';
import { INSTRUMENT_DOCUMENTS_ID } from '../../constants/constants';

export const getSecurityFactsheet = async (securityDetails) => {
    const { data: metaData } = await CustomServiceManager.getMetaDataId();
    const { Id: metaDataId } =
        metaData && metaData.find((item) => item.DocumentReferenceId === INSTRUMENT_DOCUMENTS_ID);

    if (securityDetails.Factsheet) {
        const { data } = await CustomServiceManager.getPublicDocument(
            metaDataId,
            securityDetails?.Factsheet
        );

        return data;
    }

    return '';
};
