import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import eyeOpened from '../../clientRequirements/images/eyeOpened.png';
import eyeClosed from '../../clientRequirements/images/eyeСlosed.png';
import { Button } from '../Button';

const PasswordEyeButton = ({ isPassVisible, setPassVisible }) => {
    const { t } = useTranslation();

    return (
        <Button
            type="button"
            onClick={() => setPassVisible(!isPassVisible)}
            className="eye-button"
            aria-label={t(isPassVisible ? 'login.showPassword' : 'login.hidePassword')}
        >
            <img
                alt=""
                src={isPassVisible ? eyeOpened : eyeClosed}
                className="pass-eye"
                aria-hidden="true"
            />
        </Button>
    );
};

PasswordEyeButton.propTypes = {
    isPassVisible: PropTypes.bool.isRequired,
    setPassVisible: PropTypes.func.isRequired,
};

export default PasswordEyeButton;
