import React from 'react';
import { Title, ContentBox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { OverviewPanel } from 'components/Overview';
import PageSection from 'components/PageSection/PageSection';
import { Preloader } from 'components/molecules/Preloader';
import PropTypes from 'prop-types';
import { NA } from 'static/na';
import OverviewItem from './OverviewItem';
import { TYPE_CURRENCY, TYPE_PERCENT, TYPE_NUMBER, TYPE_TEXT } from './constants';

function Overview({ data, isLoading, error, performanceYTD }) {
    const { t } = useTranslation();

    return (
        <PageSection>
            <ContentBox underline={false}>
                <Title type={2} className="overview__header">
                    {t('overview.title')}
                </Title>
                <Preloader isLoading={isLoading} error={error}>
                    <div className="overview-wrapper">
                        <OverviewPanel>
                            <OverviewItem
                                currency={data.currency}
                                title={t('overview.totalValue')}
                                type={TYPE_CURRENCY}
                                value={data.totalValue}
                            />
                            <OverviewItem
                                title={t('overview.performanceYTD')}
                                htmlTitle={t('overview.performanceYTD')}
                                type={performanceYTD ? TYPE_PERCENT : TYPE_TEXT}
                                value={performanceYTD || NA}
                            />
                            <OverviewItem
                                title={t('overview.inceptionDate')}
                                htmlTitle={t('overview.inceptionDate')}
                                type={TYPE_TEXT}
                                value={data.inceptionDate}
                                size="mid"
                            />
                            <OverviewItem
                                title={t('portfolios.columns.gainYTD')}
                                htmlTitle={t('portfolios.columns.gainYTD')}
                                type={TYPE_NUMBER}
                                value={data.gainYTD}
                                size="mid"
                                colored
                            />
                        </OverviewPanel>
                    </div>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        currency: PropTypes.string,
        totalValue: PropTypes.number,
        performanceValue: PropTypes.number,
        inceptionDate: PropTypes.string,
        gainYTD: PropTypes.number,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    performanceYTD: PropTypes.number,
};

Overview.defaultProps = {
    error: null,
    performanceYTD: null,
};

export default Overview;
