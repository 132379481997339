import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting, useLocale } from 'locale';
import { adaptPortfolioDetails } from '../adapters/adaptPortfolioDetails';

export const usePortfolioDetails = (clientId, portfolioId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({
        investmentAllocation: { chart: [{ data: { pie: {}, bar: {}, list: {} } }] },
        positions: [],
        positionsRaw: [],
    });
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { locale } = useLocale();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        (async () => {
            try {
                const response = await ServiceManager.portfolioManagement('getPortfolioDetails', [
                    portfolioId,
                    clientId,
                    params,
                ]);
                const { ModelPortfolioId } = response.data;

                if (response.data && response.data.Positions) {
                    response.data.Positions.sort(function (a, b) {
                        return parseFloat(b.Allocation) - parseFloat(a.Allocation);
                    });
                }

                let modelData = {};

                if (ModelPortfolioId) {
                    modelData = await ServiceManager.portfolioManagement('getModelPortfolio', [
                        ModelPortfolioId,
                        params,
                    ]);
                }

                try {
                    setData(
                        adaptPortfolioDetails(
                            { ...modelData.data, ...response.data },
                            t,
                            getFormattedDate,
                            getFormattedNumber,
                            getFormattedCurrency
                        )
                    );
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            } catch (err) {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            }
        })();
    }, [i18n.language, locale]);

    return { data, isLoading, error };
};
