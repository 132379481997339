import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title, Paragraph, ContentBox } from 'ui-library';
import { getAdaptedDate } from 'utils/formatting';
import { useFormatting } from 'locale';
import { LinkNews } from './LinkNews';
import './NewsItem.css';

const NewsItem = (props) => {
    const { id, image, title, date, content, link } = props;
    const { t } = useTranslation();
    const { getFormattedDate } = useFormatting();

    const formattedDate = getFormattedDate(getAdaptedDate(date, t));

    return (
        <ContentBox className="news-item">
            <LinkNews link={link} id={id} {...props}>
                {image && (
                    <div className="news-item-image-container">
                        <img className="news-item-image" src={image} alt="news-img" />
                    </div>
                )}
                <div className="news-item-content">
                    <div className="news-item-title">
                        <Title type={4}>{title}</Title>
                        <Paragraph className="news-item-date">{formattedDate}</Paragraph>
                    </div>
                    <div>
                        <p>{content}</p>
                    </div>
                </div>
            </LinkNews>
        </ContentBox>
    );
};

NewsItem.propTypes = {
    id: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
};

NewsItem.defaultProps = {
    id: null,
    image: null,
    title: null,
    date: null,
    content: null,
    link: null,
};

export default NewsItem;
