import { get } from 'lodash/fp';
import Service from 'additiv-services';
import { getDmsUrl, getCustomUrl, getServicesUrl, V11 } from 'additiv-services/dist/setup';
import { checkStatus } from '../core/generalStatusCheck';

const serviceCall = (service, method, argsArray = []) => {
    const result = service[method](...argsArray);
    const promise = result instanceof Promise ? result : result[0];

    promise
        .then(() => {})
        .catch((reason) => {
            checkStatus(get('response.status', reason));
        });

    return result;
};

class CustomServiceManager {
    static Service(method, argsArray) {
        return serviceCall(Service, method, argsArray);
    }

    static getMetaDataId() {
        return Service.getRequest(`${getDmsUrl()}/documents/metadata`, {
            isSecure: true,
        });
    }

    static getPublicDocument(metadataId, externalId) {
        return Service.getRequest(
            `${getDmsUrl()}/documents/metadata/${metadataId}/owners/0/externals/${externalId}`,
            {
                isSecure: true,
            }
        );
    }

    static getLanguages(language) {
        return Service.getRequest(`${getServicesUrl()}/languages/${language}`, {
            isSecure: true,
        });
    }

    static generateInvestmentOverviewReport(params) {
        return Service.postRequest(`${getServicesUrl()}/templates/generateTemplate`, params, {
            isSecure: true,
        });
    }

    static getWPPosts(params = {}) {
        const queries = Object.entries(params);
        const urlParams = queries.reduce(
            (acc, item) => (item[1] ? `${acc}&${item[0]}=${item[1]}` : ''),
            ''
        );

        if (urlParams) {
            return Service.getRequest(`${getCustomUrl()}/nsnews/posts/?${urlParams}`, {
                isSecure: true,
            });
        }

        return Service.getRequest(`${getCustomUrl()}/nsnews/posts/`, {
            isSecure: true,
        });
    }

    static getWPPostImages(postId) {
        return Service.getRequest(`${getCustomUrl()}/nsnews/media?parent=${postId}`, {
            isSecure: true,
        });
    }

    static logIn(username, password) {
        return Service.postRequest(`${getServicesUrl()}/authentication/authenticate`, {
            UserName: username,
            password,
            ForceExpiringPreviousSession: false,
        });
    }

    static validateToken(AccessToken) {
        return Service.postRequest(`${getServicesUrl()}/authentication/tokens/validate`, {
            AccessToken,
        });
    }

    static logIn2(_ref2) {
        const { username, password, trustedDeviceToken } = _ref2;

        return Service.postRequest(`${getServicesUrl(V11)}/authentication/pre-authenticate`, {
            UserName: username,
            Password: password,
            ForceExpiringPreviousSession: false,
            TrustedDeviceToken: trustedDeviceToken,
        });
    }

    static logout(contactId) {
        return Service.postRequest(
            `${getServicesUrl()}/contacts/${contactId}/authentication/logoff`,
            null,
            {
                isSecure: true,
            }
        );
    }
}

export default CustomServiceManager;
