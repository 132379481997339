import React from 'react';
import PropTypes from 'prop-types';
import { contactIdSelector, isAuthenticatedSelector } from 'redux/auth/authSelectors';
import { connect } from 'react-redux';
import { logout } from 'redux/auth/authActions';
import history from 'services/core/history';
import { Header } from '../components/Header';
import { useWithClientProfile } from './hooks/useWithClientProfile';

const HeaderContainer = ({ contactId, isAuthenticated, ...props }) => {
    const { data } = useWithClientProfile(contactId, isAuthenticated);

    return <Header {...props} profile={data} isAuthenticated={isAuthenticated} />;
};

HeaderContainer.propTypes = {
    contactId: PropTypes.number.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticatedSelector(state),
    contactId: contactIdSelector(state),
});

const mapDispatchToProps = {
    logout,
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, ownProps, stateProps, {
        onRightButtonClick: () => {
            if (stateProps.isAuthenticated === true) {
                dispatchProps.logout();
            } else {
                history.push('/login');
            }
        },
    });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HeaderContainer);
