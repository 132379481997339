import darkCustomVariables from 'clientRequirements/styles/dark/variables-export.css';
import modernCustomVariables from 'clientRequirements/styles/modern/variables-export.css';

export const variables = {
    dark: darkCustomVariables,
    light: modernCustomVariables,
};

export const setThemeVariables = (theme) => {
    const vars = variables[theme];

    Object.keys(vars).forEach((key) => {
        document.documentElement.style.setProperty(key, vars[key]);
    });
};
