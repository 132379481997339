import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/core/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { portfolioActions } from 'constants/constants';
import { useFormatting, useLocale } from 'locale';
import { adaptInvestmentOverview } from '../adapters/adaptInvestmentOverview';

export const useWithInvestmentOverview = (clientId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({
        overview: {},
        investmentAllocation: { chart: [{ data: { pie: {}, bar: {}, list: {} } }] },
    });
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { getFormattedCurrency, getFormattedDate } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params])
            .then((response) => {
                try {
                    setData(
                        adaptInvestmentOverview(
                            response.data,
                            portfolioActions,
                            t,
                            getFormattedCurrency,
                            getFormattedDate
                        )
                    );
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language, locale]);

    return { data, isLoading, error };
};
