import Error from '../models/Error';

const ServerError = (err) =>
    new Error({
        type: 'SERVER_ERROR',
        message: `Server error ${err.message ? `: ${err.message}` : ''}`,
        ...err,
    });

export default ServerError;
