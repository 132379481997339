import { renderers } from 'ui-library';
import { CellLink, CellPercent, CellWithMoreActions, CellAdaptedDate } from 'components/renderers';
import { sorterString, sorterByDate, sorterNumber } from 'utils/sorting';
import history from 'services/core/history';
import CellPDFView from 'components/renderers/CellPDFView';

const {
    CellWithIcon,
    CellWithSubtext,
    CellWithLinkIcon,
    CellWithHyperlinkIcon,
    CellWithButtonIcon,
} = renderers;

export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 8;
export const TOTAL_PAGES = 10;

export const SORT_DIRECTION_ASC = 'asc';
export const SORT_DIRECTION_DESC = 'desc';

export const PIE = 'pie';
export const PIE_SA = 'pieSa';
export const BAR = 'bar';
export const LIST = 'list';
export const LINE = 'line';
export const PROJECTION = 'projection';

export const MOBILE_VIEWPORT_MIN_WIDTH = 540;
export const NOTZ_STUCKI_MAIN_SITE = '//nspgroup.com ';

export const LINKEDIN_URL = 'https://www.linkedin.com/company/notz-stucki-group';

export const allocationColumns = (t) => [
    {
        key: 'name',
        title: t('Allocation.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
        defaultSortOrder: 'descend',
    },
    {
        key: 'value',
        title: t('Allocation.columns.value'),
        width: 200,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('value'),
    },
    {
        key: 'percent',
        title: t('Allocation.columns.allocation'),
        width: 150,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('percent'),
    },
];

export const profileColumns = [
    {
        key: 'Information',
        render: CellWithSubtext,
    },
    {
        key: 'Icon',
        className: 'text-right',
        render: CellWithLinkIcon,
    },
];

export const LIQUIDITY_TYPE_ID = 1;

export const INSTRUMENT_DOCUMENTS_ID = 'InstrumentDocument';

export const portfoliosColumns = (t, getFormattedNumber) => [
    {
        key: 'Name',
        title: t('portfolios.columns.name'),
        sortable: true,
        sorter: sorterString('Name.label'),
        render: CellLink,
        defaultSortOrder: 'descend',
    },
    {
        key: 'Product',
        title: t('portfolios.columns.contractType'),
        sorter: sorterString('Product'),
    },
    {
        key: 'Profile',
        title: t('portfolios.columns.investmentProfile'),
        sorter: sorterString('Profile'),
    },
    {
        key: 'Value',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        render: CellWithSubtext,
    },
    {
        key: 'Performance',
        title: t('portfolios.columns.performanceSI'),
        className: 'text-right',
        width: 160,
        sortable: true,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('Performance'),
    },
    {
        key: 'PerformanceYTD',
        title: t('portfolios.columns.performanceYTD'),
        className: 'text-right',
        width: 160,
        sortable: true,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('PerformanceYTD'),
    },
    {
        key: 'gainYTD',
        title: t('portfolios.columns.gainYTD'),
        className: 'text-right',
        width: 160,
        sortable: true,
        sorter: sorterNumber('gainYTD'),
    },
    {
        key: 'inceptionDate',
        title: t('portfolios.columns.inceptionDate'),
        className: 'text-right',
        width: 160,
        sortable: true,
        sorter: sorterNumber('inceptionDate'),
    },
    {
        key: 'currency',
        title: t('portfolios.columns.referenceCurrency'),
        className: 'text-right',
        width: 160,
        sortable: true,
        sorter: sorterNumber('currency'),
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'Actions',
        render: CellWithMoreActions,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const portfoliosMobileColumns = (t, getFormattedNumber) => [
    {
        key: 'Name',
        title: t('portfolios.columns.name'),
        sortable: true,
        sorter: sorterString('Name.label'),
        render: CellLink,
        defaultSortOrder: 'descend',
    },
    {
        key: 'Value',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        render: CellWithSubtext,
    },
    {
        key: 'Performance',
        title: t('portfolios.columns.performanceSI'),
        className: 'text-right',
        width: 160,
        sortable: true,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('Performance'),
    },
];

export const portfolioActions = (id, productName, t) => [
    {
        text: `${t('portfolios.openPortfolio')}`,
        onClick: () => {
            history.push(`/portfolios/${id}`);
        },
    },
];

export const lineChartLabels = (t) => ({
    months: t('months').split('_'),
    shortMonths: t('shortMonths').split('_'),
});

// TODO: unused code below, delete after approval
export const relationshipManagerColumns = [
    {
        key: 'Information',
        render: CellWithSubtext,
        width: 'calc(100% - 40px)',
    },
    {
        key: 'Icon',
        width: 40,
        className: 'text-right',
        render: CellWithHyperlinkIcon,
    },
];

export const documentsColumns = (t) => [
    {
        key: 'Icon',
        render: CellWithButtonIcon,
        width: 20,
    },
    {
        key: 'Document',
        sortable: true,
        sorter: sorterString('Name.value'),
        width: 185,
        render: CellPDFView,
    },
    {
        key: 'Date',
        sortable: true,
        sorter: sorterByDate('Date'),
        width: 103,
        className: 'text-right table-cell__date',
        render: CellAdaptedDate(t),
    },
];

export const documentsSorting = { key: 'Date', direction: 'asc' };

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = ['YTD', 'Last 12 months', '2 Years', '5 Years', 'Max'];

export const overviewSelectOptions = ['Month', 'Year'];

export const datePickerLocale = (t) => ({
    lang: {
        placeholder: 'Select date',
        rangePlaceholder: ['Start date', 'End date'],
        today: t('calendar.today'),
        now: t('calendar.now'),
        backToToday: t('calendar.backToToday'),
        ok: t('calendar.ok'),
        clear: t('calendar.clear'),
        month: t('calendar.month'),
        year: t('calendar.year'),
        timeSelect: t('calendar.selectTime'),
        dateSelect: t('calendar.selectDate'),
        monthSelect: t('calendar.chooseMonth'),
        yearSelect: t('calendar.chooseYear'),
        decadeSelect: t('calendar.chooseDecade'),
        yearFormat: 'YYYY',
        dateFormat: 'M/D/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY HH:mm:ss',
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: t('calendar.prevMonth'),
        // 'Previous month (PageUp)',
        nextMonth: t('calendar.nextMonth'),
        // 'Next month (PageDown)',
        previousYear: t('calendar.lastYear'),
        // 'Last year (Control + left)',
        nextYear: t('calendar.nextYear'),
        // 'Next year (Control + right)',
        previousDecade: t('calendar.lastDecade'),
        // 'Last decade',
        nextDecade: t('calendar.nextDecade'),
        // 'Next decade',
        previousCentury: t('calendar.lastCentury'),
        // 'Last century',
        nextCentury: t('calendar.nextCentury'),
        // 'Next century',
    },
    timePickerLocale: {
        placeholder: t('calendar.selectTime'),
        // 'Select time',
    },
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
});
