import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Infobox, Title } from 'ui-library';
import { get, getOr } from 'lodash/fp';
import { connect } from 'react-redux';
import history from 'services/core/history';
import { AUTH_COMPLETE, getUserProfile } from 'redux/auth/authActions';
import { isLoginDoneSelector } from 'redux/auth/authSelectors';
import { getUrlTokenCredentials } from 'utils/url';
import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';
import {
    PRE_AUTH,
    TO_ENROLL_DEVICE,
    ENROLL_DEVICE,
    AUTH_PASSCODE,
    AUTH_ALLOW,
    TITLE_VIEWS,
} from '../constants';
import LoginForm from '../components2/LoginForm';
import MobileAppApprove from '../components2/MobileAppApprove';
import { preAuth } from '../api/preAuth';
import { enrollDeviceByUserAndPassword } from '../api/enrollDevice';
import { EnrollQRCode } from '../components2/EnrollQRCode';
import { validatePasscode } from '../api/validatePassCode';
import { validateUsername } from '../utils/validation';
import LoginContentPane from '../components2/LoginContentPane';
import ToEnrollDevice from '../components2/ToEnrollDevice';

const LoginContainer = (props) => {
    const { t } = useTranslation();
    const [loginData, setLoginData] = useState({
        error: {},
        status: PRE_AUTH,
        validatedUsername: null,
        password: null,
        contactId: null,
        isSubmitting: false,
        isLoading: false,
    });
    const { isLoginDone } = props;

    const {
        urlAccessToken,
        urlContactId,
        roleId,
        isURLLogin,
        sessionId,
    } = getUrlTokenCredentials();

    useEffect(() => {
        if (loginData.error?.message === 'error.invalidLoginCredentials') {
            sendGAEvent(GA_ACTIONS.login, GA_CATEGORIES.error, 'invalid_login_credentials');
        }
    }, [loginData.error]);

    useEffect(() => {
        if (isURLLogin) {
            props.onAuthComplete({
                sessionId,
                jwtAccessToken: urlAccessToken,
                contactId: urlContactId,
                roleId,
            });
        }
    }, [isURLLogin]);

    useEffect(() => {
        if (isLoginDone) {
            const { router } = props;
            const fromPathname = getOr(undefined, 'location.state.from.pathname', router);

            if (fromPathname && fromPathname !== '/login' && fromPathname !== '/') {
                history.push(fromPathname);
            } else {
                history.push('/dashboard');
            }
        }
    }, [isLoginDone]);

    const submitFormHandlers = {
        [PRE_AUTH]: {
            onSubmit: ({ username, password }) => {
                const validatedUsername = validateUsername(username);

                setLoginData((state) => ({
                    status: state.status,
                    isSubmitting: true,
                    validatedUsername,
                    password,
                }));

                preAuth(validatedUsername, password, (result) => {
                    if (result.status === AUTH_ALLOW) {
                        props.onAuthComplete({
                            sessionId: result.sessionId,
                            jwtAccessToken: result.jwtAccessToken,
                            contactId: result.contactId,
                        });
                        props.handleGetUserProfile();
                        props.redirectToDashboard();
                    } else {
                        setLoginData((state) => ({
                            ...state,
                            ...result,
                            isSubmitting: false,
                        }));
                    }
                });
            },
            submitLabel: t('login.LOGIN'),
            bottomLinks: (
                <Button
                    onClick={() => {
                        history.push('/forgot-password');
                    }}
                    type="secondary"
                >
                    {t('login.FORGOT_PASSWORD')}
                </Button>
            ),
        },

        [TO_ENROLL_DEVICE]: {
            onSubmit: () => {
                setLoginData((state) => ({
                    ...state,
                    isSubmitting: true,
                }));

                enrollDeviceByUserAndPassword(
                    loginData.validatedUsername,
                    loginData.password,
                    (result) => {
                        setLoginData((state) => ({
                            ...state,
                            ...result,
                            isSubmitting: false,
                        }));
                    }
                );
            },
        },

        [AUTH_PASSCODE]: {
            onPreAuth: () => {
                setLoginData((state) => ({ ...state, isPreAuthLoading: true }));
                preAuth(loginData.validatedUsername, loginData.password, (result) => {
                    setLoginData((state) => ({
                        ...state,
                        ...result,
                        isPreAuthLoading: false,
                    }));
                });
            },
            validatePasscode: (passcode) => {
                setLoginData((state) => ({ ...state, isLoading: true }));

                validatePasscode(
                    {
                        passcode,
                        contactId: loginData.contactId,
                    },
                    (result) => {
                        if (result.sessionId && result.jwtAccessToken) {
                            props.onAuthComplete({
                                sessionId: result.sessionId,
                                jwtAccessToken: result.jwtAccessToken,
                                contactId: loginData.contactId,
                            });
                            props.handleGetUserProfile();
                            props.redirectToDashboard();
                        } else {
                            setLoginData((state) => ({
                                ...state,
                                ...result,
                                isLoading: false,
                            }));
                        }
                    }
                );
            },
            onBackToLogin: () => {
                setLoginData({
                    error: {},
                    status: PRE_AUTH,
                    validatedUsername: null,
                    password: null,
                    contactId: null,
                    isSubmitting: false,
                    isLoading: false,
                });
            },
        },
    };
    const currentHandler = submitFormHandlers[loginData.status];

    const loginViews = {
        [PRE_AUTH]: (handler) => (
            <LoginForm {...props} {...loginData} {...handler} errorView="errorView" />
        ),
        [TO_ENROLL_DEVICE]: (handler) => <ToEnrollDevice {...loginData} {...handler} />,
        [ENROLL_DEVICE]: () => <EnrollQRCode {...loginData} />,
        [AUTH_PASSCODE]: (handler) => <MobileAppApprove {...loginData} {...handler} />,
    };
    const loginView = loginViews[loginData.status];
    const currentTitle = TITLE_VIEWS[loginData.status];

    return (
        <LoginContentPane>
            <Title type={2}>{t(currentTitle)}</Title>
            {loginData.error?.type && (
                <div className="validation-summary-errors">
                    <Infobox>{t(loginData.error?.message)}</Infobox>
                </div>
            )}
            <div className="login-content">{loginView(currentHandler)}</div>
        </LoginContentPane>
    );
};

const mapStateToProps = (state) => ({
    router: get('router', state),
    isLoginDone: isLoginDoneSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleGetUserProfile: () => dispatch(getUserProfile()),
    onAuthComplete: (payload) => dispatch({ type: AUTH_COMPLETE, payload }),
    redirectToDashboard: () => {
        history.push('/dashboard');
    },
});

LoginContainer.propTypes = {
    onAuthComplete: PropTypes.func.isRequired,
    handleGetUserProfile: PropTypes.func.isRequired,
    redirectToDashboard: PropTypes.func.isRequired,
    isLoginDone: PropTypes.bool.isRequired,
    router: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
