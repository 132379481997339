export class ErrorModel {
    #type;

    #id;

    #message;

    #data;

    constructor({ type, message, data, id }) {
        this.#type = type;
        this.#message = message;
        this.#data = data;
        this.#id = id;
    }

    get type() {
        return this.#type;
    }

    get id() {
        return this.#id;
    }

    get message() {
        return this.#message;
    }

    get data() {
        return this.#data;
    }
}

export const generateError = (data) => new ErrorModel(data);
