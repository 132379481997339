import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import commonContent from 'additiv-services/dist/services/core/commonContent';
import CustomServiceManager from 'services/custom/CustomServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptNews } from '../adapters/adaptNews';
import { adaptWPNews } from '../adapters/adaptWPNews';

// TODO: move newsTags to AppConfig as an option or other config file
const newsTags = {
    general: 818,
    en: 828,
    de: 847,
    fr: 849,
    it: 846,
    es: 848,
    pt: 850,
};

const getPostImage = (media) => {
    if (!Array.isArray(media)) {
        return '';
    }

    const mediaSizes =
        // eslint-disable-next-line dot-notation
        media.find((attachment) => attachment['media_type'] === 'image')?.['media_details']
            ?.sizes || {};

    return mediaSizes.medium?.['source_url'];
};

const getAdaptedWpNewsData = async (language) => {
    const tags = `${newsTags[language]}%2c${newsTags.general}`;
    const postsData = await CustomServiceManager.getWPPosts({ per_page: 20, tags });
    const postsWithImages = postsData
        ? await Promise.all(
              postsData.data.map((post) =>
                  CustomServiceManager.getWPPostImages(post.id).then(({ data }) => ({
                      ...post,
                      image: getPostImage(data),
                  }))
              )
          ).then((data) => ({
              data,
              totalPages: postsData.headers['x-wp-totalpages'],
          }))
        : [];

    return adaptWPNews(postsWithImages);
};

const getAdaptedDfsNewsData = async (dfsNews, page, i18n) => {
    const postsData = await commonContent.getNews(2, i18n?.language);

    return adaptNews(postsData);
};

// TODO: implement pagination or infinite scrolling
export const useNews = () => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        setError(null);
        Promise.allSettled([getAdaptedWpNewsData(i18n.language), getAdaptedDfsNewsData(i18n)])
            .then(([wp, dfs]) => {
                try {
                    const newPosts = [];

                    if (wp.status === 'fulfilled' && wp.value?.posts) {
                        newPosts.push(...wp.value.posts);
                    }
                    if (dfs.status === 'fulfilled' && dfs.value?.posts) {
                        newPosts.push(...dfs.value.posts);
                    }

                    setNewsList(newPosts.sort((a, b) => new Date(b.date) - new Date(a.date)));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language]);

    return { data: newsList, isLoading, error };
};
