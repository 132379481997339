import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { getFirstDayOfMonth } from 'utils/datetime';
import { useTranslation } from 'react-i18next';

const MonthRangePicker = ({ monthFrom, monthTo, format }) => {
    const { t } = useTranslation();
    const { MonthPicker } = DatePicker;
    const { startDate, setStartDate } = monthFrom;
    const { endDate, setEndDate } = monthTo;

    function disabledDate(current) {
        return current && getFirstDayOfMonth(current) <= getFirstDayOfMonth(startDate);
    }

    const setStartDateWithValidation = (date) => {
        if (getFirstDayOfMonth(date) >= getFirstDayOfMonth(endDate)) {
            setEndDate(null);
            setStartDate(date);
        } else {
            setStartDate(date);
        }
    };

    return (
        <div className="month-range-picker">
            <MonthPicker
                {...monthFrom}
                className={
                    monthFrom.className
                        ? `month-picker-start ${monthFrom.className}`
                        : 'month-picker-start'
                }
                value={startDate}
                format={format}
                onChange={(date) => setStartDateWithValidation(date)}
                placeholder={t(monthFrom.placeholder)}
            />
            <MonthPicker
                {...monthTo}
                className={
                    monthFrom.className
                        ? `month-picker-end ${monthFrom.className}`
                        : 'month-picker-end'
                }
                value={endDate}
                format={format}
                onChange={(date) => setEndDate(date)}
                placeholder={t(monthTo.placeholder)}
                disabledDate={disabledDate}
            />
        </div>
    );
};

export default MonthRangePicker;

MonthRangePicker.propTypes = {
    monthFrom: PropTypes.shape({
        className: PropTypes.string,
        placeholder: PropTypes.string,
        startDate: PropTypes.shape({}),
        setStartDate: PropTypes.func,
    }),
    monthTo: PropTypes.shape({
        className: PropTypes.string,
        placeholder: PropTypes.string,
        endDate: PropTypes.shape({}),
        setEndDate: PropTypes.func,
    }),
    format: PropTypes.string,
};
MonthRangePicker.defaultProps = {
    monthFrom: {
        className: '',
        placeholder: 'from',
    },
    monthTo: {
        className: '',
        placeholder: 'to',
    },
    format: 'MMM-YY',
};
