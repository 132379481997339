// document domain
import { setup } from 'additiv-services';
import DocumentsService from 'additiv-services/dist/services/core/documents';
import { createBlobFile } from 'utils/documentUtils';
import CustomServiceManager from 'services/custom/CustomServiceManager';
import { INSTRUMENT_DOCUMENTS_ID } from 'constants/constants';

export const getDocumentWithUrlParams = async (
    { urlAccessToken, contactId, documentId },
    callback
) => {
    try {
        setup({ jwt: urlAccessToken });

        const { data } = await DocumentsService.downloadDocument(documentId, contactId);

        callback({ data: createBlobFile(data) });

        setup({ jwt: undefined });
    } catch (e) {
        callback({ error: { type: 'api', message: 'unexpected error' } });
    }
};

export const getFactsheetWithUrlParams = async (
    { urlAccessToken, docname: factsheetName },
    callback
) => {
    try {
        setup({ jwt: urlAccessToken });

        const { data: metaData } = await CustomServiceManager.getMetaDataId();
        const { Id: metaDataId } =
            metaData &&
            metaData.find((item) => item.DocumentReferenceId === INSTRUMENT_DOCUMENTS_ID);

        const { data } = await CustomServiceManager.getPublicDocument(metaDataId, factsheetName);

        callback({ data: createBlobFile(data) });

        setup({ jwt: undefined });
    } catch (e) {
        callback({ error: { type: 'api', message: 'unexpected error' } });
    }
};
