import { getDateFormat } from 'additiv-intl-formatting';
import { dateTimeFormats } from './formatTypes';

export function getUTCDate(date) {
    const d = new Date(date);

    return new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
        )
    );
}

export function getNumberSeparators(locale) {
    const formattedNum = new Intl.NumberFormat(locale).format(1111.11);

    const [thousandSeparator, decimalSeparator] = [formattedNum[1], formattedNum[5]];

    return {
        thousandSeparator,
        decimalSeparator,
    };
}

export function getDateTimeSeparators(locale) {
    const formattedDate = new Intl.DateTimeFormat(locale).format(new Date());
    const formattedTime = getDateFormat({
        locale,
        date: getUTCDate(new Date()),
        options: {
            hour: dateTimeFormats.NUMERIC,
            minute: dateTimeFormats.NUMERIC,
        },
    });

    const [dateSeparator] = formattedDate.match(/[^\w\s]/g);
    const [timeSeparator] = formattedTime.match(/[^\w\s]/g);

    return {
        dateSeparator,
        timeSeparator,
    };
}
