import { useEffect, useState, useCallback } from 'react';
import DocumentsService from 'additiv-services/dist/services/core/documents';
import { useTranslation } from 'react-i18next';
import AdapterError from 'errors/AdapterError';
import { addDaysToDate, subtractUnits } from 'utils/datetime';
import { createBlobFile, openDocumentUrl } from 'utils/documentUtils';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { GA_ACTIONS, GA_CATEGORIES, sendGAEvent } from 'utils/analytics';
import { adaptDocuments, getNames } from '../adapters/adaptDocument';

export default (clientId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [names, setNames] = useState([]);
    const [dataChecked, setDataChecked] = useState([]);
    const { t } = useTranslation();
    const { getFormattedDate } = useFormatting();

    const config = {
        ownerIds: [clientId],
        // Set startSate to solve NS-687
        startDate: subtractUnits(new Date(), 100, 'years'),
        endDate: addDaysToDate(new Date(), 1),
    };

    const onClientListChecked = ({ id, name }) => ({ target: { checked } }) => {
        if (checked) setDataChecked((oldArray) => [...oldArray, { id, name }]);
        else {
            setDataChecked((oldArray) => {
                const index = oldArray.findIndex((item) => item.id === id);

                return [...oldArray.slice(0, index), ...oldArray.slice(index + 1)];
            });
        }
    };

    const downloadDocument = useCallback((documentId, contactId) => {
        DocumentsService.downloadDocument(documentId, contactId)
            .then((response) => {
                try {
                    const newBlob = createBlobFile(response.data);

                    sendGAEvent(GA_ACTIONS.document, GA_CATEGORIES.download, newBlob.fileName);
                    openDocumentUrl(newBlob);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        DocumentsService.getDocuments(config)
            .then((response) => {
                try {
                    const adaptedData = adaptDocuments(
                        response.data,
                        downloadDocument,
                        t,
                        getFormattedDate
                    );

                    setData(
                        adaptedData.map((item) => ({
                            ...item,
                            showToCustomer: {
                                ...item.showToCustomer,
                                onChecked: onClientListChecked(item),
                            },
                        }))
                    );
                    setNames(getNames(adaptedData));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, []);

    return {
        data,
        dataChecked,
        names,
        isLoading,
        error,
    };
};
