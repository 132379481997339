import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabPane, Tabs, Title, ContentBox, TabsExtraItem } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { PIE, BAR, LIST } from 'constants/constants';
import { useFormatting } from 'locale';
import ChartType from './ChartType';
import PageSection from '../PageSection/PageSection';
import { Preloader } from '../molecules/Preloader';
import { EmptyContent } from '../molecules/EmptyContent';

function InvestmentAllocation({ data, isLoading, error }) {
    const { t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();
    const [chartView, setChartView] = useState(PIE);
    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) =>
        getFormattedCurrency(number, {
            currency: data.currency,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        });
    const labelFormat = (label, value) =>
        `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value)}`;
    const tabBarExtraContent = () => (
        <>
            <TabsExtraItem
                icon="chart-pie"
                isActive={chartView === PIE}
                onClick={changeView(PIE)}
            />
            <TabsExtraItem
                icon="chart-bar-horizontal"
                isActive={chartView === BAR}
                onClick={changeView(BAR)}
            />
            <TabsExtraItem icon="list" isActive={chartView === LIST} onClick={changeView(LIST)} />
        </>
    );

    return (
        <PageSection className="investment-allocation">
            <ContentBox underline={false}>
                <Title type={2}>{t('dashboard.investmentAllocation.title')}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data.chart} text={t('dashboard.noData')}>
                        <Tabs defaultActiveKey="1" tabBarExtraContent={tabBarExtraContent()}>
                            {data.chart.map((item, i) => (
                                <TabPane tab={t(item.title)} key={(i + 1).toString()}>
                                    <EmptyContent
                                        data={item.data[chartView].data}
                                        text={t('dashboard.noData')}
                                    >
                                        <div className={`chart-${i}`}>
                                            <ChartType
                                                data={item.data[chartView].data}
                                                title={item.title || item.name}
                                                labels={item.data[chartView].labels}
                                                currency={data.currency}
                                                chartView={chartView}
                                                labelFormat={labelFormat}
                                                numberFormat={numberFormat}
                                                t={t}
                                            />
                                        </div>
                                    </EmptyContent>
                                </TabPane>
                            ))}
                        </Tabs>
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

InvestmentAllocation.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        })
                    ),
                }),
            })
        ),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

InvestmentAllocation.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
};

export default InvestmentAllocation;
