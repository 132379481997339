import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'ui-library';
import { useTranslation } from 'react-i18next';
import PageSection from 'components/PageSection/PageSection';
import { Preloader } from 'components/molecules/Preloader';
import { EmptyContent } from 'components/molecules/EmptyContent';
import NewsItem from './NewsItem';
import './News.css';

function News({ data, isLoading, error, withLink }) {
    const { t } = useTranslation();

    return (
        <PageSection>
            <Preloader isLoading={isLoading} error={error}>
                <div className="news">
                    {withLink ? (
                        <Title type={2} link="/news">
                            {t('news.title')}
                        </Title>
                    ) : (
                        <Title type={2}>{t('news.title')}</Title>
                    )}
                    <EmptyContent data={data} text={t('news.noNews')}>
                        <div className="news-list">
                            {data.map((item) => (
                                <NewsItem {...item} key={item.id} />
                            ))}
                        </div>
                    </EmptyContent>
                </div>
            </Preloader>
        </PageSection>
    );
}

News.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
            link: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    withLink: PropTypes.bool,
};

News.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    withLink: false,
};

export default News;
