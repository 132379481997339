import { find, isNil } from 'lodash';
import { validateStringData, validateName, NA } from 'utils/formatting';
import getAvatarPath from '../components/RelationshipManager/getAvatarPath';

const getPortfoliosCount = (data) => {
    const portfolios = find(data, { PortfolioType: 'Real' });

    return isNil(portfolios) ? NA : portfolios.Count;
};

const adaptRMData = (data, t, getFormattedPhoneNumber) => {
    if (isNil(data)) {
        return {};
    }

    const getPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            return getFormattedPhoneNumber(phoneNumber);
        }

        return null;
    };
    const validateRMEmail = (email) => ({
        Information: {
            title: t('dashboard.relationshipManager.email'),
            subtitle: validateStringData(email),
        },
        Icon: {
            type: 'envolope',
            size: 20,
            href: `mailto:${email}`,
        },
    });
    const validateRMPhone = (phoneNumber) => ({
        Information: {
            title: t('dashboard.relationshipManager.phone'),
            subtitle: validateStringData(phoneNumber),
        },
        Icon: {
            type: 'phone-call',
            size: 20,
            href: getPhoneNumber(phoneNumber),
        },
    });

    const validatedContacts = [];

    if (data.Email) {
        validatedContacts.push(validateRMEmail(data.Email));
    }

    if (data.Phone) {
        validatedContacts.push(validateRMPhone(data.Phone));
    }

    return {
        Img: getAvatarPath(data.Email),
        Name: `${validateName(data.FirstName)} ${validateName(data.LastName)}`,
        PhoneNumber: getPhoneNumber(data.Phone),
        Contacts: validatedContacts,
    };
};

export const adaptProfile = (data, members, t, getFormattedPhoneNumber) => ({
    clientProfile: [
        {
            Information: {
                title: t('dashboard.profile.clientRiskProfile'),
                subtitle: validateStringData(data.ChosenRiskCategory),
            },
            Icon: { type: 'form', size: 20, href: 'risk-profile' },
        },
        {
            Information: {
                title: t('dashboard.profile.totalPortfolios'),
                subtitle: getPortfoliosCount(data.Investments),
            },
            Icon: { type: '' },
        },
    ],
    RMProfile: adaptRMData(members[0], t, getFormattedPhoneNumber),
    lastLogin: data.LastLogin,
});
