import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import history from 'services/core/history';

import { checkSession as checkSessionAction } from '../redux/auth/authActions';
import { isAuthenticatedSelector } from '../redux/auth/authSelectors';
import {
    isCurrentSessionCheckedSelector,
    isSessionValidSelector,
} from '../redux/session/sessionSelectors';
import { withAutoLogout } from './withAutoLogout';
import { Loading } from '../components/Loading';

export const withAuthentication = (WrappedComponent) => {
    function Authentication(props) {
        const { checkSession, isCurrentSessionChecked, isAuthenticated, isSessionValid } = props;

        useEffect(() => {
            if (isCurrentSessionChecked !== true) {
                checkSession();
            }
        }, []);

        if (
            isAuthenticated === true &&
            isCurrentSessionChecked === true &&
            isSessionValid === true
        ) {
            return <WrappedComponent {...props} />;
        }

        if (isCurrentSessionChecked === true && isSessionValid === false) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: history.location },
                    }}
                />
            );
        }

        return <Loading />;
    }

    Authentication.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        isSessionValid: PropTypes.bool.isRequired,
        isCurrentSessionChecked: PropTypes.bool.isRequired,
        checkSession: PropTypes.func.isRequired,
    };

    const mapStateToProps = (state) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        isCurrentSessionChecked: isCurrentSessionCheckedSelector(state),
        isSessionValid: isSessionValidSelector(state),
    });

    const mapDispatchToProps = {
        checkSession: checkSessionAction,
    };

    return connect(mapStateToProps, mapDispatchToProps)(withAutoLogout(Authentication));
};
