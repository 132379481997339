import { useState, useEffect } from 'react';
import { MOBILE_VIEWPORT_MIN_WIDTH } from 'constants/constants';

const detectIsMobile = () => document.body.clientWidth <= MOBILE_VIEWPORT_MIN_WIDTH;

export const useDetectedMobileDevice = () => {
    const [isMobileSize, setMobileSize] = useState(detectIsMobile());

    useEffect(() => {
        const handleResize = () => setMobileSize(detectIsMobile());

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { isMobileSize };
};
