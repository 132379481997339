import { filter } from 'lodash/fp';
import { validateNumber } from 'utils/formatting';

const calcRoundingLot = (data) => (Math.log10(data) < 0 ? Math.abs(Math.log10(data)) : 0);

export const adaptSecurities = (data, { positionId }) => {
    const security = filter({ Id: Number(positionId) }, data.Securities);

    return {
        securityId: security.Id,
        name: security[0] ? security[0].Name : '',
        price: security[0] ? validateNumber(security[0].TradingPrice) : 0,
        tradingUnit: security[0] ? validateNumber(security[0].TradingUnit) : 0,
        roundingLot:
            security[0] && security[0].RoundingLot && security[0].RoundingLot > 0
                ? calcRoundingLot(security[0].RoundingLot)
                : 0,
    };
};
