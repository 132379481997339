import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ForgotPassword } from './ForgotPasswordAskRM';
import { NoHeaderTemplate } from '../../templates/NoHeaderTemplate';

const Router = ({ match: { path } }) => (
    <NoHeaderTemplate>
        <Switch>
            <Route path={path} component={ForgotPassword} />
        </Switch>
    </NoHeaderTemplate>
);

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'ForgotPasswordRouter';

export default Router;
