/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';

const CellPDFView = ({ value, onDownload }, { id, contactId }) => {
    if (value === undefined) return null;

    return (
        <span role="link" className="table-cell_linked" onClick={() => onDownload(id, contactId)}>
            {value}
        </span>
    );
};

CellPDFView.propTypes = {
    value: PropTypes.string,
    onDownload: PropTypes.func,
};

CellPDFView.defaultProps = {
    value: '',
    onDownload: () => {},
};

export default CellPDFView;
