import React, { Fragment, useEffect, useState } from 'react';
import { Preloader } from 'components/molecules/Preloader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LineChart, Select, TabPane, Tabs, TabsExtraItem, Title, ContentBox } from 'ui-library';
import MonthRangePicker from 'components/MonthRangePicker';
import { useFormatting } from 'locale';
import { lineChartLabels } from 'constants/constants';
import { getPerformanceSelectOptions, MONTH_TO_MONTH } from 'filters/investementPerformanceFilters';
import PageSection from '../PageSection/PageSection';
import { EmptyContent } from '../molecules/EmptyContent';

function AssetDevelopment({
    data,
    isLoading,
    error,
    onFilterChange,
    currentFilter,
    title,
    chartValueFormat,
    yAxisFormat,
    currency,
    setFilterDates,
}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { t } = useTranslation();
    const { getFormattedXAxisFormat } = useFormatting();

    useEffect(() => {
        if (startDate && endDate) {
            setFilterDates({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (currentFilter !== MONTH_TO_MONTH) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [currentFilter]);

    const monthFrom = {
        startDate,
        setStartDate,
        placeholder: 'dashboard.assetDevelopment.filters.from',
    };
    const monthTo = {
        endDate,
        setEndDate,
        placeholder: 'dashboard.assetDevelopment.filters.to',
    };

    const renderTabs = () => (
        <Tabs
            defaultActiveKey="1"
            style={{
                overflow: 'visible',
            }}
            className="tabs-with-filter"
            tabBarExtraContent={(
                <Fragment>
                    {currentFilter === MONTH_TO_MONTH && (
                        <TabsExtraItem>
                            <MonthRangePicker monthFrom={monthFrom} monthTo={monthTo} />
                        </TabsExtraItem>
                    )}
                    <TabsExtraItem>
                        <Select
                            onChange={onFilterChange}
                            options={getPerformanceSelectOptions(t)}
                            width={130}
                            value={currentFilter}
                        />
                    </TabsExtraItem>
                </Fragment>
              )}
        >
            <TabPane tab={t('dashboard.assetDevelopment.tabs.performance')} key="1">
                {/* eslint-disable-next-line react/prop-types */}
                <EmptyContent data={data.performance[0].data} text={t('dashboard.noData')}>
                    <LineChart
                        data={data.performance}
                        height={252}
                        yAxisFormat={yAxisFormat.performance}
                        valueFormat={chartValueFormat.performance}
                        tooltipFormat={data.performance[0].tooltipFormat}
                        xAxisFormat={getFormattedXAxisFormat}
                        labels={lineChartLabels(t)}
                    />
                </EmptyContent>
            </TabPane>
            <TabPane tab={t('dashboard.assetDevelopment.tabs.assets')} key="2">
                <EmptyContent data={data.assets[0].data} text={t('dashboard.noData')}>
                    <LineChart
                        data={data.assets}
                        height={252}
                        yTitle={currency}
                        valueFormat={chartValueFormat.assets}
                        yAxisFormat={yAxisFormat.assets}
                        tooltipFormat={data.assets[0].tooltipFormat}
                        xAxisFormat={getFormattedXAxisFormat}
                        labels={lineChartLabels(t)}
                    />
                </EmptyContent>
            </TabPane>
        </Tabs>
    );

    return (
        <PageSection>
            <ContentBox underline={false}>
                <Title type={2}>{title}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    {renderTabs()}
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

AssetDevelopment.propTypes = {
    data: PropTypes.shape({
        performance: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.arrayOf([PropTypes.number, PropTypes.number]),
                tooltipFormat: PropTypes.func,
            })
        ),
        assets: PropTypes.arrayOf({
            date: PropTypes.arrayOf([PropTypes.number, PropTypes.number]),
            tooltipFormat: PropTypes.func,
        }),
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    onFilterChange: PropTypes.func.isRequired,
    currentFilter: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    yAxisFormat: PropTypes.shape({
        performance: PropTypes.func,
        assets: PropTypes.func,
    }).isRequired,
    chartValueFormat: PropTypes.shape({
        performance: PropTypes.shape({
            symbol: PropTypes.string,
            precision: PropTypes.number,
            thousand: PropTypes.string,
            decimal: PropTypes.string,
            format: PropTypes.string,
        }),
        assets: PropTypes.shape({
            symbol: PropTypes.string,
            precision: PropTypes.number,
            thousand: PropTypes.string,
            decimal: PropTypes.string,
            format: PropTypes.string,
        }),
    }).isRequired,
    currency: PropTypes.string.isRequired,
    setFilterDates: PropTypes.func,
};

AssetDevelopment.defaultProps = {
    data: undefined,
    isLoading: true,
    error: null,
    setFilterDates: () => {},
};

export default AssetDevelopment;
