/* eslint-disable no-param-reassign,no-multi-assign */
import { isUndefined } from 'lodash/fp';
import { getParent, roundAllocations, sortAssetsByOrderPropOrAllocation } from 'utils';
import { allocationPercentFormat, formatPercentage, NA } from 'utils/formatting';
import { BAR, LIST } from 'constants/constants';
import { toPascalCase } from 'utils/toPascalCase';
import { ASSET_STRATEGY_IDS, getAssetClassOrder } from '../../SinglePortfolioView/constants';

const adaptChartTypes = (data, currency, chartTypesList, getFormattedCurrency) => ({
    [BAR]: chartTypesList.includes(BAR)
        ? {
              data: [
                  {
                      data: data.map(({ value }) => value),
                  },
              ],
              labels: data.map((item) => `${item.name} ${allocationPercentFormat(item.percent)}`),
          }
        : undefined,
    [LIST]: chartTypesList.includes(LIST)
        ? {
              data: data.map((item) => ({
                  name: item.name,
                  value: getFormattedCurrency(item.value, {
                      currency,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                  }),
                  percent: allocationPercentFormat(item.percent),
                  children: item.children,
              })),
          }
        : undefined,
});

const getAmount = (amount, percentage, investmentValuePortfolioCurrency, securitiesValue) => {
    if (investmentValuePortfolioCurrency) {
        return (amount || 0) + investmentValuePortfolioCurrency;
    }
    if (!isUndefined(securitiesValue)) {
        return (percentage * securitiesValue) / 100;
    }

    return amount || 0;
};

const getChildren = (
    name,
    allocation,
    investmentValuePortfolioCurrency,
    securitiesValue,
    id,
    percent
) => {
    const val =
        investmentValuePortfolioCurrency ||
        (!isUndefined(securitiesValue) ? allocation * securitiesValue : undefined);

    return {
        id,
        name,
        percent: isUndefined(percent) ? allocation * 100 : percent,
        value: isUndefined(val) ? 0 : val,
    };
};

const getPercentage = (percentage, allocation) => (percentage || 0) + allocation;

export const buildAllocationGroup = (positions, currencyPortfolio, t, securitiesValue) => {
    const percents = roundAllocations(positions, 100, 1);

    return positions.reduce(
        (obj, item) => {
            const result = { ...obj };

            const allocation = percents.find(({ id }) => id === item.Security.Id)
                ? percents.find(({ id }) => id === item.Security.Id).value
                : item.Allocation * 100;

            const assetClass = item.Security.AssetClass;

            if (assetClass) {
                // strategy level
                const assetId = assetClass.Id;
                const assetName = getParent(assetClass).Name;
                const asset = (result.asset[assetName] = result.asset[assetName] || {
                    children: [],
                    sortingOrder: getAssetClassOrder(assetId),
                });

                asset.percentage = getPercentage(asset.percentage, allocation);
                asset.amount = getAmount(
                    asset.amount,
                    asset.percentage,
                    item.InvestmentValuePortfolioCurrency,
                    securitiesValue
                );

                const assetInd = asset.children.findIndex((child) => child.id === assetClass.Id);
                const child = getChildren(
                    assetClass.Name,
                    item.Allocation,
                    item.InvestmentValuePortfolioCurrency,
                    securitiesValue,
                    assetClass.Id,
                    percents.find(({ id }) => id === item.Security.Id).value
                );

                if (assetInd !== -1) {
                    asset.children[assetInd].percent += child.percent;
                    asset.children[assetInd].value += child.value;
                } else {
                    asset.children.push(child);
                }

                // sub-strategy level
                const typeName =
                    ASSET_STRATEGY_IDS.indexOf(assetClass.Id) >= 0
                        ? toPascalCase(assetClass.Name)
                        : assetClass.Name;
                const type = (result.type[typeName] = result.type[typeName] || {
                    sortingOrder: getAssetClassOrder(assetId),
                });

                type.percentage = getPercentage(type.percentage, allocation);
                type.amount = getAmount(
                    type.amount,
                    type.percentage,
                    item.InvestmentValuePortfolioCurrency,
                    securitiesValue
                );
            }

            if (item.Security.Currency !== undefined) {
                const currencyName = item.Security.Currency.Name;
                const currency = (obj.currency[currencyName] = obj.currency[currencyName] || {});

                currency.percentage = getPercentage(currency.percentage, allocation);
                currency.amount = getAmount(
                    currency.amount,
                    currency.percentage,
                    item.InvestmentValuePortfolioCurrency,
                    securitiesValue
                );
            }

            return result;
        },
        {
            asset: {},
            type: {},
            currency: {},
        }
    );
};

export const adaptAllocationGroups = (
    { positions, currency, t, securitiesValue, chartTypesList = [BAR, LIST] },
    getFormattedCurrency
) => {
    const groups = buildAllocationGroup(positions, currency, t, securitiesValue);

    const gruopArrays = Object.keys(groups).map((groupKey) => {
        const group = groups[groupKey];

        return Object.keys(group)
            .map((key) => ({
                sortingOrder: group[key].sortingOrder,
                name: key,
                value: group[key].amount,
                percent: group[key].percentage,
                children: group[key].children
                    ? group[key].children.map((item) => ({
                          percent: allocationPercentFormat(item.percent),
                          name: item.name ? toPascalCase(item.name) : NA,
                          value: item.value
                              ? getFormattedCurrency(item.value, {
                                    currency,
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                })
                              : NA,
                      }))
                    : undefined,
                label: formatPercentage(group[key].percentage),
            }))
            .sort(sortAssetsByOrderPropOrAllocation);
    });

    return [
        {
            name: t('allocationTabs.strategyAllocation'),
            data: adaptChartTypes(gruopArrays[0], currency, chartTypesList, getFormattedCurrency),
        },
        {
            name: t('allocationTabs.subStrategy'),
            data: adaptChartTypes(gruopArrays[1], currency, chartTypesList, getFormattedCurrency),
        },
        {
            name: t('allocationTabs.currencies'),
            data: adaptChartTypes(gruopArrays[2], currency, chartTypesList, getFormattedCurrency),
        },
    ];
};
