import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'ui-library';
import LoginLogoSvgLight from 'clientRequirements/images/logo-mobile.svg';

const LoginContentPane = ({ children }) => (
    <div className="container container-small detail_page Login">
        <div className="detail_content content-pane">
            <Title className="application-title" type={1}>
                <img src={LoginLogoSvgLight} alt="Notz Stucki" />
            </Title>
            <div className="content-pane-wrapper">
                <div className="content-pane">{children}</div>
            </div>
        </div>
    </div>
);

LoginContentPane.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default LoginContentPane;
