import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsText = () => {
    const { t } = useTranslation();

    return (
        <div>
            <p>
                <ol classNameName="terms__first-level-list">
                    {/* 1 */}
                    <h3>
                        <li>{t('termsOfUse.legalNotice')}</li>
                    </h3>
                    <ol classNameName="second-level-list">
                        <li classNameName="terms__second-level-item">
                            {t('termsOfUse.paragraph1')}
                        </li>
                        <li classNameName="terms__second-level-item">
                            {t('termsOfUse.paragraph2')}
                            <br />
                            <br />
                            {t('termsOfUse.paragraph3')}
                        </li>
                        <li classNameName="terms__second-level-item">
                            {t('termsOfUse.paragraph4')}
                            <br />
                            <br />
                            {t('termsOfUse.paragraph5')}
                            <br />
                            <br />
                            {t('termsOfUse.paragraph6')}
                            <br />
                            <br />
                            {t('termsOfUse.paragraph7')}
                            <br />
                            <br />
                            {t('termsOfUse.paragraph8')}
                        </li>
                        <li classNameName="terms__second-level-item">
                            {t('termsOfUse.paragraph9')}
                            <br />
                            <br />
                            {t('termsOfUse.paragraph10')}
                        </li>
                        <li classNameName="terms__second-level-item">
                            {t('termsOfUse.paragraph11')}
                        </li>
                    </ol>

                    {/* 2 */}
                    <h3>
                        <li>{t('termsOfUse.dataProtectionPolicy')}</li>
                    </h3>

                    {/* 3 */}
                    <h3>{t('termsOfUse.generalConsiderations')}</h3>
                    <p>
                        {t('termsOfUse.paragraph12')}
                        <a href="https://www.notzstucki.com/"> https://www.notzstucki.com/</a>
                    </p>

                    {/* 4 */}
                    <h3>{t('termsOfUse.basicPrinciplesPrivacyPolicy')}</h3>
                    <p>{t('termsOfUse.paragraph13')}</p>
                    <p>{t('termsOfUse.paragraph14')}</p>
                    <p>{t('termsOfUse.paragraph15')}</p>
                    <p>{t('termsOfUse.paragraph16')}</p>
                    <p>{t('termsOfUse.paragraph17')}</p>
                    <p>{t('termsOfUse.paragraph18')}</p>

                    {/* 5 */}
                    <h3>{t('termsOfUse.personalDataUse')}</h3>
                    <ol>
                        <li>{t('termsOfUse.paragraph19')}</li>
                        <li>{t('termsOfUse.paragraph20')}</li>
                        <li>{t('termsOfUse.paragraph21')}</li>
                        <li>{t('termsOfUse.paragraph22')}</li>
                        <li>{t('termsOfUse.paragraph23')}</li>
                    </ol>

                    {/* 6 */}
                    <h3>{t('termsOfUse.personalDataRights')}</h3>
                    <p>{t('termsOfUse.paragraph24')}</p>
                    <p>{t('termsOfUse.paragraph25')}</p>
                    <p>{t('termsOfUse.paragraph26')}</p>
                    <p>{t('termsOfUse.paragraph27')}</p>
                    <p>{t('termsOfUse.paragraph28')}</p>

                    {/* 7 */}
                    <h3>{t('termsOfUse.dataDuration')}</h3>
                    <p>{t('termsOfUse.paragraph29')}</p>
                    <p>{t('termsOfUse.paragraph30')}</p>
                    <p>{t('termsOfUse.paragraph31')}</p>

                    {/* 8 */}
                    <h3>{t('termsOfUse.dataController')}</h3>
                    <p>{t('termsOfUse.paragraph32')}</p>
                    <p>{t('termsOfUse.paragraph33')}</p>

                    {/* 9 */}
                    <h3>{t('termsOfUse.linkToThirdParty')}</h3>
                    <p>{t('termsOfUse.paragraph34')}</p>

                    {/* 10 */}
                    <h3>
                        <li>{t('termsOfUse.cookiePolicy')}</li>
                    </h3>
                    <p>{t('termsOfUse.paragraph35')}</p>
                    <p>{t('termsOfUse.paragraph36')}</p>

                    {/* 11 */}
                    <h3>{t('termsOfUse.whatAreCookies')}</h3>
                    <p>{t('termsOfUse.paragraph37')}</p>
                    <ul>
                        {t('termsOfUse.paragraph38')}
                        <li>{t('termsOfUse.paragraph39')}</li>
                        <li>{t('termsOfUse.paragraph40')}</li>
                        <li>{t('termsOfUse.paragraph41')}</li>
                    </ul>
                    <p>{t('termsOfUse.paragraph42')}</p>
                    <p>{t('termsOfUse.paragraph43')}</p>

                    <h3>{t('termsOfUse.typesOfCookies')}</h3>
                    <ol>
                        {t('termsOfUse.paragraph44')}
                        <li>{t('termsOfUse.paragraph45')}</li>
                        <p>{t('termsOfUse.paragraph46')}</p>
                        <p>{t('termsOfUse.paragraph47')}</p>
                        <li>{t('termsOfUse.paragraph48')}</li>
                        <ul>
                            <li>{t('termsOfUse.paragraph49')}</li>
                            <li>{t('termsOfUse.paragraph50')}</li>
                            <li>{t('termsOfUse.paragraph51')}</li>
                            <li>{t('termsOfUse.paragraph52')}</li>
                            <li>{t('termsOfUse.paragraph53')}</li>
                        </ul>
                    </ol>

                    <div role="presentation" className="Table-wrapper table-wrapper">
                        <div className="Table-content">
                            <table className="Table table">
                                <thead>
                                    <tr role="row">
                                        <th role="columnheader" className="column-sort sorted">
                                            <span role="button" className="header-column">
                                                {t('termsOfUse.nameOfCookie')}
                                            </span>
                                        </th>
                                        <th role="columnheader" className="column-sort sorted">
                                            <span role="button" className="header-column">
                                                {t('termsOfUse.length')}
                                            </span>
                                        </th>
                                        <th role="columnheader" className="column-sort sorted">
                                            <span role="button" className="header-column">
                                                {t('termsOfUse.description')}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr role="row" className="row-level-0">
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph54')}
                                            </span>
                                        </td>
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph55')}
                                            </span>
                                        </td>
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph56')}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr role="row" className="row-level-0">
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph57')}
                                            </span>
                                        </td>
                                        <td role="cell" className="text-left">
                                            <span className="table-cel">
                                                {t('termsOfUse.paragraph58')}
                                            </span>
                                        </td>
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph59')}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr role="row" className="row-level-0">
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph60')}
                                            </span>
                                        </td>
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph61')}
                                            </span>
                                        </td>
                                        <td role="cell" className="text-left">
                                            <span className="table-cell">
                                                {t('termsOfUse.paragraph62')}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <br />
                    <br />

                    <p>
                        {t('termsOfUse.furtherInfo')}
                        <a href="https://www.google.com/analytics/learn/privacy.html?hl=es">
                            {' '}
                            https://www.google.com/analytics/learn/privacy.html?hl=es
                        </a>
                    </p>

                    <br />
                    <br />

                    {/* 12 */}
                    <h3>{t('termsOfUse.cookieConfiguration')}</h3>
                    <p>{t('termsOfUse.paragraph63')}</p>
                    <p>{t('termsOfUse.paragraph64')}</p>
                    <p>{t('termsOfUse.paragraph65')}</p>
                    <p>{t('termsOfUse.paragraph66')}</p>

                    <br />
                    <br />

                    <p>Microsoft</p>
                    <a href="https://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">
                        https://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies
                    </a>

                    <br />
                    <br />

                    <p>Safari</p>
                    <a href="https://support.apple.com/kb/HT1677?viewlocale=es_ES">
                        https://support.apple.com/kb/HT1677?viewlocale=es_ES
                    </a>

                    <br />
                    <br />

                    <p>Google Chrome</p>
                    <a href="https://support.google.com/chrome/answer/95647?hl=es ">
                        https://support.google.com/chrome/answer/95647?hl=es
                    </a>

                    <br />
                    <br />

                    <p>Firefox</p>
                    <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                        https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                    </a>
                </ol>
            </p>
        </div>
    );
};

export default TermsText;
