import { renderers } from 'ui-library';
import { CellSwitch, CellPercent, CellWithMoreActions } from 'components/renderers';
import { sorterByDate, sorterString, sorterNumber } from 'utils/sorting';

// const { CellWithIcon, CellTooltiped } = renderers;
const { CellWithIcon } = renderers;

// TODO: check if IDs for the Asset Classes remain during migration between environments
// API method - /dfs/api/v1.0/assetclasses/en
// { AssetClassId_fromBackOffice: { positionSortingOrder }}
const ASSET_CLASSES_ORDERS = JSON.parse(global.additionalConfig?.assetClassesOrder || 'null');
const ASSET_CLASSES_ORDERS_FALLBACK = {
    '1': { order: 0 }, // Name: 'CASH',
    '14': { order: 1 }, // Name: 'CURRENCY HEDGE',
    '2': { order: 2 }, // Name: 'FIXED INCOME',
    '10': { order: 3 }, // Name: 'Credit/High Yield/Convertible', ParentId: 2,
    '19': { order: 4 }, // Name: 'Investment Grade Bonds', ParentId: 2,
    '18': { order: 5 }, // Name: 'Emerging Markets Bonds', ParentId: 2,
    '3': { order: 6 }, // Name: 'EQUITIES',
    '15': { order: 7 }, // Name: 'Equities Long Only', ParentId: 3,
    '16': { order: 8 }, // Name: 'Equities Long/Short', ParentId: 3,
    '17': { order: 9 }, // Name: 'Flexible', ParentId: 3,
    '11': { order: 10 }, // Name: 'ABSOLUTE RETURN',
    '12': { order: 11 }, // Name: 'Global Macro/CTA', ParentId: 11,
    '13': { order: 12 }, // Name: 'Relative Value', ParentId: 11,
    '22': { order: 13 }, // Name: 'Multi-Strategy Funds', ParentId: 11,
    '20': { order: 14 }, // Name: 'GOLD & PRECIOUS METALS',
    '4': { order: 15 }, // Name: 'LOAN',
    '21': { order: 16 }, // Name: 'LONG TERM COMMITMENT',
    '24': { order: 17 }, // Name: 'Private Equity', ParentId: 21,
    '25': { order: 18 }, // Name: 'Real Estate', ParentId: 21,
    '26': { order: 19 }, // Name: 'Guarantee', ParentId: 21,
    '23': { order: 20 }, // Name: 'Others', ParentId: 21,
};

export const ASSET_STRATEGY_IDS = [
    1, // Name: 'CASH',
    14, // Name: 'CURRENCY HEDGE',
    2, // Name: 'FIXED INCOME',
    3, // Name: 'EQUITIES',
    11, // Name: 'ABSOLUTE RETURN',
    20, // Name: 'GOLD & PRECIOUS METALS',
    4, // Name: 'LOAN',
    21, // Name: 'LONG TERM COMMITMENT',
];

export function getAssetClassOrder(id) {
    const assetClassOrder = ASSET_CLASSES_ORDERS || ASSET_CLASSES_ORDERS_FALLBACK;
    // const assetClassOrder = ASSET_CLASSES_ORDERS_FALLBACK;

    // 21 is fallback value to set item in the last position of the list
    return assetClassOrder[id]?.order || 21;
}

export const positionsColumns = (t, getFormattedNumber) => [
    {
        key: 'name',
        title: t('position.columns.name'),
        sortable: true,
        sorter: sorterString('name.label'),
        render: CellSwitch,
    },
    {
        title: t('position.columns.value'),
        className: 'text-right',
        key: 'valuation',
        sorter: sorterNumber('valuation'),
    },
    {
        key: 'performanceYTD',
        title: t('position.columns.performanceYTD'),
        className: 'text-right',
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('performanceYTD'),
    },
    {
        title: t('position.columns.unrealizedGainLoss'),
        key: 'unrealizedGainLoss',
        className: 'text-right',
        sorter: sorterNumber('unrealizedGainLoss'),
    },
    {
        title: t('position.columns.weight'),
        key: 'allocation',
        className: 'text-right',
        sorter: sorterNumber('allocation'),
    },
    {
        title: t('position.columns.currency'),
        key: 'currency',
        className: 'currency-cel',
        hidden: true,
        sorter: sorterString('currency'),
    },
    {
        title: t('position.columns.lastPrice'),
        key: 'latestPrice',
        hidden: true,
        className: 'text-right',
        sorter: sorterNumber('latestPrice'),
    },
    {
        title: t('position.columns.lastPriceDate'),
        key: 'latestPriceDate',
        className: 'text-right',
        hidden: true,
        sorter: sorterByDate('latestPriceDate'),
    },
    {
        title: t('position.columns.costPrice'),
        key: 'costPrice',
        className: 'text-right',
        hidden: true,
        sorter: sorterNumber('costPrice'),
    },
    {
        title: t('position.columns.units'),
        key: 'units',
        hidden: true,
        className: 'text-right',
        sorter: sorterNumber('units'),
    },
    {
        title: t('position.columns.valueInSecurityCurrency'),
        className: 'text-right',
        key: 'valuationInSecurityCurrency',
        hidden: true,
        sorter: sorterNumber('valuationInSecurityCurrency'),
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 40,
        minWidth: 40,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const positionsMobileColumns = (t) => [
    {
        key: 'name',
        title: t('position.columns.name'),
        sortable: true,
        render: CellSwitch,
        sorter: sorterString('name'),
    },
    {
        title: t('position.columns.value'),
        className: 'text-right',
        key: 'valuation',
        sorter: sorterNumber('valuation'),
    },
];
